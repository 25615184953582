import request from "@/utils/request";

export function getPage(condition, pagination) {
  return request({
    url: "/hc/customer/change/page",
    method: "post",
    data: { condition: condition, pagination: pagination },
  });
}

export function saveOrUpdate(data) {
  return request({
    url: "/hc/customer/change/saveOrUpdate",
    method: "post",
    data,
  });
}

export function get(id) {
  return request({
    url: "/hc/customer/change/get",
    method: "get",
    params: { id },
  });
}
export function submit(id) {
  return request({
    url: '/hc/customer/change/submit',
    method: 'post',
    data: { id: id }
  })
}
export function deleteById(id) {
  return request({
    url: '/hc/customer/change/deleteById',
    method: 'post',
    data: { id: id }
  })
}
export function audit(data) {
  return request({
    url: '/hc/customer/change/audit',
    method: 'post',
    data
  })
}