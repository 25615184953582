import request from "@/utils/request";

export function getImportTaskList(className) {
  return request({
    url: '/system/excel/getImportTaskList',
    method: 'post',
    data:{className: className}
  })
}

export function downloadResult(key) {
  return request({
    url: '/system/excel/downloadResult',
    method: 'post',
    data:{key: key},
    responseType: 'blob'
  })
}

/**
 * 下载模板
 * @param url
 * @returns {AxiosPromise}
 */
export function downloadTemplate(url) {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob'
  })
}
