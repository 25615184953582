import request from "@/utils/request";

export function getAll() {
  return request({
    url: "/hc/contract/getAll",
    method: "get",
  });
}

export function getPage(condition, pagination) {
  return request({
    url: "/hc/contract/page",
    method: "post",
    data: { condition: condition, pagination: pagination },
  });
}

export function saveOrUpdate(data) {
  return request({
    url: "/hc/contract/saveOrUpdate",
    method: "post",
    data,
  });
}

export function get(id) {
  return request({
    url: "/hc/contract/get",
    method: "get",
    params: { id },
  });
}

export function deleteById(id) {
  return request({
    url: "/hc/contract/deleteById",
    method: "post",
    data: { id: id },
  });
}

export function deleteBatchByIds(ids) {
  return request({
    url: "/hc/contract/deleteBatchById",
    method: "post",
    data: { ids: ids },
  });
}

export function exportExcel(condition, pagination) {
  return request({
    url: "/hc/contract/exportExcel",
    method: "post",
    data: { condition: condition, pagination: pagination },
    responseType: "blob",
  });
}

export function submit(id) {
  return request({
    url: "/hc/contract/submit",
    method: "post",
    data: { id: id },
  });
}

//获取跟单员列表
export function getSelectMerchandiser() {
  return request({
    url: "/hc/flowpath/selectMerchandiser",
    method: "post",
    data: {
      condition: { userName: null },
      pagination: { size: 10000, current: 1 },
    },
  });
}

//获取业务员列表
export function getSalesmanOptions() {
  return request({
    url: "/hc/flowpath/selectSalesman",
    method: "post",
    data: {
      condition: { userName: null },
      pagination: { size: 10000, current: 1 },
    },
  });
}

//获取培训工程师列表
export function getCultivateEngineerOptions() {
  return request({
    url: "/hc/flowpath/selectCultivate",
    method: "post",
    data: {
      condition: { userName: null },
      pagination: { size: 10000, current: 1 },
    },
  });
}

//首页查询加急合同项
export function seleteIsUrgent(data) {
  return request({
    url: "/hc/contract/seleteIsUrgent",
    method: "post",
    data,
  });
}


