<template>
  <el-drawer
    v-loading="loading"
    :title="form.id ? '编辑缴费单申请' : '新增缴费单申请'"
    :visible.sync="show"
    size="80%"
    :before-close="handleClose"
    append-to-body
    destroy-on-close
    center
    :close-on-click-modal="false"
  >
    <div style="height: calc(100vh - 110px); overflow-y: auto">
      <el-form
        ref="form"
        :disabled="type == '查看'"
        :model="form"
        :rules="rules"
        label-width="130px"
      >
        <el-card header="基础信息">
          <el-row>
            <el-col :span="6">
              <el-form-item label="缴费申请单号" prop="sn">
                <el-input v-model="form.sn" disabled placeholder="" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="流程单号" prop="flowpathBillsSn">
                <el-input
                  v-model="form.flowpathBillsSn"
                  placeholder=""
                  clearable
                  readonly
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                  @click.native="onPicker"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="6">
            <el-form-item label="流程单名称" prop="flowpathBillsName">
              <el-input
                v-model="form.flowpathBillsName"
                placeholder=""
                clearable
                :disabled="true"
              />
            </el-form-item>
          </el-col> -->

            <el-col :span="6">
              <el-form-item label="单据状态" prop="status">
                <el-select v-model="form.status" clearable filterable disabled>
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="合同名称" prop="contractApplyName">
                <el-input
                  v-model="form.contractApplyName"
                  placeholder=""
                  clearable
                  :disabled="true"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户名称" prop="customerName">
                <el-input
                  v-model="form.customerName"
                  clearable
                  :disabled="true"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="申请人" prop="applyContact">
                <el-input
                  v-model="form.applyContact"
                  placeholder=""
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="申请日期" prop="applyTime">
                <el-input
                  :value="
                    form.applyTime && dayjs(form.applyTime).format('YYYY-MM-DD')
                  "
                  placeholder=""
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="申请总金额" prop="applyFee">
                <el-input v-model="form.applyFee" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="会计名称" prop="accountantName">
                <el-input
                  v-model="form.accountantName"
                  placeholder=""
                  clearable
                  :disabled="true"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="会计审核时间" prop="accountantTime">
                <el-input
                  :value="
                    form.accountantTime &&
                      dayjs(form.accountantTime).format('YYYY-MM-DD')
                  "
                  placeholder=""
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="总经理名称" prop="generalManager">
                <el-input
                  v-model="form.generalManager"
                  placeholder=""
                  clearable
                  :disabled="true"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="总经理审批时间" prop="generalManagerTime">
                <el-input
                  :value="
                    form.generalManagerTime &&
                      dayjs(form.generalManagerTime).format('YYYY-MM-DD')
                  "
                  placeholder=""
                  clearable
                  :disabled="true"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="状态" prop="jfType">
                <el-select
                  v-model="form.jfType"
                  clearable
                  filterable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                >
                  <el-option
                    v-for="item in jfTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <!-- 审核完成状态可以上传发票 -->
            <el-col :span="6">
              <el-form-item label="是否上传发票" prop="isInvoice">
                <el-select v-model="form.isInvoice" clearable filterable :disabled="!(form.status == 3)">
                  <el-option
                    v-for="item in booleanOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="发证机构" prop="certifyingAuthority">
                <el-input
                  v-model="form.certifyingAuthority"
                  placeholder=""
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="发证机构费用" prop="attestationFee">
                <el-input
                  v-model="form.attestationFee"
                  placeholder=""
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="实验室" prop="laboratory">
                <el-input
                  v-model="form.laboratory"
                  placeholder=""
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="实验室费用" prop="laboratoryFee">
                <el-input
                  v-model="form.laboratoryFee"
                  placeholder=""
                  clearable
                  type="number"
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="备注" prop="note">
                <el-input
                  v-model="form.note"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card header="明细信息">
          <div slot="header" class="clearfix">
            <span>明细信息</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              :disabled="
                [2, 3].includes(form.status) &&
                  !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
              "
              @click="onAdd"
            >新增</el-button>
          </div>
          <el-row>
            <el-table
              ref="elTable"
              :data="form.hcPayapplyFeeLineModels"
              border
              stripe
              :summary-method="getSummaries"
              show-summary
              :height="280"
            >
              <el-table-column
                label="事项"
                align="left"
                prop="matter"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'hcPayapplyFeeLineModels.' + scope.$index + '.matter'"
                    :rules="[
                      {
                        required: true,
                        message: '请填写事项',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.matter"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="应付金额"
                align="left"
                prop="amountPayable"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcPayapplyFeeLineModels.' + scope.$index + '.amountPayable'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写应付金额',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.amountPayable"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                label="收款单位"
                align="left"
                show-overflow-tooltip
                prop="receiptCompany"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcPayapplyFeeLineModels.' +
                        scope.$index +
                        '.receiptCompany'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写收款单位',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.receiptCompany"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="receiptBank"
                label="收款银行"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcPayapplyFeeLineModels.' + scope.$index + '.receiptBank'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写收款银行',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.receiptBank"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                label="收款银行账号"
                align="left"
                show-overflow-tooltip
                prop="receiptBankNumber"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcPayapplyFeeLineModels.' +
                        scope.$index +
                        '.receiptBankNumber'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写收款银行账号',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.receiptBankNumber"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="receiptContact"
                label="收款人姓名"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcPayapplyFeeLineModels.' +
                        scope.$index +
                        '.receiptContact'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写收款人姓名',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.receiptContact"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="receiptContactMobile"
                label="收款人电话"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcPayapplyFeeLineModels.' +
                        scope.$index +
                        '.receiptContactMobile'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写收款人电话',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.receiptContactMobile"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <!-- <el-table-column
              label="付款凭证号"
              align="left"
              show-overflow-tooltip
              prop="billOfCredit"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="
                    'hcPayapplyFeeLineModels.' + scope.$index + '.billOfCredit'
                  "
                  :rules="[
                    {
                      required:
                        flowOptions.isAssignee &&
                        [2].includes(form.status) &&
                        flowOptions.nodeName == '出纳',
                      message: '请填写付款凭证号',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.billOfCredit"
                    placeholder=""
                    clearable
                    :disabled="
                      scope.row.contractLineId ||
                      !flowOptions.isAssignee ||
                      ![2].includes(form.status) ||
                      flowOptions.nodeName != '出纳'
                    "
                  />
                </el-form-item>
              </template>
            </el-table-column> -->
              <el-table-column
                prop="note"
                label="说明"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'hcPayapplyFeeLineModels.' + scope.$index + '.note'"
                    :rules="[
                      {
                        required: false,
                        message: '请填写说明',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.note"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="操作" fixed="right" width="200">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    :disabled="
                      [2, 3].includes(form.status) &&
                        !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                    "
                    @click="handleDelete(scope.$index)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-card>

        <el-card header="收款方发票">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            multiple
            :on-preview="handlePreview"
            :file-list="form.hcPayapplyFeeAccessoryModels"
            :disabled="
              !flowOptions.isAssignee ||
                ![2].includes(form.status) ||
                flowOptions.nodeName != '出纳'
            "
          >
            <el-button
              :disabled="
                !flowOptions.isAssignee ||
                  ![2].includes(form.status) ||
                  flowOptions.nodeName != '出纳'
              "
              size="small"
              type="primary"
            >点击上传</el-button>
            <div slot="tip" class="el-upload__tip" />
          </el-upload>
        </el-card>


        <el-card header="发票附件">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-remove="handleRemove2"
            :on-success="handleAvatarSuccess2"
            multiple
            :on-preview="handlePreview"
            :file-list="form.hcPayapplyFeeReceiptAccessoryModels"
            :disabled="!(form.status == 3 && form.isInvoice)"
          >
            <el-button
              :disabled="!(form.status == 3 && form.isInvoice)"
              size="small"
              type="primary"
            >点击上传</el-button>
            <div slot="tip" class="el-upload__tip" />
          </el-upload>
        </el-card>

        <el-card header="流程审批">
          <flow
            ref="flow"
            :custom-event="true"
            :flow-id="form.flowId"
            @flowLoad="flowLoad"
            @complete="onComplete"
            @stop="getData"
          />
        </el-card>
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            @click="submitForm('form')"
          >确认</el-button>
          <el-button
            v-if="form.id && (form.status == 1 || !form.status)"
            type="primary"
            @click="submit()"
          >提交</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
      <flowDialog ref="flowDialog" @select="onSelect" />
    </div>
  </el-drawer>
</template>

<script>
import { get, saveOrUpdate, submit } from '@/api/hc/payment-slip/payment-slip'
import Flow from '@/components/Flow'
import flowDialog from '@/views/hc/components/flowDialog'
import AreaSelect from '@/views/fwpt/basic/area/AreaSelect'
import dayjs from 'dayjs'
export default {
  name: 'hcPaymentSlipEdit',
  components: { Flow, flowDialog, AreaSelect },
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    editId: Number
  },

  data() {
    return {
      dayjs,
      type: '',
      flowOptions: {},
      uploadUrl: process.env.VUE_APP_FILE_UPLOAD_URL,
      statusOptions: [
        {
          label: '制单',
          value: 1
        },
        {
          label: '审核中',
          value: 2
        },
        {
          label: '审核完成',
          value: 3
        },
        {
          label: '驳回',
          value: 4
        }
      ],
      jfTypeOptions: [
        {
          label: '申请中',
          value: 1
        },
        {
          label: '检测中',
          value: 2
        },
        {
          label: '缴费状态',
          value: 3
        },
        {
          label: '证书/发票寄出状态',
          value: 4
        }
      ],
      productCategoryOptions: [
        {
          label: 'CCC新申请',
          value: 1
        },
        {
          label: 'CCCS同类新申请',
          value: 2
        },
        {
          label: '能效检测',
          value: 3
        },
        {
          label: '委托测试项目',
          value: 4
        },
        {
          label: '化学项目',
          value: 5
        },
        {
          label: '体系项目',
          value: 6
        },
        {
          label: '节能认证新申请',
          value: 7
        },
        {
          label: '节能认证同类新申请',
          value: 8
        },
        {
          label: '环保认证',
          value: 9
        },
        {
          label: '环保认证同类新申请',
          value: 10
        },
        {
          label: 'CB认证',
          value: 11
        },
        {
          label: 'CE认证',
          value: 12
        }
      ],
      projectTypeOptions: [
        {
          label: 'CCCS同类新申请',
          value: 1
        },
        {
          label: '能效检测',
          value: 2
        },
        {
          label: '委托测试项目',
          value: 3
        },
        {
          label: '化学项目',
          value: 4
        },
        {
          label: '节能认证同类新申请',
          value: 5
        },
        {
          label: '环保认证同类新申请',
          value: 6
        },
        {
          label: 'CB认证',
          value: 7
        },
        {
          label: 'CE认证',
          value: 8
        }
      ],
      booleanOptions: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      projectStatOptions: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '异常',
          value: 2
        }
      ],
      loading: false,
      form: {
        laboratoryFee: '',
        sn: '', // 缴费申请单号
        flowpathBillsSn: '', // 流程单号
        flowpathBillsName: '', // 流程单名称
        status: '', // 单据状态
        contractApplyName: '', // 合同名称
        customerName: '', // 客户名称
        applyContact: '', // 申请人
        applyTime: '', // 申请日期
        applyFee: '', // 申请总金额
        accountantName: '', // 会计名称
        accountantTime: '', // 会计审核时间
        generalManager: '', // 总经理名称
        generalManagerTime: '', // 总经理审批时间
        note: '', // 备注

        hcPayapplyFeeLineModels: [], // 明细信息
        hcPayapplyFeeAccessoryModels: [], // 附件
        hcPayapplyFeeReceiptAccessoryModels: [],//发票附件
      },
      rules: {}
    }
  },
  watch: {
    editId(editId) {},
    show(show) {
      if (!show) {
        return
      }
      if (this.editId == null) {
        this.form = this.$options.data.call(this).form
        return
      }
      this.getData()
    }
  },
  // created() {
  //   this.type = this.$route.query.type
  //   if (this.editId == null && this.$route.query.id) {
  //     this.editId = this.$route.query.id
  //     this.getData()
  //   }
  // },
  methods: {
    async getData() {
      if (!this.editId) return
      this.loading = true
      try {
        const response = await get(this.editId)
        const { data } = response
        this.form = data
        this.loading = false
        if (!Array.isArray(this.form.hcPayapplyFeeLineModels)) {
          this.form.hcPayapplyFeeLineModels = []
        }

        if (Array.isArray(this.form.hcPayapplyFeeAccessoryModels)) {
          this.form.hcPayapplyFeeAccessoryModels.forEach((item) => {
            this.$set(item, 'url', item.accessoryUrl)
            this.$set(item, 'name', item.accessoryName)
          })
        } else {
          this.form.hcPayapplyFeeAccessoryModels = []
        }

        if (Array.isArray(this.form.hcPayapplyFeeReceiptAccessoryModels)) {
          this.form.hcPayapplyFeeReceiptAccessoryModels.forEach((item) => {
            this.$set(item, 'url', item.accessoryUrl)
            this.$set(item, 'name', item.accessoryName)
          })
        } else {
          this.form.hcPayapplyFeeReceiptAccessoryModels = []
        }

        this.$nextTick(() => {
          this.$refs.elTable.doLayout()
        })
      } catch (error) {
        this.loading = false
      }
    },
    // 流程提交
    async onComplete() {
      // 先执行保存
      await this.submitForm('form', false, async() => {
        // await this.getData();
        // 在执行提交
        this.$refs.flow.onComplete(() => {
          this.handleClose()
        })
      })
    },
    flowLoad(e) {
      console.log(e)
      this.flowOptions = e
      this.$refs.flow.validatorForm.push(this.$refs.isProceeds)
    },
    handlePreview(file) {
      console.log(file)
      window.open(file.url, file.url, '_blank')
      // saveAs(file.url, file.accessoryName || file.url);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.form.hcPayapplyFeeAccessoryModels = fileList
    },
    handleAvatarSuccess(response) {
      if (!response.status === 200) {
        this.$message.error(response.msg)
        return
      }
      const { fileName, url, md5 } = response.data
      this.form.hcPayapplyFeeAccessoryModels.push({
        // contractApplyId: 1 /** 合同主表ID */,
        accessoryName: fileName,
        accessoryUrl: url /** 附件URL */,
        url: url /** 附件URL */,
        name: fileName /** 附件URL */
      })
      console.log(fileName, url, md5)
    },

    handleRemove2(file, fileList) {
      console.log(file, fileList)
      this.form.hcPayapplyFeeReceiptAccessoryModels = fileList
    },
    handleAvatarSuccess2(response) {
      if (!response.status === 200) {
        this.$message.error(response.msg)
        return
      }
      const { fileName, url, md5 } = response.data
      this.form.hcPayapplyFeeReceiptAccessoryModels.push({
        // contractApplyId: 1 /** 合同主表ID */,
        accessoryName: fileName,
        accessoryUrl: url /** 附件URL */,
        url: url /** 附件URL */,
        name: fileName /** 附件URL */
      })
      console.log(fileName, url, md5)
    },
    onPicker() {
      if (!this.form.status || this.form.status == 1) {
        this.$refs.flowDialog.open({
          fromType: '缴费单申请'
        })
      }
    },
    onSelect(row) {
      console.log(row)
      Object.assign(this.form, {
        flowpathBillsId: row.id /** 流程单主表ID */,
        flowpathBillsSn: row.sn /** 流程单号 */,
        flowpathBillsName: '' /** 流程单名称 */,
        contractApplyId: row.contract_apply_id /** 合同主表ID */,
        contractApplySn: row.contract_apply_sn /** 合同单号 */,
        contractApplyName: row.contract_name /** 合同名称 */,
        customerId: row.customer_id, // 客户id
        customerNo: row.customer_no, // 客户编码
        customerName: row.customer_name, // 客户名称
        laboratory: row.laboratory, //实验室
        certifyingAuthority: row.certifyingAuthority || row.certifying_authority,//发证机构
        attestationFee: row.attestationFee || row.attestation_fee, //发证机构费用
        // laboratoryFee: row.laboratoryFee, //实验室费用
        projectPrice: row.projectPrice
      })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        const values = data.map((item) => {
          console.log(item[column.property])
          return Number(item[column.property])
        })
        console.log(data)
        console.log(values)
        if (
          !values.every((value) => isNaN(value)) &&
          ['amountPayable'].includes(column.property)
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          this.form.applyFee = sums[index]
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    onAdd() {
      this.form.hcPayapplyFeeLineModels.push({
        id: undefined,
        matter: undefined,
        amountPayable: undefined,
        receiptCompany: undefined,
        receiptBank: undefined,
        receiptBankNumber: undefined,
        receiptContact: undefined,
        receiptContactMobile: undefined,
        billOfCredit: undefined,
        note: undefined
      })
    },
    handleDelete(index) {
      this.form.hcPayapplyFeeLineModels.splice(index, 1)
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    handleClose() {
      Object.assign(this.$data, this.$options.data.call(this))
      // const view = this.$route;
      // this.$store
      //   .dispatch("tagsView/delView", view)
      //   .then(({ visitedViews }) => {
      //     if (this.isActive(view)) {
      //       this.toLastView(visitedViews, view);
      //     }
      //   });
      this.$emit('close')
    },
    async submitForm(formName, isClose = false, cb) {
      // ---- 需要手工填写此代码 -----
      this.$refs[formName].validate(async(valid, obj) => {
        if (valid) {
          if (
            this.flowOptions.isAssignee &&
            [2].includes(this.form.status) &&
            this.flowOptions.nodeName == '出纳'
          ) {
            if (
              Array.isArray(this.form.hcPayapplyFeeAccessoryModels) &&
              this.form.hcPayapplyFeeAccessoryModels.length == 0
            ) {
              this.$message({
                type: 'warning',
                message: '请上传收款凭证'
              })
              return
            }
          }
          //发票附件
          if ([3].includes(this.form.status) && this.form.isInvoice == 1) {
            if (
              Array.isArray(this.form.hcPayapplyFeeReceiptAccessoryModels) &&
              this.form.hcPayapplyFeeReceiptAccessoryModels.length == 0
            ) {
              this.$message({
                type: 'warning',
                message: '请上传发票附件'
              })
              return
            }
          }

          if (this.form.hcPayapplyFeeLineModels.length == 0) {
            this.$message({
              type: 'warning',
              message: '请添加明细信息'
            })
            return
          }

          if (this.flowOptions.nodeName == '财务') {
            const row = this.flowOptions.historyActivityList.find(
              (item) => item.name == this.flowOptions.nodeName
            )
            if (row) {
              this.form.accountantName = row.assignee
              this.form.accountantTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
            }
          } else if (this.flowOptions.nodeName == '总经理') {
            const row = this.flowOptions.historyActivityList.find(
              (item) => item.name == this.flowOptions.nodeName
            )
            if (row) {
              this.form.generalManager = row.assignee
              this.form.generalManagerTime = dayjs().format(
                'YYYY-MM-DD HH:mm:ss'
              )
            }
          }
          const total = Number(this.form.laboratoryFee || 0) + Number(this.form.attestationFee || 0) + Number(this.form.applyFee || 0);
          if(total > this.form.projectPrice) {
            await this.$confirm('已超出合同总金额，是否还需继续下单?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
          }

          // ---- 需要手工填写此代码 -----
          try {
            this.loading = true
            const { message } = await saveOrUpdate(this.form)
            this.$message({
              type: 'success',
              message: message
            })
            await this.getData()
            console.log('33333')
            this.loading = false
            if (cb) {
              await cb()
            }
            if (isClose) {
              this.handleClose()
            }
          } catch (error) {
            this.loading = false
          }
        } else {
          console.log(obj)
          for (const key in obj) {
            this.$message({
              type: 'warning',
              message: obj[key][0].message
            })
            return false
          }
          console.log('error submit!!')
          return false
        }
      })
    },
    async submit() {
      // 先执行保存
      await this.submitForm('form', true, async() => {
        // await this.getData();
        // 在执行提交
        submit(this.form.id).then((response) => {
          const flowId = response.data.flowId
          this.$message.success('提交成功')
          this.form.flowId = flowId
          this.getData()
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
