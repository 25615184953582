<template>
  <el-dialog
    title="列表"
    :visible.sync="drawer"
    width="800px"
    @close="onClose"
    append-to-body
  >
    <div class="content">
      <el-row ref="searchBox" class="search-box">
        <el-col :span="3">
          <el-input
            v-model="condition.customerNo"
            clearable
            placeholder="客户编号"
          />
        </el-col>
        <el-col :span="3">
          <el-input
            v-model="condition.customerName"
            clearable
            placeholder="客户名称"
          />
        </el-col>
        <el-col :span="3">
          <el-button type="primary" icon="el-icon-search" @click="getPage"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-table
        ref="listTable"
        v-loading="loading"
        :data="tableData"
        :height="tableHeight"
        border
      >
        <el-table-column type="selection" width="40" />

        <el-table-column
          label="客户编号"
          width="100"
          align="left"
          prop="customerNo"
          show-overflow-tooltip
        />

        <el-table-column
          label="客户名称"
          width="100"
          align="left"
          prop="customerName"
          show-overflow-tooltip
        />

        <el-table-column
          label="客户简称"
          width="100"
          align="left"
          prop="customerAbbreviation"
          show-overflow-tooltip
        />

        <el-table-column
          label="行业类别"
          width="150"
          align="left"
          prop="industryType"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              format.formatEnumDesc("HcIndustryType", scope.row.industryType)
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="联系人"
          width="100"
          align="left"
          prop="customerContacts"
          show-overflow-tooltip
        />

        <el-table-column
          label="联系电话"
          width="100"
          align="left"
          prop="customerPhone"
          show-overflow-tooltip
        />

        <el-table-column
          label="详细地址"
          width="200"
          align="left"
          prop="customerAddress"
          show-overflow-tooltip
        />

        <el-table-column
          label="备注"
          align="left"
          prop="customerRemark"
          show-overflow-tooltip
        />
        <el-table-column
          label="创建人"
          align="left"
          prop="createUser"
          show-overflow-tooltip
        />
        <el-table-column
          label="创建时间"
          align="left"
          prop="createTime"
          show-overflow-tooltip
        />
        <el-table-column
          label="更新人"
          align="left"
          prop="updateUser"
          show-overflow-tooltip
        />
        <el-table-column
          label="更新时间"
          align="left"
          prop="updateTime"
          show-overflow-tooltip
        />
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="onSubmit(scope.row)"
              >确定
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-row class="footer-box">
        <el-col :span="24">
          <pagination
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getPage"
          />
        </el-col>
      </el-row>
    </div>

    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="drawer = false">取 消</el-button>
      <el-button :loading="isLoading" type="primary" @click="onSubmit"
        >确 定</el-button
      >
    </span> -->
  </el-dialog>
</template>
<script>
import { getPage, deleteById, exportExcel } from "@/api/hc/customer/customer";
import { booleanToStr } from "@/filters";
import Pagination from "@/components/Pagination";
import edit from "@/views/hc/customer/edit";
import ExcelImport from "@/components/ExcelImport";
import { downloadFile } from "@/utils/file";
import format from "@/utils/formatter";

export default {
  name: "Index",
  components: { Pagination, ExcelImport, edit },
  data() {
    return {
      drawer: false,
      tableHeight: "300px",
      format: format,
      showEdit: false,
      loading: false,
      editId: null,
      pagination: {
        total: 0,
        size: 20,
        current: 1,
      },
      condition: {
        customerNo: null,
        customerName: null,
        status: 3,
      },
      tableData: [],
    };
  },
  methods: {
    onClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    open() {
      this.drawer = true;
      this.getPage();
    },
    onSubmit(row) {
      this.$emit("select", row);
      this.drawer = false;
    },
    getPage() {
      this.loading = true;
      getPage(this.condition, this.pagination)
        .then((reponse) => {
          const { current, total, size, records } = reponse.data;
          this.tableData = records;
          this.pagination.total = total;
          this.pagination.current = current;
          this.pagination.size = size;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatBoolean(cellValue) {
      return booleanToStr(cellValue.isEnabled);
    },
  },
};
</script>
<style scoped>
.content {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}
.handleBar {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  left: 0;
  bottom: 0;
  padding: 0 10px;
  box-sizing: border-box;
}
.elDrawer {
}
.title {
  margin-top: 20px;
  font-size: 14px;
}
</style>