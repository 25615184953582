var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-table",
        {
          ref: "pageTable",
          attrs: { url: "/flow/myPending" },
          scopedSlots: _vm._u([
            {
              key: "condition",
              fn: function (ref) {
                var condition = ref.condition
                return [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入流程名称" },
                        model: {
                          value: condition.name,
                          callback: function ($$v) {
                            _vm.$set(condition, "name", $$v)
                          },
                          expression: "condition.name",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          [
            _c("el-table-column", {
              attrs: { label: "单据号", prop: "orderNo" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.toOrder(row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(row.orderNo))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "流程类型",
                prop: "category",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "启动人", prop: "createUser" },
            }),
            _c("el-table-column", {
              attrs: { label: "流程名称", prop: "name" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "当前节点名称",
                prop: "node",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "当前流程处理人",
                prop: "assignee",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "创建时间", prop: "createTime", width: "140" },
            }),
          ],
        ],
        2
      ),
      _c(_vm.currentView, {
        ref: "com",
        tag: "component",
        attrs: { show: _vm.showEdit, "edit-id": _vm.editId },
        on: { close: _vm.closeEdit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }