var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.form.id ? "编辑客户信息" : "新增客户信息",
        visible: _vm.show,
        size: "80%",
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "destroy-on-close": "",
        center: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                disabled: _vm.type == "查看",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-card",
                { attrs: { header: "基础信息" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "客户编号", prop: "customerNo" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "自动生成",
                                  disabled: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.customerNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerNo", $$v)
                                  },
                                  expression: "form.customerNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户名称",
                                prop: "customerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入客户名称",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.customerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerName", $$v)
                                  },
                                  expression: "form.customerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户简称",
                                prop: "customerAbbreviation",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入客户简称",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.customerAbbreviation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "customerAbbreviation",
                                      $$v
                                    )
                                  },
                                  expression: "form.customerAbbreviation",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "所属行业",
                                prop: "industryType",
                              },
                            },
                            [
                              _c("vocabulary-select", {
                                attrs: {
                                  placeholder: "",
                                  code: "HcIndustryType",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.industryType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "industryType", $$v)
                                  },
                                  expression: "form.industryType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户地区",
                                prop: "customerAreaId",
                              },
                            },
                            [
                              _c("area-select", {
                                attrs: {
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                on: { change: _vm.handleAreaSelect },
                                model: {
                                  value: _vm.form.customerAreaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerAreaId", $$v)
                                  },
                                  expression: "form.customerAreaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "详细地址",
                                prop: "customerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入详细地址",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.customerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerAddress", $$v)
                                  },
                                  expression: "form.customerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系人",
                                prop: "customerContacts",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系人",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.customerContacts,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerContacts", $$v)
                                  },
                                  expression: "form.customerContacts",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系电话",
                                prop: "customerPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系电话",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.customerPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerPhone", $$v)
                                  },
                                  expression: "form.customerPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "备注", prop: "customerRemark" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入备注",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.customerRemark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerRemark", $$v)
                                  },
                                  expression: "form.customerRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否开票", prop: "isInvoice" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled:
                                      [2, 3].includes(_vm.form.status) &&
                                      !(
                                        _vm.flowOptions.isAssignee &&
                                        _vm.flowOptions.nodeName == "开始"
                                      ),
                                  },
                                  model: {
                                    value: _vm.form.isInvoice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isInvoice", $$v)
                                    },
                                    expression: "form.isInvoice",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "开票信息" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "注册地址",
                                prop: "invoicingRegisterAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入注册地址",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.invoicingRegisterAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingRegisterAddress",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingRegisterAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司地址",
                                prop: "invoicingCompanyAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入公司地址",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.invoicingCompanyAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingCompanyAddress",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingCompanyAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司电话",
                                prop: "invoicingCompanyPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入公司电话",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.invoicingCompanyPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingCompanyPhone",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingCompanyPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纳税人识别号",
                                prop: "invoicingCompanyNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入纳税人识别号",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.invoicingCompanyNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingCompanyNumber",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingCompanyNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开户银行",
                                prop: "invoicingDepositBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入开户银行",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.invoicingDepositBank,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingDepositBank",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingDepositBank",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "银行账号",
                                prop: "invoicingBankNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入银行账号",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.invoicingBankNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingBankNumber",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingBankNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发票抬头",
                                prop: "invoiceTitle",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入发票抬头",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.invoiceTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "invoiceTitle", $$v)
                                  },
                                  expression: "form.invoiceTitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "附件" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleAvatarSuccess,
                        multiple: "",
                        limit: 1,
                        "on-preview": _vm.handlePreview,
                        "file-list": _vm.form.hcCustomerAccessoryModels,
                        disabled: [2, 3].includes(_vm.form.status),
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: [2, 3].includes(_vm.form.status),
                            size: "small",
                            type: "primary",
                          },
                        },
                        [_vm._v("点击上传")]
                      ),
                      _c("div", {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm.form.id && _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.onAudit(_vm.form.id)
                            },
                          },
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }