var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.form.id ? "编辑合同变更信息" : "新增合同变更信息",
        visible: _vm.show,
        size: "80%",
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "destroy-on-close": "",
        center: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                disabled: _vm.type == "查看",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-card",
                { attrs: { header: "基础信息" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合同变更号", prop: "sn" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.sn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sn", $$v)
                                  },
                                  expression: "form.sn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同编码",
                                prop: "contractApplySn",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "",
                                    clearable: "",
                                    readonly: "",
                                    disabled:
                                      [2, 3].includes(_vm.form.status) &&
                                      !(
                                        _vm.flowOptions.isAssignee &&
                                        _vm.flowOptions.nodeName == "开始"
                                      ),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onPicker.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.contractApplySn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "contractApplySn", $$v)
                                    },
                                    expression: "form.contractApplySn",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合同号", prop: "contractSn" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.contractSn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractSn", $$v)
                                  },
                                  expression: "form.contractSn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同名称",
                                prop: "contractName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.contractName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractName", $$v)
                                  },
                                  expression: "form.contractName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务员", prop: "salesman" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择业务员",
                                    clearable: "",
                                    disabled:
                                      [2, 3].includes(_vm.form.status) &&
                                      !(
                                        _vm.flowOptions.isAssignee &&
                                        _vm.flowOptions.nodeName == "开始"
                                      ),
                                  },
                                  model: {
                                    value: _vm.form.salesman,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "salesman", $$v)
                                    },
                                    expression: "form.salesman",
                                  },
                                },
                                _vm._l(_vm.salesmanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.adminId,
                                    attrs: {
                                      label: item.userName,
                                      value: item.userName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户名称",
                                prop: "customerName",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    clearable: "",
                                    readonly: "",
                                    disabled:
                                      [2, 3].includes(_vm.form.status) &&
                                      !(
                                        _vm.flowOptions.isAssignee &&
                                        _vm.flowOptions.nodeName == "开始"
                                      ),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onPicker2.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.customerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "customerName", $$v)
                                    },
                                    expression: "form.customerName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "客户编码", prop: "customerNo" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入客户编码",
                                  clearable: "",
                                  readonly: "",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.customerNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerNo", $$v)
                                  },
                                  expression: "form.customerNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单据状态", prop: "status" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status",
                                  },
                                },
                                _vm._l(_vm.statusOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户地区",
                                prop: "customerAreaId",
                              },
                            },
                            [
                              _c("area-select", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.form.customerAreaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerAreaId", $$v)
                                  },
                                  expression: "form.customerAreaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户地址",
                                prop: "customerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.customerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerAddress", $$v)
                                  },
                                  expression: "form.customerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系人", prop: "contact" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.contact,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contact", $$v)
                                  },
                                  expression: "form.contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系人电话",
                                prop: "contactMobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.contactMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactMobile", $$v)
                                  },
                                  expression: "form.contactMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否需要开票",
                                prop: "isInvoice",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.isInvoice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isInvoice", $$v)
                                    },
                                    expression: "form.isInvoice",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否已收款",
                                prop: "isProceeds",
                                rules: [
                                  {
                                    required: _vm.flowOptions.isAssignee,
                                    message: "请选择是否收款",
                                    trigger: ["blur"],
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled:
                                      !_vm.flowOptions.isAssignee ||
                                      ![2].includes(_vm.form.status) ||
                                      _vm.flowOptions.nodeName != "财务",
                                  },
                                  model: {
                                    value: _vm.form.isProceeds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isProceeds", $$v)
                                    },
                                    expression: "form.isProceeds",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "总金额", prop: "totalMoney" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.totalMoney,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "totalMoney", $$v)
                                  },
                                  expression: "form.totalMoney",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "变更原因", prop: "note" } },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [_vm._v("变更原因")]
                              ),
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "note", $$v)
                                  },
                                  expression: "form.note",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "合同明细" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("合同明细")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: {
                            type: "text",
                            disabled:
                              [2, 3].includes(_vm.form.status) &&
                              !(
                                _vm.flowOptions.isAssignee &&
                                _vm.flowOptions.nodeName == "开始"
                              ),
                          },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("新增")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "elTable",
                          attrs: {
                            data: _vm.form.hcContractLineChangeModels,
                            border: "",
                            stripe: "",
                            "summary-method": _vm.getSummaries,
                            "show-summary": "",
                            height: 280,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              label: "产品名称",
                              align: "left",
                              prop: "productCategory",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".productCategory",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写产品名称",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.productCategory,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "productCategory",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.productCategory",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              label: "项目类型",
                              align: "left",
                              prop: "projectType",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".projectType",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写项目类型",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              filterable: "",
                                              disabled:
                                                [2, 3].includes(
                                                  _vm.form.status
                                                ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                ),
                                            },
                                            model: {
                                              value: scope.row.projectType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "projectType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.projectType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.productCategoryOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              label: "产品型号",
                              align: "left",
                              prop: "model",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".model",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.model,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "model", $$v)
                                            },
                                            expression: "scope.row.model",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              label: "证书编号",
                              align: "left",
                              prop: "certificateNumber",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".certificateNumber",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.certificateNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "certificateNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.certificateNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              label: "发证机构",
                              align: "left",
                              prop: "certifyingAuthority",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".certifyingAuthority",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value:
                                              scope.row.certifyingAuthority,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "certifyingAuthority",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.certifyingAuthority",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "200",
                              label: "是否审厂",
                              align: "left",
                              prop: "isFactory",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".isFactory",
                                        },
                                      },
                                      [
                                        _c("el-checkbox", {
                                          attrs: {
                                            "true-label": "1",
                                            "false-label": "0",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.isFactory,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "isFactory",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.isFactory",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "项目单价",
                              align: "left",
                              "show-overflow-tooltip": "",
                              prop: "projectPrice",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".projectPrice",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row.contractLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.projectPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "projectPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.projectPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "projectSn",
                              label: "项目号",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".projectSn",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled: "",
                                          },
                                          model: {
                                            value: scope.row.projectSn,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "projectSn",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.projectSn",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "要求完成工作日",
                              align: "left",
                              "show-overflow-tooltip": "",
                              prop: "askAccomplishTime",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".askAccomplishTime",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            "value-format": "yyyy-MM-dd",
                                            placeholder: "选择日期",
                                            clearable: "",
                                            disabled:
                                              scope.row.contractLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.askAccomplishTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "askAccomplishTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.askAccomplishTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "laboratory",
                              label: "实验室",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".laboratory",
                                          rules: [
                                            {
                                              required:
                                                _vm.flowOptions.isAssignee &&
                                                [2].includes(_vm.form.status) &&
                                                _vm.flowOptions.nodeName ==
                                                  "总经理",
                                              message: "请填写实验室",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row.contractLineId ||
                                              !_vm.flowOptions.isAssignee ||
                                              ![2].includes(_vm.form.status) ||
                                              _vm.flowOptions.nodeName !=
                                                "总经理",
                                          },
                                          model: {
                                            value: scope.row.laboratory,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "laboratory",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.laboratory",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "merchandiser",
                              label: "跟单员",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".merchandiser",
                                          rules: [
                                            {
                                              required:
                                                _vm.flowOptions.isAssignee &&
                                                [2].includes(_vm.form.status) &&
                                                _vm.flowOptions.nodeName ==
                                                  "总经理",
                                              message: "请填写跟单员",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择",
                                              clearable: "",
                                              disabled:
                                                scope.row.contractLineId ||
                                                !_vm.flowOptions.isAssignee ||
                                                ![2].includes(
                                                  _vm.form.status
                                                ) ||
                                                _vm.flowOptions.nodeName !=
                                                  "总经理",
                                            },
                                            model: {
                                              value: scope.row.merchandiser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "merchandiser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.merchandiser",
                                            },
                                          },
                                          _vm._l(
                                            _vm.merchandiserOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.adminId,
                                                attrs: {
                                                  label: item.userName,
                                                  value: item.userName,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "培训工程师",
                              align: "left",
                              "show-overflow-tooltip": "",
                              prop: "cultivateEngineer",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".cultivateEngineer",
                                          rules: [
                                            {
                                              required:
                                                _vm.flowOptions.isAssignee &&
                                                [2].includes(_vm.form.status) &&
                                                _vm.flowOptions.nodeName ==
                                                  "总经理",
                                              message: "请填写培训工程师",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择培训工程师",
                                              clearable: "",
                                              disabled:
                                                scope.row.contractLineId ||
                                                !_vm.flowOptions.isAssignee ||
                                                ![2].includes(
                                                  _vm.form.status
                                                ) ||
                                                _vm.flowOptions.nodeName !=
                                                  "总经理",
                                            },
                                            model: {
                                              value:
                                                scope.row.cultivateEngineer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "cultivateEngineer",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.cultivateEngineer",
                                            },
                                          },
                                          _vm._l(
                                            _vm.cultivateEngineerOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.adminId,
                                                attrs: {
                                                  label: item.userName,
                                                  value: item.userName,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "note",
                              label: "说明",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractLineChangeModels." +
                                            scope.$index +
                                            ".note",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row.contractLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.note,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "note", $$v)
                                            },
                                            expression: "scope.row.note",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            scope.row.contractLineId ||
                                            ([2, 3].includes(_vm.form.status) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              )),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "开票信息" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "注册地址",
                                prop: "registerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.registerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "registerAddress", $$v)
                                  },
                                  expression: "form.registerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "办公地址", prop: "workAddress" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.workAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "workAddress", $$v)
                                  },
                                  expression: "form.workAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纳税人识别号",
                                prop: "taxpayerCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.taxpayerCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "taxpayerCode", $$v)
                                  },
                                  expression: "form.taxpayerCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发票抬头",
                                prop: "invoiceTitle",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.invoiceTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "invoiceTitle", $$v)
                                  },
                                  expression: "form.invoiceTitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开户银行名称",
                                prop: "invoicingDepositBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.invoicingDepositBank,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingDepositBank",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingDepositBank",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "银行账号",
                                prop: "invoicingBankNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.invoicingBankNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingBankNumber",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingBankNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司电话",
                                prop: "invoicingCompanyPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.invoicingCompanyPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invoicingCompanyPhone",
                                      $$v
                                    )
                                  },
                                  expression: "form.invoicingCompanyPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "收款信息" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("收款信息")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: {
                            type: "text",
                            disabled:
                              [2, 3].includes(_vm.form.status) &&
                              !(
                                _vm.flowOptions.isAssignee &&
                                _vm.flowOptions.nodeName == "开始"
                              ),
                          },
                          on: { click: _vm.onAdd2 },
                        },
                        [_vm._v("新增")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.form.hcContractProceedsLineChangeModels,
                            border: "",
                            stripe: "",
                            height: 280,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "收款节点",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractProceedsLineChangeModels." +
                                            scope.$index +
                                            ".proceedsNode",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row
                                                .contractProceedsLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.proceedsNode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "proceedsNode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.proceedsNode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "收款方式",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractProceedsLineChangeModels." +
                                            scope.$index +
                                            ".paymentTerm",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row
                                                .contractProceedsLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.paymentTerm,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "paymentTerm",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.paymentTerm",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "收款比例",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractProceedsLineChangeModels." +
                                            scope.$index +
                                            ".ratio",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row
                                                .contractProceedsLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.ratio,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "ratio", $$v)
                                            },
                                            expression: "scope.row.ratio",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "金额",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractProceedsLineChangeModels." +
                                            scope.$index +
                                            ".price",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row
                                                .contractProceedsLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.price,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "price", $$v)
                                            },
                                            expression: "scope.row.price",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "说明",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcContractProceedsLineChangeModels." +
                                            scope.$index +
                                            ".note",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              scope.row
                                                .contractProceedsLineId ||
                                              ([2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                                !(
                                                  _vm.flowOptions.isAssignee &&
                                                  _vm.flowOptions.nodeName ==
                                                    "开始"
                                                )),
                                          },
                                          model: {
                                            value: scope.row.note,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "note", $$v)
                                            },
                                            expression: "scope.row.note",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled:
                                            scope.row.contractProceedsLineId ||
                                            ([2, 3].includes(_vm.form.status) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              )),
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete2(
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "附件" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        "on-remove": _vm.handleRemove,
                        "before-remove": _vm.beforeRemove,
                        "on-success": _vm.handleAvatarSuccess,
                        multiple: "",
                        "on-preview": _vm.handlePreview,
                        "file-list": _vm.form.hcContractAccessoryChangeModels,
                        disabled:
                          [2, 3].includes(_vm.form.status) &&
                          !(
                            _vm.flowOptions.isAssignee &&
                            _vm.flowOptions.nodeName == "开始"
                          ),
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              [2, 3].includes(_vm.form.status) &&
                              !(
                                _vm.flowOptions.isAssignee &&
                                _vm.flowOptions.nodeName == "开始"
                              ),
                            size: "small",
                            type: "primary",
                          },
                        },
                        [_vm._v("点击上传")]
                      ),
                      _c("div", {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "流程审批" } },
                [
                  _c("flow", {
                    ref: "flow",
                    attrs: { "custom-event": true, "flow-id": _vm.form.flowId },
                    on: {
                      flowLoad: _vm.flowLoad,
                      complete: _vm.onComplete,
                      stop: _vm.getData,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: [3].includes(_vm.form.status),
                        loading: _vm.loading,
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm.form.id && (_vm.form.status == 1 || !_vm.form.status)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("contractDialog", {
            ref: "contractDialog",
            on: { select: _vm.onSelect },
          }),
          _c("customerDialog", {
            ref: "customerDialog",
            on: { select: _vm.onSelect2 },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }