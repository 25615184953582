<!-- 选择品牌 -->
<template>
  <div>

      <el-button v-if="isButton" type="primary" @click="handleClick">{{text}}</el-button>
      <el-input v-if="!isButton" placeholder="选择产品信息" @focus="handleClick" :value="content" readonly>
        <i slot="suffix" class="el-icon-search" @click="handleClick" />
      </el-input>

    <el-dialog
      title="选择产品信息"
      :visible="dialogVisible"
      width="70%"
      :append-to-body="true"
      destroy-on-close
      center
      :before-close="handleClose"
    >
      <el-row ref="searchBox" class="search-box">
        <el-col :span="4">
          <el-input v-model="condition.materialCode" clearable placeholder="物料编码" @keyup.enter.native="getPage" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="condition.materialName" clearable placeholder="物料名称" @keyup.enter.native="getPage" />
        </el-col>

        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" @click="getPage">查询</el-button>
        </el-col>
      </el-row>
      <el-table
        ref="selectListTable"
        v-loading="loading"
        :data="tableData"
        :height="tableHeight"
        border
        @select="onSelect"
        @select-all="onSelectAll">
        <el-table-column type="selection" width="40"/>
        <el-table-column label="物料编码" align="left" prop="materialCode" show-overflow-tooltip width="170"/>
        <el-table-column label="物料名称" align="left" prop="materialName" show-overflow-tooltip width="170"/>
        <el-table-column label="基准价" align="left" prop="minimumPrice" show-overflow-tooltip width="170"/>
        <el-table-column label="计量单位名称" align="left" prop="primaryUnit" show-overflow-tooltip width="170"/>
        <el-table-column label="主单位/辅单位" align="left" prop="measureUnitName" show-overflow-tooltip width="170"/>
       <el-table-column label="物料分类" align="left" prop="materialClass" show-overflow-tooltip width="170"/>
      </el-table>
      <el-row class="footer-box">
        <el-col :span="5">
          <el-button type="primary" @click="handleConfirm">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-col>
        <el-col :span="14">
          <pagination
            :pager-count="7"
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getPage"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>

</template>

<script>
import Pagination from '@/components/Pagination'
import { getPagePopup } from '@/api/crm/base/product'
import { isEmpty } from '@/utils/validate'
import format from '@/utils/formatter'

export default {
  name: 'selectProductDialog',
  components: { Pagination },
  props: {
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    confirm: {
      type: Function,
      required: true
    },
    currentIndex: {
      type: Number
    },
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    validation: { // 校验
      type: Function,
      default: function() {
        return true
      }
    },
    content: { // 输入框内容
      type: String,
      default: null
    },
    isButton: {
      type: Boolean,
      default: false
    } // 是否为按钮，
  },

  data() {
    return {
      format: format,
      dialogVisible: false,
      loading: false,
      condition: {
        name: '',
        sn: '',
      },
      // 分页
      pagination: {
        total: 0,
        size: 20,
        current: 1
      },
      tableHeight: window.innerHeight - 400,
      tableData: []

    }
  },
  methods: {
    onSelect(selection, row) {
      if (!this.multiple) {
        this.$refs.selectListTable.clearSelection()
        this.$refs.selectListTable.toggleRowSelection(row)
      }
    },
    onSelectAll() {
      if (!this.multiple) {
        this.$refs.selectListTable.clearSelection()
      }
    },

    getPage() {
      this.loading = true
      getPagePopup(this.condition, this.pagination).then((response) => {
        const { current, total, size, records } = response.data
        this.tableData = records
        this.pagination.total = total
        this.pagination.current = current
        this.pagination.size = size
      }).finally(() => {
        this.loading = false
      })
    },
    handleConfirm() {
      const selectData = this.$refs.selectListTable.selection
      if (isEmpty(selectData)) {
        return this.$message.warning(this.placeholder)
      }
      if (this.multiple) {
        this.confirm(selectData, this.currentIndex)
      } else {
        this.confirm(selectData[0], this.currentIndex)
      }
      this.$emit('change')
      this.dialogVisible = false
    },

    handleClose() {
      this.dialogVisible = false
    },
    handleClick() {
      if (!this.validation()) {
        return
      }
      this.dialogVisible = true
      this.getPage()
    }
  }

}
</script>

<style scoped>
i:hover {
  cursor: pointer
}
el-button {

}
</style>
