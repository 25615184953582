import request from '@/utils/request'

export function getAll() {
  return request({
    url: '/sc/price/getAll',
    method: 'get'
  })
}

export function getPage(condition, pagination) {
  return request({
    url: '/sc/price/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function saveOrUpdate(data) {
  return request({
    url: '/sc/price/saveOrUpdate',
    method: 'post',
    data
  })
}

/**
 * 过期时间
 * @param data
 * @returns {AxiosPromise}
 */
export function updateStaleDated(data) {
  return request({
    url: '/sc/price/updateStaleDated',
    method: 'post',
    params: { expire:data.expire,isExpire:data.isExpire },
    data
  })
}

/**
 * 回复询价
 * @param data
 * @returns {AxiosPromise}
 */
export function saveReply(data) {
  return request({
    url: '/sc/price/saveReply',
    method: 'post',
    data
  })
}
export function get(id) {
  return request({
    url: '/sc/price/get',
    method: 'get',
    params: { id }
  })
}

export function deleteById(id) {
  return request({
    url: '/sc/price/deleteById',
    method: 'post',
    data: { id: id }
  })
}

export function deleteBatchByIds(ids) {
  return request({
    url: '/sc/price/deleteBatchById',
    method: 'post',
    data: { ids: ids }
  })
}

export function exportExcel(condition, pagination) {
  return request({
    url: '/sc/price/exportExcel',
    method: 'post',
    data: { condition: condition, pagination: pagination },
    responseType: 'blob'
  })
}

export function getUnreadNum() {
  return request({
    url: '/sc/price/getUnreadNum',
    method: 'post'
  })
}
export function selectPriceCompareUnread() {
  return request({
    url: '/sc/price/selectPriceCompareUnread',
    method: 'post'
  })
}
export function updateUnread(id) {
  return request({
    url: '/sc/price/updateUnread',
    method: 'post',
    data: { id: id }
  })
}
