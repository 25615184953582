<template>
  <div>
    <el-row>
      <el-col :span="21">
        <el-row class="search-box">
          <slot name="condition" :condition="condition" />
          <el-col :span="3">
            <el-button type="primary" icon="el-icon-search" @click="getPage">查询</el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="3" style="text-align: right;">
        <el-tooltip v-if="exportUrl" class="item" effect="dark" content="导出" placement="bottom">
          <el-button style="padding: 7px 7px;" icon="el-icon-download" />
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      :data="tableData"
      :height="height"
      border
    >
      <slot/>
    </el-table>

    <el-row class="footer-box">
      <el-col :span="12">
        <slot name="operateButton" />
      </el-col>
      <el-col :span="12" style="text-align: right">
        <pagination
          :total="pagination.total"
          :page.sync="pagination.current"
          :limit.sync="pagination.size"
          @pagination="getPage"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination'
import request from '@/utils/request'

export default {
  name: 'PageTable',
  components: { Pagination },
  props: {
    url: {
      type: String,
      required: true
    },
    exportUrl: String,
    height: {
      type: Number,
      default: window.innerHeight - 185
    }
  },
  data() {
    return {
      showEdit: false,
      showResetPassword: false,
      loading: false,

      // 分页
      pagination: {
        total: 0,
        size: 20,
        current: 1
      },

      condition: {},
      tableData: []

    }
  },
  created() {
    this.getPage()
  },
  methods: {

    getPage() {
      const condition = this.condition
      this.loading = true
      request({
        url: this.url,
        method: 'post',
        data: { condition: condition, pagination: this.pagination }
      }).then((response) => {
        const { current, total, size, records } = response.data
        this.tableData = records
        this.pagination.total = total
        this.pagination.current = current
        this.pagination.size = size
      }).finally(() => {
        this.loading = false
      })
    }

  }

}
</script>

<style scoped>

</style>
