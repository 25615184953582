var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "列表",
        visible: _vm.drawer,
        width: "800px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
        close: _vm.onClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            { ref: "searchBox", staticClass: "search-box" },
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "客户编号" },
                    model: {
                      value: _vm.condition.customerNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.condition, "customerNo", $$v)
                      },
                      expression: "condition.customerNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "客户名称" },
                    model: {
                      value: _vm.condition.customerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.condition, "customerName", $$v)
                      },
                      expression: "condition.customerName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.getPage },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "listTable",
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHeight,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户编号",
                  width: "100",
                  align: "left",
                  prop: "customerNo",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户名称",
                  width: "100",
                  align: "left",
                  prop: "customerName",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户简称",
                  width: "100",
                  align: "left",
                  prop: "customerAbbreviation",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "行业类别",
                  width: "150",
                  align: "left",
                  prop: "industryType",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.format.formatEnumDesc(
                                "HcIndustryType",
                                scope.row.industryType
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "联系人",
                  width: "100",
                  align: "left",
                  prop: "customerContacts",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "联系电话",
                  width: "100",
                  align: "left",
                  prop: "customerPhone",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "详细地址",
                  width: "200",
                  align: "left",
                  prop: "customerAddress",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "备注",
                  align: "left",
                  prop: "customerRemark",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  align: "left",
                  prop: "createUser",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  align: "left",
                  prop: "createTime",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "更新人",
                  align: "left",
                  prop: "updateUser",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  align: "left",
                  prop: "updateTime",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onSubmit(scope.row)
                              },
                            },
                          },
                          [_vm._v("确定 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "footer-box" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("pagination", {
                    attrs: {
                      total: _vm.pagination.total,
                      page: _vm.pagination.current,
                      limit: _vm.pagination.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.pagination, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.pagination, "size", $event)
                      },
                      pagination: _vm.getPage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }