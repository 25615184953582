var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        placeholder: _vm.placeholder,
        disabled: _vm.disabled,
        multiple: _vm.multiple,
        clearable: "",
        filterable: "",
      },
      on: { change: _vm.changeNode, blur: _vm.blur },
      model: {
        value: _vm.currentVal,
        callback: function ($$v) {
          _vm.currentVal = $$v
        },
        expression: "currentVal",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.name, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }