<template>
  <el-form ref="form" :model="user" :rules="rules">
    <el-form-item label="姓名" prop="name">
      <el-input v-model.trim="user.name" />
    </el-form-item>
    <el-form-item label="手机号" prop="mobile">
      <el-input v-model.trim="user.mobile" />
    </el-form-item>
    <el-form-item label="电子邮箱" prop="email">
      <el-input v-model.trim="user.email" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {saveOrUpdate} from "@/api/site/engineer";
import {updateInfo} from "@/api/system/admin";

export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: ''
        }
      }
    }
  },
  data() {
    return {
      rules:{
        name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        mobile: [{required: true, message: '请输入手机号', trigger: 'blur'}],
        email: [{required: true, message: '请输入电子邮箱', trigger: 'blur'}],
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }
        updateInfo(this.user).then(() => {
          this.$message.success('修改成功')
        })
      })

    }
  }
}
</script>
