var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ],
        1
      ),
      _vm.isAssignee
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 8 },
                      placeholder: "请输入审批意见",
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px", "margin-top": "5px" },
                },
                [
                  _c("el-button", { on: { click: _vm.refresh } }, [
                    _vm._v("刷新"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", loading: _vm.isLoading },
                      on: { click: _vm.handleComplete },
                    },
                    [_vm._v("同意")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", loading: _vm.isLoading },
                      on: { click: _vm.handleTransition },
                    },
                    [_vm._v("转办")]
                  ),
                  _vm.hasReject
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.isLoading, type: "danger" },
                          on: { click: _vm.handleReject },
                        },
                        [_vm._v("驳回")]
                      )
                    : _vm._e(),
                  _vm.hasReject
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.isLoading, type: "danger" },
                          on: { click: _vm.handleRejectTo },
                        },
                        [_vm._v("驳回至")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.isLoading },
                      on: { click: _vm.stop },
                    },
                    [_vm._v("中断")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            { attrs: { data: _vm.historyActivityList, border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "节点名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "开始时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endTime", label: "结束时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "assignee", label: "审批人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "处理类型" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "comment",
                  label: "审批意见",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "table-dialog",
        {
          ref: "tableDialog",
          staticStyle: { display: "none" },
          attrs: {
            title: "转办至",
            url: "/system/admin/page",
            confirm: _vm.confirmAdmin,
          },
          scopedSlots: _vm._u([
            {
              key: "condition",
              fn: function (ref) {
                var condition = ref.condition
                return [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入姓名" },
                        model: {
                          value: condition.name,
                          callback: function ($$v) {
                            _vm.$set(condition, "name", $$v)
                          },
                          expression: "condition.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入用户名" },
                        model: {
                          value: condition.username,
                          callback: function ($$v) {
                            _vm.$set(condition, "username", $$v)
                          },
                          expression: "condition.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "联系电话" },
                        model: {
                          value: condition.mobile,
                          callback: function ($$v) {
                            _vm.$set(condition, "mobile", $$v)
                          },
                          expression: "condition.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _c("el-table-column", {
            attrs: {
              prop: "username",
              label: "用户名",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "姓名", "show-overflow-tooltip": "" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "email",
              label: "邮箱",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mobile",
              label: "联系电话",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center", prop: "createTime" },
          }),
        ],
        1
      ),
      _c(
        "table-dialog",
        {
          ref: "rejectToDialog",
          staticStyle: { display: "none" },
          attrs: {
            title: "驳回至",
            url: "/flow/getHiTaskList",
            confirm: _vm.confirmRejectTo,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "节点名称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "assignee",
              label: "处理人",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "处理时间", align: "center", prop: "createTime" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }