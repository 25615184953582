<!-- 地址选则组件 -->
<template>
  <el-cascader
    ref="area"
    v-model="selectVal"
    v-loading="loading"
    :options="options"
    :props="props"
    :disabled="disabled"
    clearable
    :emit-path="false"
    @change="changeNode"
  />
</template>

<script>

import { getByParent, getByPath } from '@/api/basic/area'

export default {
  name: 'AreaSelect',
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      selectVal: null,
      loading: false,
      props: {
        lazy: true,
        value: 'id',
        label: 'name',
        leaf: 'leaf',
        lazyLoad(node, resolve) {
          getByParent(node.value).then((response) => {
            const { data } = response
            resolve(data)
          })
        }
      },
      options: []

    }
  },
  watch: {
    value(value, oldValue) {
      // 保证实时刷新（临时处理方案）
      if (Array.isArray(this.selectVal)) {
        this.selectVal = value
        return
      }
      this.selectVal = value
      this.getByPath()
    }
  },
  created() {
    this.selectVal = this.value
    this.getByPath()
  },

  methods: {
    changeNode(nodeVal) {
      const areaId = nodeVal[nodeVal.length - 1]
      this.$emit('input', areaId)
      this.$emit('change', areaId, nodeVal)
    },
    getByPath() {
      if (this.selectVal == null) {
        return
      }
      this.loading = true
      getByPath(this.selectVal).then((response) => {
        const { data } = response
        this.options = data
      }).finally(() => {
        this.loading = false
        this.$emit('change', this.selectVal)
      })
    },

    // 选中某个值
    selected(value) {
      this.selectVal = value
      this.getByPath()
    }

  }

}
</script>

<style scoped>
  .el-cascader-panel {
    height:10px
  }
</style>
