<template>
  <el-drawer
    v-loading="loading"
    :title="form.id ? '编辑合同变更信息' : '新增合同变更信息'"
    :visible.sync="show"
    size="80%"
    :before-close="handleClose"
    append-to-body
    destroy-on-close
    center
    :close-on-click-modal="false"
  >
    <div style="height: calc(100vh - 110px); overflow-y: auto">
      <el-form
        ref="form"
        :disabled="type == '查看'"
        :model="form"
        :rules="rules"
        label-width="130px"
      >
        <el-card header="基础信息">
          <el-row>
            <el-col :span="6">
              <el-form-item label="合同变更号" prop="sn">
                <el-input v-model="form.sn" disabled placeholder="" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="合同编码" prop="contractApplySn">
                <el-input
                  v-model="form.contractApplySn"
                  placeholder=""
                  clearable
                  readonly
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                  @click.native="onPicker"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="合同号" prop="contractSn">
                <el-input
                  v-model="form.contractSn"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="合同名称" prop="contractName">
                <el-input
                  v-model="form.contractName"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="业务员" prop="salesman">
                <!-- <el-input
                v-model="form.salesman"
                placeholder=""
                clearable
                :disabled="
                  [2, 3].includes(form.status) &&
                  !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                "
              /> -->

                <el-select
                  v-model="form.salesman"
                  placeholder="请选择业务员"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                >
                  <el-option
                    v-for="item in salesmanOptions"
                    :key="item.adminId"
                    :label="item.userName"
                    :value="item.userName"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户名称" prop="customerName">
                <el-input
                  v-model="form.customerName"
                  clearable
                  readonly
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                  @click.native="onPicker2"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户编码" prop="customerNo">
                <el-input
                  v-model="form.customerNo"
                  placeholder="请输入客户编码"
                  clearable
                  readonly
                  :disabled="true"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="单据状态" prop="status">
                <el-select v-model="form.status" clearable filterable disabled>
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户地区" prop="customerAreaId">
                <area-select v-model="form.customerAreaId" :disabled="true" />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户地址" prop="customerAddress">
                <el-input v-model="form.customerAddress" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="联系人" prop="contact">
                <el-input
                  v-model="form.contact"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="联系人电话" prop="contactMobile">
                <el-input
                  v-model="form.contactMobile"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="是否需要开票" prop="isInvoice">
                <el-select v-model="form.isInvoice" clearable filterable disabled>
                  <el-option
                    v-for="item in booleanOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item
                label="是否已收款"
                prop="isProceeds"
                :rules="[
                  {
                    required: flowOptions.isAssignee,
                    message: '请选择是否收款',
                    trigger: ['blur'],
                  },
                ]"
              >
                <el-select
                  v-model="form.isProceeds"
                  clearable
                  filterable
                  :disabled="
                    !flowOptions.isAssignee ||
                      ![2].includes(form.status) ||
                      flowOptions.nodeName != '财务'
                  "
                >
                  <el-option
                    v-for="item in booleanOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="总金额" prop="totalMoney">
                <el-input v-model="form.totalMoney" disabled clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="变更原因" prop="note">
                <span slot="label" style="color: red">变更原因</span>
                <el-input
                  v-model="form.note"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card header="合同明细">
          <div slot="header" class="clearfix">
            <span>合同明细</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              :disabled="
                [2, 3].includes(form.status) &&
                  !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
              "
              @click="onAdd"
            >新增</el-button>
          </div>
          <el-row>
            <el-table
              ref="elTable"
              :data="form.hcContractLineChangeModels"
              border
              stripe
              :summary-method="getSummaries"
              show-summary
              :height="280"
            >
              <el-table-column min-width="200" label="产品名称" align="left" prop="productCategory" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.productCategory'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写产品名称',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.productCategory"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column min-width="200" label="项目类型" align="left" prop="projectType" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.projectType'
                    "
                    :rules="[
                      {
                        required: true,
                        message: '请填写项目类型',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-select
                      v-model="scope.row.projectType"
                      clearable
                      filterable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    >
                      <el-option
                        v-for="item in productCategoryOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column min-width="200" label="产品型号" align="left" prop="model" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.model'
                    "
                  >
                    <el-input
                      v-model="scope.row.model"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column min-width="200" label="证书编号" align="left" prop="certificateNumber" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.certificateNumber'
                    "
                  >
                    <el-input
                      v-model="scope.row.certificateNumber"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column min-width="200" label="发证机构" align="left" prop="certifyingAuthority" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.certifyingAuthority'
                    "
                  >
                    <el-input
                      v-model="scope.row.certifyingAuthority"
                      placeholder=""
                      clearable
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column min-width="200" label="是否审厂" align="left" prop="isFactory" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.isFactory'
                    "
                  >
                    <el-checkbox
                      v-model="scope.row.isFactory"
                      true-label="1"
                      false-label="0"
                      :disabled="
                        [2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          )
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                label="项目单价"
                align="left"
                show-overflow-tooltip
                prop="projectPrice"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' +
                        scope.$index +
                        '.projectPrice'
                    "
                  >
                    <el-input
                      v-model="scope.row.projectPrice"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="projectSn"
                label="项目号"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.projectSn'
                    "
                  >
                    <el-input
                      v-model="scope.row.projectSn"
                      placeholder=""
                      clearable
                      disabled
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                label="要求完成工作日"
                align="left"
                show-overflow-tooltip
                prop="askAccomplishTime"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' +
                        scope.$index +
                        '.askAccomplishTime'
                    "
                  >
                    <el-date-picker
                      v-model="scope.row.askAccomplishTime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期"
                      clearable
                      :disabled="
                        scope.row.contractLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="laboratory"
                label="实验室"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' + scope.$index + '.laboratory'
                    "
                    :rules="[
                      {
                        required:
                          flowOptions.isAssignee &&
                          [2].includes(form.status) &&
                          flowOptions.nodeName == '总经理',
                        message: '请填写实验室',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.laboratory"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractLineId ||
                          !flowOptions.isAssignee ||
                          ![2].includes(form.status) ||
                          flowOptions.nodeName != '总经理'
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                prop="merchandiser"
                label="跟单员"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' +
                        scope.$index +
                        '.merchandiser'
                    "
                    :rules="[
                      {
                        required:
                          flowOptions.isAssignee &&
                          [2].includes(form.status) &&
                          flowOptions.nodeName == '总经理',
                        message: '请填写跟单员',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <el-input
                    v-model="scope.row.merchandiser"
                    placeholder=""
                    clearable
                    :disabled="
                      scope.row.contractLineId ||
                      !flowOptions.isAssignee ||
                      ![2].includes(form.status) ||
                      flowOptions.nodeName != '总经理'
                    "
                  /> -->
                    <el-select
                      v-model="scope.row.merchandiser"
                      placeholder="请选择"
                      clearable
                      :disabled="
                        scope.row.contractLineId ||
                          !flowOptions.isAssignee ||
                          ![2].includes(form.status) ||
                          flowOptions.nodeName != '总经理'
                      "
                    >
                      <el-option
                        v-for="item in merchandiserOptions"
                        :key="item.adminId"
                        :label="item.userName"
                        :value="item.userName"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                label="培训工程师"
                align="left"
                show-overflow-tooltip
                prop="cultivateEngineer"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractLineChangeModels.' +
                        scope.$index +
                        '.cultivateEngineer'
                    "
                    :rules="[
                      {
                        required:
                          flowOptions.isAssignee &&
                          [2].includes(form.status) &&
                          flowOptions.nodeName == '总经理',
                        message: '请填写培训工程师',
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <!-- <el-input
                    v-model="scope.row.cultivateEngineer"
                    placeholder=""
                    clearable
                    :disabled="
                      scope.row.contractLineId ||
                      !flowOptions.isAssignee ||
                      ![2].includes(form.status) ||
                      flowOptions.nodeName != '总经理'
                    "
                  /> -->

                    <el-select
                      v-model="scope.row.cultivateEngineer"
                      placeholder="请选择培训工程师"
                      clearable
                      :disabled="
                        scope.row.contractLineId ||
                          !flowOptions.isAssignee ||
                          ![2].includes(form.status) ||
                          flowOptions.nodeName != '总经理'
                      "
                    >
                      <el-option
                        v-for="item in cultivateEngineerOptions"
                        :key="item.adminId"
                        :label="item.userName"
                        :value="item.userName"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                prop="note"
                label="说明"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'hcContractLineChangeModels.' + scope.$index + '.note'"
                  >
                    <el-input
                      v-model="scope.row.note"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="操作" fixed="right" width="200">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    :disabled="
                      scope.row.contractLineId ||
                        ([2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          ))
                    "
                    @click="handleDelete(scope.$index)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-card>
        <el-card header="开票信息">
          <el-row>
            <el-col :span="5">
              <el-form-item label="注册地址" prop="registerAddress">
                <el-input v-model="form.registerAddress" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="办公地址" prop="workAddress">
                <el-input v-model="form.workAddress" disabled clearable />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="纳税人识别号" prop="taxpayerCode">
                <el-input v-model="form.taxpayerCode" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="发票抬头" prop="invoiceTitle">
                <el-input v-model="form.invoiceTitle" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="开户银行名称" prop="invoicingDepositBank">
                <el-input
                  v-model="form.invoicingDepositBank"
                  disabled
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="银行账号" prop="invoicingBankNumber">
                <el-input v-model="form.invoicingBankNumber" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item label="公司电话" prop="invoicingCompanyPhone">
                <el-input
                  v-model="form.invoicingCompanyPhone"
                  disabled
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card header="收款信息">
          <div slot="header" class="clearfix">
            <span>收款信息</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              :disabled="
                [2, 3].includes(form.status) &&
                  !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
              "
              @click="onAdd2"
            >新增</el-button>
          </div>
          <el-row>
            <el-table
              :data="form.hcContractProceedsLineChangeModels"
              border
              stripe
              :height="280"
            >
              <el-table-column
                label="收款节点"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractProceedsLineChangeModels.' +
                        scope.$index +
                        '.proceedsNode'
                    "
                  >
                    <el-input
                      v-model="scope.row.proceedsNode"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractProceedsLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="收款方式"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractProceedsLineChangeModels.' +
                        scope.$index +
                        '.paymentTerm'
                    "
                  >
                    <el-input
                      v-model="scope.row.paymentTerm"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractProceedsLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="收款比例"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractProceedsLineChangeModels.' +
                        scope.$index +
                        '.ratio'
                    "
                  >
                    <el-input
                      v-model="scope.row.ratio"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractProceedsLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="金额" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractProceedsLineChangeModels.' +
                        scope.$index +
                        '.price'
                    "
                  >
                    <el-input
                      v-model="scope.row.price"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractProceedsLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="说明" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'hcContractProceedsLineChangeModels.' +
                        scope.$index +
                        '.note'
                    "
                  >
                    <el-input
                      v-model="scope.row.note"
                      placeholder=""
                      clearable
                      :disabled="
                        scope.row.contractProceedsLineId ||
                          ([2, 3].includes(form.status) &&
                            !(
                              flowOptions.isAssignee &&
                              flowOptions.nodeName == '开始'
                            ))
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="操作" fixed="right" width="200">
                <template slot-scope="scope">
                  <el-button
                    :disabled="
                      scope.row.contractProceedsLineId ||
                        ([2, 3].includes(form.status) &&
                          !(
                            flowOptions.isAssignee && flowOptions.nodeName == '开始'
                          ))
                    "
                    type="text"
                    @click="handleDelete2(scope.$index)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-card>

        <el-card header="附件">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="handleAvatarSuccess"
            multiple
            :on-preview="handlePreview"
            :file-list="form.hcContractAccessoryChangeModels"
            :disabled="
              [2, 3].includes(form.status) &&
                !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
            "
          >
            <el-button
              :disabled="
                [2, 3].includes(form.status) &&
                  !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
              "
              size="small"
              type="primary"
            >点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
            <!-- 只能上传jpg/png文件，且不超过500kb -->
            </div>
          </el-upload>
        </el-card>

        <el-card header="流程审批">
          <flow
            ref="flow"
            :custom-event="true"
            :flow-id="form.flowId"
            @flowLoad="flowLoad"
            @complete="onComplete"
            @stop="getData"
          />
        </el-card>
        <el-form-item>
          <el-button
            :disabled="[3].includes(form.status)"
            :loading="loading"
            type="primary"
            @click="submitForm('form')"
          >确认</el-button>
          <el-button
            v-if="form.id && (form.status == 1 || !form.status)"
            type="primary"
            @click="submit()"
          >提交</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
      <contractDialog ref="contractDialog" @select="onSelect" />
      <customerDialog ref="customerDialog" @select="onSelect2" />
    </div>
  </el-drawer>
</template>

<script>
import {
  get,
  saveOrUpdate,
  submit
} from '@/api/hc/contract-change/contract-change'
import {
  getSalesmanOptions,
  getCultivateEngineerOptions
} from '@/api/hc/contract/contract'
import { getSelectMerchandiser } from '@/api/hc/contract/contract'
import Flow from '@/components/Flow'
import contractDialog from '@/views/hc/components/contractDialog'
import AreaSelect from '@/views/fwpt/basic/area/AreaSelect'
import customerDialog from '@/views/hc/components/customerDialog'
export default {
  name: 'hcContractChangeEdit',
  components: { Flow, contractDialog, AreaSelect, customerDialog },
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    editId: Number
  },

  data() {
    return {
      salesmanOptions: [],
      cultivateEngineerOptions: [],
      merchandiserOptions: [],
      type: '',
      flowOptions: {},
      uploadUrl: process.env.VUE_APP_FILE_UPLOAD_URL,
      statusOptions: [
        {
          label: '制单',
          value: 1
        },
        {
          label: '审核中',
          value: 2
        },
        {
          label: '审核完成',
          value: 3
        },
        {
          label: '驳回',
          value: 4
        }
      ],
      productCategoryOptions: [
        {
          label: 'CCC新申请',
          value: 1
        },
        {
          label: 'CCCS同类新申请',
          value: 2
        },
        {
          label: '能效检测',
          value: 3
        },
        {
          label: '委托测试项目',
          value: 4
        },
        {
          label: '化学项目',
          value: 5
        },
        {
          label: '体系项目',
          value: 6
        },
        {
          label: '节能认证新申请',
          value: 7
        },
        {
          label: '节能认证同类新申请',
          value: 8
        },
        {
          label: '环保认证',
          value: 9
        },
        {
          label: '环保认证同类新申请',
          value: 10
        },
        {
          label: 'CB认证',
          value: 11
        },
        {
          label: 'CE认证',
          value: 12
        }
      ],
      projectTypeOptions: [
        {
          label: 'CCCS同类新申请',
          value: 1
        },
        {
          label: '能效检测',
          value: 2
        },
        {
          label: '委托测试项目',
          value: 3
        },
        {
          label: '化学项目',
          value: 4
        },
        {
          label: '节能认证同类新申请',
          value: 5
        },
        {
          label: '环保认证同类新申请',
          value: 6
        },
        {
          label: 'CB认证',
          value: 7
        },
        {
          label: 'CE认证',
          value: 8
        }
      ],
      booleanOptions: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      projectStatOptions: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '异常',
          value: 2
        }
      ],
      loading: false,
      form: {
        sn: '', // 合同变更号
        contractApplySn: '', // 合同编码
        contractSn: '', // 合同号
        contractName: '', // 合同名称
        salesman: '', // 业务员
        customerId: '', //
        customerName: '', // 客户名称
        customerNo: '', // 客户编码
        status: '', // 单据状态
        customerAreaId: '', // 客户地区
        customerAddress: '', // 客户地址
        contact: '', // 联系人
        contactMobile: '', // 联系人电话
        isInvoice: '', // 是否需要开票
        isProceeds: '', // 是否已收款
        totalMoney: '', // 总金额
        note: '', // 变更原因

        registerAddress: '', // 注册地址
        workAddress: '', // 办公地址
        taxpayerCode: '', // 纳税人识别号
        invoiceTitle: '', // 发票抬头
        invoicingDepositBank: '', // 开户行名称
        invoicingBankNumber: '', // 银行账号
        invoicingCompanyPhone: '', // 办公电话

        hcContractLineChangeModels: [],
        hcContractProceedsLineChangeModels: [],
        hcContractAccessoryChangeModels: []
      },
      rules: {
        note: [
          {
            required: true,
            message: '变更原因不能为空',
            trigger: ['blur', 'change']
          }
        ],
        contractApplySn: [
          {
            required: true,
            message: '合同编码不能为空',
            trigger: ['blur', 'change']
          }
        ], // 合同编码
        contractSn: [
          {
            required: true,
            message: '合同号不能为空',
            trigger: ['blur', 'change']
          }
        ], // 合同号
        contractName: [
          {
            required: true,
            message: '合同名称不能为空',
            trigger: ['blur', 'change']
          }
        ], // 合同名称
        salesman: [
          {
            required: true,
            message: '业务员不能为空',
            trigger: ['blur', 'change']
          }
        ], // 业务员
        customerName: [
          {
            required: true,
            message: '客户名称不能为空',
            trigger: ['blur', 'change']
          }
        ] // 客户
      }
    }
  },
  watch: {
    editId(editId) {},
    show(show) {
      if (!show) {
        return
      }
      if (this.editId == null) {
        this.form = this.$options.data.call(this).form
        return
      }
      this.getData()
    }
  },
  created() {
    getSelectMerchandiser().then((res) => {
      this.merchandiserOptions = res.data.records || []
    })
    getSalesmanOptions().then((res) => {
      this.salesmanOptions = res.data.records || []
    })
    getCultivateEngineerOptions().then((res) => {
      this.cultivateEngineerOptions = res.data.records || []
    })
    this.type = this.$route.query.type
    if (this.editId == null && this.$route.query.id) {
      this.editId = this.$route.query.id
      this.getData()
    }
  },
  methods: {
    async getData() {
      if (!this.editId) return
      this.loading = true
      try {
        const response = await get(this.editId)
        const { data } = response
        this.form = data
        this.loading = false
        if (!Array.isArray(this.form.hcContractLineChangeModels)) {
          this.form.hcContractLineChangeModels = []
        }

        if (!Array.isArray(this.form.hcContractProceedsLineChangeModels)) {
          this.form.hcContractProceedsLineChangeModels = []
        }

        if (Array.isArray(this.form.hcContractAccessoryChangeModels)) {
          this.form.hcContractAccessoryChangeModels.forEach((item) => {
            this.$set(item, 'url', item.accessoryUrl)
            this.$set(item, 'name', item.accessoryName)
          })
        } else {
          this.form.hcContractAccessoryChangeModels = []
        }

        this.$nextTick(() => {
          this.$refs.elTable.doLayout()
        })
      } catch (error) {
        this.loading = false
      }
    },
    // 流程提交
    async onComplete() {
      // 先执行保存
      await this.submitForm('form', false, async() => {
        // await this.getData();
        // 在执行提交
        this.$refs.flow.onComplete(() => {
          this.handleClose()
        })
      })
    },
    flowLoad(e) {
      console.log(e)
      this.flowOptions = e
      this.$refs.flow.validatorForm.push(this.$refs.isProceeds)
    },
    handlePreview(file) {
      console.log(file)
      window.open(file.url, file.url, '_blank')
      // saveAs(file.url, file.accessoryName || file.url);
    },
    beforeRemove(file, fileList) {
      if (file.contractApplyId) {
        return false
      } else {
        return true
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.form.hcContractAccessoryChangeModels = fileList
    },
    handleAvatarSuccess(response) {
      if (!response.status === 200) {
        this.$message.error(response.msg)
        return
      }
      const { fileName, url, md5 } = response.data
      this.form.hcContractAccessoryChangeModels.push({
        // contractApplyId: 1 /** 合同主表ID */,
        accessoryName: fileName,
        accessoryUrl: url /** 附件URL */,
        url: url /** 附件URL */,
        name: fileName /** 附件URL */
      })
      console.log(fileName, url, md5)
    },
    onPicker2() {
      if (!this.form.status || this.form.status == 1) {
        this.$refs.customerDialog.open()
      }
    },
    onPicker() {
      if (!this.form.status || this.form.status == 1) {
        this.$refs.contractDialog.open()
      }
    },
    onSelect2(row) {
      console.log(row, '5565656')
      Object.assign(this.form, {
        customerId: row.id,
        customerNo: row.customerNo, // 客户编码
        customerName: row.customerName, // 客户名称
        customerAreaId: row.customerAreaId, // 客户地区
        customerAddress: row.customerAddress, // 客户地址

        contact: row.customerContacts,
        contactMobile: row.customerPhone,
        // contact: row.contact, //联系人
        // contactMobile: row.contactMobile, //联系人电话
        registerAddress: row.invoicingRegisterAddress, // 注册地址
        workAddress: row.invoicingCompanyAddress, // 办公地址
        taxpayerCode: row.invoicingCompanyNumber, // 纳税人识别号
        invoiceTitle: row.invoiceTitle, //
        invoicingDepositBank: row.invoicingDepositBank, // 开户行名称
        invoicingBankNumber: row.invoicingBankNumber, // 银行账号
        invoicingCompanyPhone: row.invoicingCompanyPhone // 办公电话
      })
    },
    onSelect(row) {
      console.log(row)
      Object.assign(this.form, {
        contractApplyId: row.id, // 合同信息id
        contractApplySn: row.sn, // 合同编码
        contractSn: row.contractSn, // 合同号
        contractName: row.contractName, // 合同名称
        salesman: row.salesman, // 业务员
        customerId: row.customerId,
        customerNo: row.customerNo, // 客户编码
        customerName: row.customerName, // 客户名称
        customerAreaId: row.customerAreaId, // 客户地区
        customerAddress: row.customerAddress, // 客户地址
        contact: row.contact, // 联系人
        contactMobile: row.contactMobile, // 联系人电话
        totalMoney: row.totalMoney,
        isInvoice: row.isInvoice,
        // isProceeds:
        //   typeof row.isProceeds == "number"
        //     ? String(row.isProceeds)
        //     : row.isProceeds,
        registerAddress: row.registerAddress, // 注册地址
        workAddress: row.workAddress, // 办公地址
        taxpayerCode: row.taxpayerCode, // 纳税人识别号
        invoiceTitle: row.invoiceTitle, //
        invoicingDepositBank: row.invoicingDepositBank, // 开户行名称
        invoicingBankNumber: row.invoicingBankNumber, // 银行账号
        invoicingCompanyPhone: row.invoicingCompanyPhone, // 办公电话
        // note: row.note,
        hcContractLineChangeModels: row.hcContractLineChangeModels,
        hcContractProceedsLineChangeModels: row.hcContractProceedsLineModels,
        hcContractAccessoryChangeModels: row.hcContractAccessoryModels
      })
      console.log(this.form, this.flowOptions, '5555')
      if (Array.isArray(this.form.hcContractAccessoryChangeModels)) {
        this.form.hcContractAccessoryChangeModels.forEach((item) => {
          this.$set(item, 'url', item.accessoryUrl)
          this.$set(item, 'name', item.accessoryName)
        })
      } else {
        this.form.hcContractAccessoryChangeModels = []
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        const values = data.map((item) => {
          console.log(item[column.property])
          return Number(item[column.property])
        })
        console.log(data)
        console.log(values)
        if (
          !values.every((value) => isNaN(value)) &&
          ['projectPrice'].includes(column.property)
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          this.form.totalMoney = sums[index]
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    onAdd() {
      this.form.hcContractLineChangeModels.push({
        id: undefined,
        createTime: undefined,
        updateTime: undefined,
        createUser: undefined,
        updateUser: undefined,
        tenantCode: undefined,
        deleteFlag: undefined,
        version: undefined,
        contractLineId: undefined,
        contractApplyChangeId: undefined,
        contractApplyId: undefined,
        productCategory: undefined,
        projectType: undefined,
        projectPrice: undefined,
        projectSn: undefined,
        askAccomplishTime: undefined,
        laboratory: undefined,
        merchandiser: undefined,
        cultivateEngineer: undefined,
        note: undefined
      })
    },
    onAdd2() {
      this.form.hcContractProceedsLineChangeModels.push({
        proceedsNode: undefined, // 收款节点
        paymentTerm: undefined, // 收款方式
        ratio: undefined, // 收款比例
        price: undefined, // 金额
        note: undefined // 说明
      })
    },
    handleDelete(index) {
      this.form.hcContractLineChangeModels.splice(index, 1)
    },
    handleDelete2(index) {
      this.form.hcContractProceedsLineChangeModels.splice(index, 1)
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    handleClose() {
      Object.assign(this.$data, this.$options.data.call(this))
      // const view = this.$route
      // this.$store
      //   .dispatch('tagsView/delView', view)
      //   .then(({ visitedViews }) => {
      //     if (this.isActive(view)) {
      //       this.toLastView(visitedViews, view)
      //     }
      //   })
      this.$emit('close')
    },
    async submitForm(formName, isClose = false, cb) {
      // ---- 需要手工填写此代码 -----
      this.$refs[formName].validate(async(valid, obj) => {
        if (valid) {
          // ---- 需要手工填写此代码 -----
          try {
            this.loading = true
            const { message } = await saveOrUpdate(this.form)
            this.$message({
              type: 'success',
              message: message
            })
            await this.getData()
            console.log('33333')
            this.loading = false
            if (cb) {
              await cb()
            }
            if (isClose) {
              this.handleClose()
            }
          } catch (error) {
            this.loading = false
          }
        } else {
          console.log(obj)
          for (const key in obj) {
            this.$message({
              type: 'warning',
              message: obj[key][0].message
            })
            return false
          }
          console.log('error submit!!')
          return false
        }
      })
    },
    async submit() {
      // 先执行保存
      await this.submitForm('form', true, async() => {
        // await this.getData();
        // 在执行提交
        submit(this.form.id).then((response) => {
          const flowId = response.data.flowId
          this.$message.success('提交成功')
          this.form.flowId = flowId
          this.getData()
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
