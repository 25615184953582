import hcContractEdit from  '@/views/hc/contract/edit.vue'
import hcContractChangeEdit from  '@/views/hc/contract-change/edit.vue'
import hcCustomerEdit from  '@/views/hc/customer/edit.vue'
import hcCustomerChangeEdit from  '@/views/hc/customer-change/edit.vue'
import hcFlowEdit from  '@/views/hc/flow/edit.vue'
import hcPaymentSlipEdit from  '@/views/hc/payment-slip/edit.vue'
import hcReturnedMoneyEdit from  '@/views/hc/returned-money/edit.vue'


export default [
    hcContractEdit,
    hcContractChangeEdit,
    hcCustomerEdit,
    hcCustomerChangeEdit,
    hcFlowEdit,
    hcPaymentSlipEdit,
    hcReturnedMoneyEdit,
];