import request from "@/utils/request";
import {  Message } from 'element-ui'

/**
 * 下载文件
 * @param response
 */
export function downloadFile(response) {
  const resData = response.data
  const fileReader = new FileReader()
  fileReader.onloadend = () => {
    if (resData.type === 'application/json') {
      const data = JSON.parse(fileReader.result);
      Message({
        message: data.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      // 下载文件
      let contentDisposition = response.headers['content-disposition'];
      let fileName = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9);
      let blob = new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      })

      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = decodeURIComponent(fileName)
      link.click()
      //释放内存
      window.URL.revokeObjectURL(link.href);

    }
  };
  fileReader.readAsText(resData)

}


/**
 * 上传文件
 * @param url
 * @param form
 */
export function uploadFile(url, form) {
  return request({
    url,
    method: 'post',
    data: form,
    headers: {'Content-Type': 'multipart/form-data'}
  });

}


/**
 * 下载文件
 * @param response
 * @param name 文件名称加后缀(xlsx,zip,xls)
 */
export function downloadBlobFile(response, name) {
  // 以xls文件为例
  const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
  // 浏览器兼容，Google和火狐支持a标签的download，IE不支持
  if (window.navigator && window.navigator.msSaveBlob) {
    // IE浏览器、微软浏览器
    /* 经过测试，微软浏览器Microsoft Edge下载文件时必须要重命名文件才可以打开，
      IE可不重命名，以防万一，所以都写上比较好 */
    window.navigator.msSaveBlob(blob, name)
  } else {
    // 其他浏览器
    const link = document.createElement('a') // 创建a标签
    link.style.display = 'none'
    const objectUrl = URL.createObjectURL(blob)
    link.href = objectUrl
    link.download = name
    link.click()
    URL.revokeObjectURL(objectUrl)
  }
}
