<template>
  <el-drawer
    v-loading="loading"
    :title="form.id ? '编辑客户变更信息' : '新增客户变更信息'"
    :visible.sync="show"
    :before-close="handleClose"
    :modal="false"
    size="70%"
    :wrapper-closable="false"
    class="detailDrawer"
    append-to-body
    destroy-on-close
  >
    <div style="height: calc(100vh - 110px); overflow-y: auto">
      <el-form
        ref="form"
        :disabled="type == '查看'"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-card header="基础信息">
          <el-row>
            <el-col :span="6">
              <el-form-item label="客户变更号" prop="customerChangeNo">
                <el-input
                  v-model="form.customerChangeNo"
                  placeholder="自动生成"
                  disabled
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户编码" prop="customerNo">
                <el-input
                  v-model="form.customerNo"
                  placeholder="请输入客户编码"
                  clearable
                  readonly
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                  @click.native="onPicker"
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户名称" prop="customerName">
                <el-input
                  v-model="form.customerName"
                  placeholder="请输入客户名称"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户简称" prop="customerAbbreviation">
                <el-input
                  v-model="form.customerAbbreviation"
                  placeholder="请输入客户简称"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属行业" prop="industryType">
                <vocabulary-select
                  v-model="form.industryType"
                  placeholder=""
                  code="HcIndustryType"
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户地区" prop="customerAreaId">
                <area-select
                  v-model="form.customerAreaId"
                  :disabled="true"
                  @change="handleAreaSelect"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户地址" prop="customerAddress">
                <el-input
                  v-model="form.customerAddress"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
            <el-form-item label="是否有效" prop="customerAddress">
              <el-input
                v-model="form.customerAddress"
                placeholder=""
                clearable
              />
            </el-form-item>
          </el-col> -->
            <el-col :span="6">
              <el-form-item label="联系人" prop="customerContacts">
                <el-input
                  v-model="form.customerContacts"
                  placeholder="请输入联系人"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系电话" prop="customerTel">
                <el-input
                  v-model="form.customerTel"
                  placeholder="请输入联系电话"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
            <el-form-item label="单据状态" prop="status">
              <el-input v-model="form.status" placeholder="" clearable />
            </el-form-item>
          </el-col> -->

            <el-col :span="6">
              <el-form-item label="单据状态" prop="status">
                <el-select v-model="form.status" clearable filterable disabled>
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="变更原因" prop="changeReason">
                <span slot="label" style="color: red">变更原因</span>
                <el-input
                  v-model="form.changeReason"
                  placeholder=""
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="是否开票" prop="isInvoice">
                <el-select
                  v-model="form.isInvoice"
                  clearable
                  filterable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                >
                  <el-option
                    v-for="item in booleanOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card header="开票信息">
          <el-row>
            <el-col :span="6">
              <el-form-item label="注册地址" prop="invoicingRegisterAddress">
                <el-input
                  v-model="form.invoicingRegisterAddress"
                  placeholder="请输入注册地址"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="办公地址" prop="invoicingCompanyAddress">
                <el-input
                  v-model="form.invoicingCompanyAddress"
                  placeholder="请输入办公地址"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="纳税人识别号" prop="invoicingCompanyNumber">
                <el-input
                  v-model="form.invoicingCompanyNumber"
                  placeholder="请输入纳税人识别号"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="发票抬头" prop="invoiceTitle">
                <el-input
                  v-model="form.invoiceTitle"
                  placeholder="请输入发票抬头"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="开户银行" prop="invoicingDepositBank">
                <el-input
                  v-model="form.invoicingDepositBank"
                  placeholder="请输入开户银行"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="银行账号" prop="invoicingBankNumber">
                <el-input
                  v-model="form.invoicingBankNumber"
                  placeholder="请输入银行账号"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="公司电话" prop="invoicingCompanyPhone">
                <el-input
                  v-model="form.invoicingCompanyPhone"
                  placeholder="请输入公司电话"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card header="附件">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            multiple
            :limit="1"
            :on-preview="handlePreview"
            :file-list="form.hcCustomerChangeAccessoryModels"
            :disabled="[2, 3].includes(form.status)"
          >
            <el-button
              :disabled="[2, 3].includes(form.status)"
              size="small"
              type="primary"
            >点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
            <!-- 只能上传jpg/png文件，且不超过500kb -->
            </div>
          </el-upload>
        </el-card>

        <!-- <el-card header="流程审批">
          <flow
            ref="flow"
            :custom-event="true"
            :flow-id="form.flowId"
            @flowLoad="flowLoad"
            @complete="onComplete"
            @stop="getData"
          />
        </el-card> -->
        <el-form-item>
          <el-button
            :loading="isLoading"
            type="primary"
            @click="submitForm('form')"
          >确认</el-button>
          <el-button v-if="form.id && form.status == 1" type="primary" @click="onAudit(form.id)">审核</el-button>
          <!-- <el-button
            v-if="form.id && (form.status == 1 || !form.status)"
            type="primary"
            :loading="isLoading"
            @click="submit()"
          >提交</el-button> -->
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
      <customerDialog ref="customerDialog" @select="onSelect" />
    </div>
  </el-drawer>
</template>

<script>
import {
  get,
  saveOrUpdate,
  submit,
  audit
} from '@/api/hc/customer-change/customer-change'
import VocabularySelect from '@/views/mms/components/vocabulary/VocabularySelect'
import AreaSelect from '@/views/fwpt/basic/area/AreaSelect'
import customerDialog from '@/views/hc/components/customerDialog'
import Flow from '@/components/Flow'
export default {
  name: 'hcCustomerChangeEdit',
  components: {
    VocabularySelect,
    AreaSelect,
    customerDialog,
    Flow
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    editId: Number
  },

  data() {
    return {
      booleanOptions: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      uploadUrl: process.env.VUE_APP_FILE_UPLOAD_URL,
      type: '',
      isLoading: false,
      loading: false,
      flowOptions: {},
      statusOptions: [
        {
          label: '制单',
          value: 1
        },
        {
          label: '审核中',
          value: 2
        },
        {
          label: '审核完成',
          value: 3
        },
        {
          label: '驳回',
          value: 4
        }
      ],
      form: {
        id: null,
        customerChangeNo: null, // 客户变更号
        customerId: null, // 客户ID
        customerNo: null, // 客户编号
        customerName: null, // 客户名称
        customerAbbreviation: null, // 客户简称
        customerSource: null,
        industryType: null, // 所属行业
        companyType: null,
        customerPostcode: null,
        customerContacts: null, // 联系人
        customerTel: null, // 联系人电话
        customerPhone: null,
        customerEmail: null,
        customerAreaId: null, // 地区id
        customerAddress: null, // 详细地址
        customerFax: null,
        customerRemark: null,
        status: null, // 流程状态
        changeReason: null, // 变更原因
        invoicingRegisterAddress: null, // 注册地址
        invoicingCompanyAddress: null, // 公司地址
        invoicingCompanyNumber: null, // 纳税人识别号
        invoiceTitle: null, // 发票抬头
        invoicingDepositBank: null, // 开户银行
        invoicingBankNumber: null, // 银行账号
        invoicingCompanyPhone: null, // 公司电话
        flowId: null,
        flowTitle: null,
        flowUrgent: null,
        billNo: null,
        flowTaskFId: null,
        hcCustomerChangeAccessoryModels: []
      },
      rules: {
        customerNo: [
          {
            required: true,
            message: '客户编号不能为空',
            trigger: ['blur', 'change']
          }
        ], // 客户编号
        customerName: [
          {
            required: true,
            message: '客户名称不能为空',
            trigger: ['blur', 'change']
          }
        ], // 客户名称
        customerAreaId: [
          {
            required: true,
            message: '客户地区不能为空',
            trigger: ['blur', 'change']
          }
        ], // 客户地区
        customerAddress: [
          {
            required: true,
            message: '客户详细地址不能为空',
            trigger: ['blur', 'change']
          }
        ], // 客户详细地址
        customerContacts: [
          {
            required: true,
            message: '联系人不能为空',
            trigger: ['blur', 'change']
          }
        ], // 联系人
        customerTel: [
          {
            required: true,
            message: '联系人电话不能为空',
            trigger: ['blur', 'change']
          }
        ], // 联系人电话
        invoicingRegisterAddress: [
          {
            required: true,
            message: '注册地址不能为空',
            trigger: ['blur', 'change']
          }
        ], // 注册地址
        invoicingCompanyAddress: [
          {
            required: true,
            message: '公司地址不能为空',
            trigger: ['blur', 'change']
          }
        ], // 公司地址
        invoicingCompanyNumber: [
          {
            required: true,
            message: '纳税人识别号不能为空',
            trigger: ['blur', 'change']
          }
        ], // 纳税人识别号
        invoiceTitle: [
          {
            required: true,
            message: '发票抬头不能为空',
            trigger: ['blur', 'change']
          }
        ], // 发票抬头
        invoicingDepositBank: [
          {
            required: true,
            message: '开户银行不能为空',
            trigger: ['blur', 'change']
          }
        ], // 开户银行
        invoicingBankNumber: [
          {
            required: true,
            message: '银行账号不能为空',
            trigger: ['blur', 'change']
          }
        ], // 银行账号
        changeReason: [
          {
            required: true,
            message: '变更原因不能为空',
            trigger: ['blur', 'change']
          }
        ] // 客户编号
      }
    }
  },
  watch: {
    editId(editId) {},
    show(show) {
      this.form = this.$options.data.call(this).form
      if (!show) {
        return
      }
      if (this.editId == null) {
        return
      }
      this.getData()
    }
  },
  created() {
    this.type = this.$route.query.type
    if (this.editId == null && this.$route.query.id) {
      this.editId = this.$route.query.id
      this.getData()
    }
  },
  methods: {
    async onAudit(id) {
      try {
        await this.$confirm('确定审核通过吗', '提示', { type: 'warning' });
        this.loading = true;
        await audit({
          id
        })
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.handleClose();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.form.hcCustomerChangeAccessoryModels = fileList
    },
    handleAvatarSuccess(response) {
      if (!response.status === 200) {
        this.$message.error(response.msg)
        return
      }
      const { fileName, url, md5 } = response.data
      this.form.hcCustomerChangeAccessoryModels.push({
        // contractApplyId: 1 /** 合同主表ID */,
        accessoryName: fileName,
        accessoryUrl: url /** 附件URL */,
        url: url /** 附件URL */,
        name: fileName /** 附件URL */
      })
      console.log(fileName, url, md5)
    },
    handlePreview(file) {
      console.log(file)
      window.open(file.url, file.url, '_blank')
      // saveAs(file.url, file.accessoryName || file.url);
    },
    async submit() {
      // 先执行保存
      await this.submitForm('form', true, async() => {
        // await this.getData();
        // 在执行提交
        submit(this.form.id).then((response) => {
          const flowId = response.data.flowId
          this.$message.success('提交成功')
          this.form.flowId = flowId
          this.getData()
        })
      })
    },

    flowLoad(e) {
      console.log(e)
      this.flowOptions = e
    },
    // 流程提交
    async onComplete() {
      // 先执行保存
      await this.submitForm('form', false, async() => {
        // await this.getData();
        // 在执行提交
        this.$refs.flow.onComplete(() => {
          this.handleClose()
        })
      })
    },
    onPicker() {
      if (!this.form.status || this.form.status == 1) {
        this.$refs.customerDialog.open()
      }
    },
    async getData() {
      if (!this.editId) return
      this.loading = true
      try {
        const response = await get(this.editId)
        const { data } = response
        this.form = data
        this.loading = false
        if (Array.isArray(this.form.hcCustomerChangeAccessoryModels)) {
          this.form.hcCustomerChangeAccessoryModels.forEach((item) => {
            this.$set(item, 'url', item.accessoryUrl)
            this.$set(item, 'name', item.accessoryName)
          })
        } else {
          this.form.hcCustomerChangeAccessoryModels = []
        }
        this.$nextTick(() => {
          this.$refs.elTable.doLayout()
        })
      } catch (error) {
        this.loading = false
      }
    },
    onSelect(row) {
      console.log(row)
      Object.assign(this.form, {
        customerId: row.id, // 客户ID
        customerNo: row.customerNo, // 客户编号
        customerName: row.customerName, // 客户名称
        customerAbbreviation: row.customerAbbreviation, // 客户简称
        industryType: row.industryType, // 所属行业
        customerAreaId: row.customerAreaId, // 客户地区
        customerAddress: row.customerAddress, // 客户详细地址
        customerContacts: row.customerContacts, // 联系人
        customerTel: row.customerPhone, // 联系人电话
        invoicingRegisterAddress: row.invoicingRegisterAddress, // 注册地址
        invoicingCompanyAddress: row.invoicingCompanyAddress, // 公司地址
        invoicingCompanyNumber: row.invoicingCompanyNumber, // 纳税人识别号
        invoiceTitle: row.invoiceTitle, // 发票抬头
        invoicingDepositBank: row.invoicingDepositBank, // 开户银行
        invoicingBankNumber: row.invoicingBankNumber, // 银行账号
        invoicingCompanyPhone: row.invoicingCompanyPhone // 公司电话
      })
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    handleClose() {
      Object.assign(this.$data, this.$options.data.call(this))
      // const view = this.$route;
      // this.$store
      //   .dispatch("tagsView/delView", view)
      //   .then(({ visitedViews }) => {
      //     if (this.isActive(view)) {
      //       this.toLastView(visitedViews, view);
      //     }
      //   });
      this.$emit('close')
    },

    async submitForm(formName, isClose = false, cb) {
      // ---- 需要手工填写此代码 -----
      this.$refs[formName].validate(async(valid, obj) => {
        if (valid) {
          // ---- 需要手工填写此代码 -----
          try {
            this.loading = true
            const { message } = await saveOrUpdate(this.form)
            this.$message({
              type: 'success',
              message: message
            })
            await this.getData()
            console.log('33333')
            this.loading = false
            if (cb) {
              await cb()
            }
            if (isClose) {
              this.handleClose()
            }
          } catch (error) {
            this.loading = false
          }
        } else {
          console.log(obj)
          for (const key in obj) {
            this.$message({
              type: 'warning',
              message: obj[key][0].message
            })
            return false
          }
          console.log('error submit!!')
          return false
        }
      })
    },
    // submitForm(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (!valid) {
    //       return false;
    //     }
    //     saveOrUpdate(this.form).then(() => {
    //       this.handleClose();
    //       this.$message.success("操作成功");
    //     });
    //   });
    // },
    popoverHide(value) {
      this.item.parent = value
    },
    handleAreaSelect(val, obj) {
      this.$refs.form.validateField('customerAreaId')
    }
  }
}
</script>

<style scoped>
</style>
