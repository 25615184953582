var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.form.id ? "编辑回款信息维护" : "新增回款信息维护",
        visible: _vm.show,
        size: "80%",
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "destroy-on-close": "",
        center: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                disabled: _vm.type == "查看",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-card",
                { attrs: { header: "基础信息" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "回款单号", prop: "sn" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.sn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sn", $$v)
                                  },
                                  expression: "form.sn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "流程单号",
                                prop: "flowpathBillsSn",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "",
                                    clearable: "",
                                    readonly: "",
                                    disabled:
                                      [2, 3].includes(_vm.form.status) &&
                                      !(
                                        _vm.flowOptions.isAssignee &&
                                        _vm.flowOptions.nodeName == "开始"
                                      ),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onPicker.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.flowpathBillsSn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "flowpathBillsSn", $$v)
                                    },
                                    expression: "form.flowpathBillsSn",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "流程单名称",
                                prop: "flowpathBillsName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.flowpathBillsName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "flowpathBillsName", $$v)
                                  },
                                  expression: "form.flowpathBillsName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单据状态", prop: "status" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status",
                                  },
                                },
                                _vm._l(_vm.statusOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同名称",
                                prop: "contractApplyName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.contractApplyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractApplyName", $$v)
                                  },
                                  expression: "form.contractApplyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户名称",
                                prop: "customerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", disabled: true },
                                model: {
                                  value: _vm.form.customerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerName", $$v)
                                  },
                                  expression: "form.customerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "回款总金额",
                                prop: "returnedFee",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.returnedFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "returnedFee", $$v)
                                  },
                                  expression: "form.returnedFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "创建时间", prop: "createTime" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  value:
                                    _vm.form.createTime &&
                                    _vm
                                      .dayjs(_vm.form.createTime)
                                      .format("YYYY-MM-DD"),
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "note" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "note", $$v)
                                  },
                                  expression: "form.note",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "明细信息" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("明细信息")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: {
                            type: "text",
                            disabled:
                              [2, 3].includes(_vm.form.status) &&
                              !(
                                _vm.flowOptions.isAssignee &&
                                _vm.flowOptions.nodeName == "开始"
                              ),
                          },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("新增")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "elTable",
                          attrs: {
                            data: _vm.form.hcReturnedMoneyLineModels,
                            border: "",
                            stripe: "",
                            "summary-method": _vm.getSummaries,
                            "show-summary": "",
                            height: 280,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "费用类别",
                              align: "left",
                              prop: "expenseCategory",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcReturnedMoneyLineModels." +
                                            scope.$index +
                                            ".expenseCategory",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写费用类别",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.expenseCategory,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "expenseCategory",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.expenseCategory",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "returnedMoney",
                              label: "回款金额",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcReturnedMoneyLineModels." +
                                            scope.$index +
                                            ".returnedMoney",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写回款金额",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.returnedMoney,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "returnedMoney",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.returnedMoney",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "returnedTime",
                              label: "回款时间",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcReturnedMoneyLineModels." +
                                            scope.$index +
                                            ".returnedTime",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写回款时间",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            "value-format": "yyyy-MM-dd",
                                            placeholder: "选择日期",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.returnedTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "returnedTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.returnedTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "note",
                              label: "说明",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcReturnedMoneyLineModels." +
                                            scope.$index +
                                            ".note",
                                          rules: [
                                            {
                                              required: false,
                                              message: "请填写说明",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.note,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "note", $$v)
                                            },
                                            expression: "scope.row.note",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            [2, 3].includes(_vm.form.status) &&
                                            !(
                                              _vm.flowOptions.isAssignee &&
                                              _vm.flowOptions.nodeName == "开始"
                                            ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "附件" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleAvatarSuccess,
                        multiple: "",
                        "on-preview": _vm.handlePreview,
                        "file-list": _vm.form.hcReturnedMoneyAccessoryModels,
                        disabled:
                          !_vm.flowOptions.isAssignee ||
                          ![2].includes(_vm.form.status) ||
                          _vm.flowOptions.nodeName != "财务",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              !_vm.flowOptions.isAssignee ||
                              ![2].includes(_vm.form.status) ||
                              _vm.flowOptions.nodeName != "财务",
                            size: "small",
                            type: "primary",
                          },
                        },
                        [_vm._v("点击上传")]
                      ),
                      _c("div", {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "流程审批" } },
                [
                  _c("flow", {
                    ref: "flow",
                    attrs: { customEvent: true, "flow-id": _vm.form.flowId },
                    on: {
                      flowLoad: _vm.flowLoad,
                      complete: _vm.onComplete,
                      stop: _vm.getData,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: [3].includes(_vm.form.status),
                        loading: _vm.loading,
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm.form.id && (_vm.form.status == 1 || !_vm.form.status)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("flowDialog", { ref: "flowDialog", on: { select: _vm.onSelect } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }