import request from "@/utils/request";
export function getPage(condition, pagination) {
  return request({
    url: "/hc/returned/page",
    method: "post",
    data: { condition: condition, pagination: pagination },
  });
}

export function saveOrUpdate(data) {
  return request({
    url: "/hc/returned/saveOrUpdate",
    method: "post",
    data,
  });
}

export function get(id) {
  return request({
    url: "/hc/returned/get",
    method: "get",
    params: { id },
  });
}

export function submit(id) {
  return request({
    url: "/hc/returned/submit",
    method: "post",
    data: { id: id },
  });
}
export function deleteById(id) {
  return request({
    url: '/hc/returned/deleteById',
    method: 'post',
    data: { id: id }
  })
}