/**
 * 项目跟进
 */
import request from '@/utils/request'

export function getPage(condition, pagination) {
  return request({
    url: '/crm/project/record/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}
/**
 * 获取详情
 * @param id
 * @returns {AxiosPromise}
 */
export function get(id) {
  return request({
    url: '/crm/project/record/get',
    method: 'post',
    data: { id: id }
  })
}
