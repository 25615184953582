/**
 * 工程师请求类
 */
import request from '@/utils/request'
import { aes } from '@/utils/crypto'

export function listPage(condition, pagination) {
  return request({
    url: '/site/engineer/listPage',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function getDetail(id) {
  return request({
    url: '/site/engineer/getDetail',
    method: 'post',
    data: { id: id }
  })
}

export function saveOrUpdate(data) {
  return request({
    url: '/site/engineer/saveOrUpdate',
    method: 'post',
    data
  })
}

export function deleteById(id) {
  return request({
    url: '/site/engineer/deleteById',
    method: 'post',
    data: { id: id }
  })
}

/**
 * 重置密码
 */
export function resetPassword(id, password) {
  password = aes.encrypt(password)
  return request({
    url: '/site/engineer/resetPassword',
    method: 'post',
    data: { id: id, password: password }
  })
}
