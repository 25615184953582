<template>
  <el-drawer
    v-loading="loading"
    :title="form.id ? '编辑客户信息' : '新增客户信息'"
    :visible.sync="show"
    size="80%"
    :before-close="handleClose"
    append-to-body
    destroy-on-close
    center
    :close-on-click-modal="false"
  >
    <div style="height: calc(100vh - 110px); overflow-y: auto">
      <el-form
        ref="form"
        :disabled="type == '查看'"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-card header="基础信息">
          <el-row>
            <el-col :span="8">
              <el-form-item label="客户编号" prop="customerNo">
                <el-input
                  v-model="form.customerNo"
                  placeholder="自动生成"
                  disabled
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户名称" prop="customerName">
                <el-input
                  v-model="form.customerName"
                  placeholder="请输入客户名称"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户简称" prop="customerAbbreviation">
                <el-input
                  v-model="form.customerAbbreviation"
                  placeholder="请输入客户简称"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="所属行业" prop="industryType">
                <vocabulary-select
                  v-model="form.industryType"
                  placeholder=""
                  code="HcIndustryType"
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户地区" prop="customerAreaId">
                <area-select
                  v-model="form.customerAreaId"
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                  @change="handleAreaSelect"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细地址" prop="customerAddress">
                <el-input
                  v-model="form.customerAddress"
                  placeholder="请输入详细地址"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="联系人" prop="customerContacts">
                <el-input
                  v-model="form.customerContacts"
                  placeholder="请输入联系人"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话" prop="customerPhone">
                <el-input
                  v-model="form.customerPhone"
                  placeholder="请输入联系电话"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="备注" prop="customerRemark">
                <el-input
                  v-model="form.customerRemark"
                  placeholder="请输入备注"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="是否开票" prop="isInvoice">
                <el-select
                  v-model="form.isInvoice"
                  clearable
                  filterable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                >
                  <el-option
                    v-for="item in booleanOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card header="开票信息">
          <el-row>
            <el-col :span="8">
              <el-form-item label="注册地址" prop="invoicingRegisterAddress">
                <el-input
                  v-model="form.invoicingRegisterAddress"
                  placeholder="请输入注册地址"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司地址" prop="invoicingCompanyAddress">
                <el-input
                  v-model="form.invoicingCompanyAddress"
                  placeholder="请输入公司地址"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司电话" prop="invoicingCompanyPhone">
                <el-input
                  v-model="form.invoicingCompanyPhone"
                  placeholder="请输入公司电话"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="纳税人识别号" prop="invoicingCompanyNumber">
                <el-input
                  v-model="form.invoicingCompanyNumber"
                  placeholder="请输入纳税人识别号"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户银行" prop="invoicingDepositBank">
                <el-input
                  v-model="form.invoicingDepositBank"
                  placeholder="请输入开户银行"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="银行账号" prop="invoicingBankNumber">
                <el-input
                  v-model="form.invoicingBankNumber"
                  placeholder="请输入银行账号"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发票抬头" prop="invoiceTitle">
                <el-input
                  v-model="form.invoiceTitle"
                  placeholder="请输入发票抬头"
                  clearable
                  :disabled="
                    [2, 3].includes(form.status) &&
                      !(flowOptions.isAssignee && flowOptions.nodeName == '开始')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card header="附件">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            multiple
            :limit="1"
            :on-preview="handlePreview"
            :file-list="form.hcCustomerAccessoryModels"
            :disabled="[2, 3].includes(form.status)"
          >
            <el-button
              :disabled="[2, 3].includes(form.status)"
              size="small"
              type="primary"
            >点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              <!-- 只能上传jpg/png文件，且不超过500kb -->
            </div>
          </el-upload>
        </el-card>

        <!-- <el-card header="流程审批">
          <flow
            ref="flow"
            :custom-event="true"
            :flow-id="form.flowId"
            @flowLoad="flowLoad"
            @complete="onComplete"
            @stop="getData"
          />
        </el-card> -->

        <!--      <el-form-item label="邮编" prop="customerPostcode">-->
        <!--        <el-input v-model="form.customerPostcode" placeholder="请输入邮编" clearable/>-->
        <!--      </el-form-item>-->
        <!--      -->
        <!--      <el-form-item label="电话" prop="customerTel">-->
        <!--        <el-input v-model="form.customerTel" placeholder="请输入电话" clearable/>-->
        <!--      </el-form-item>-->
        <!--      -->
        <!--      <el-form-item label="电子邮件" prop="customerEmail">-->
        <!--        <el-input v-model="form.customerEmail" placeholder="请输入电子邮件" clearable/>-->
        <!--      </el-form-item>-->

        <!--      <el-form-item label="传真" prop="customerFax">-->
        <!--        <el-input v-model="form.customerFax" placeholder="请输入传真" clearable/>-->
        <!--      </el-form-item>-->

        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">确认</el-button>
          <el-button v-if="form.id && form.status == 1" type="primary" @click="onAudit(form.id)">审核</el-button>
          <!-- <el-button
            v-if="form.id && (form.status == 1 || !form.status)"
            type="primary"
            @click="submit()"
          >提交</el-button> -->
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
import { get, saveOrUpdate, submit, audit } from '@/api/hc/customer/customer'
import VocabularySelect from '@/views/mms/components/vocabulary/VocabularySelect'
import AreaSelect from '@/views/fwpt/basic/area/AreaSelect'
import Flow from '@/components/Flow'
export default {
  name: 'hcCustomerEdit',
  components: {
    VocabularySelect,
    AreaSelect,
    Flow
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    editId: Number
  },

  data() {
    return {
      booleanOptions: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      uploadUrl: process.env.VUE_APP_FILE_UPLOAD_URL,
      type: '',
      loading: false,
      flowOptions: {},
      form: {
        id: null,
        customerNo: null,
        customerName: null,
        customerAbbreviation: null,
        customerSource: null,
        industryType: null,
        companyType: null,
        customerPostcode: null,
        customerContacts: null,
        customerTel: null,
        customerPhone: null,
        customerEmail: null,
        customerAreaId: null,
        customerAddress: null,
        customerFax: null,
        customerRemark: null,
        status: null,
        flowId: null,
        flowTitle: null,
        flowUrgent: null,
        billNo: null,
        flowTaskFId: null,
        hcCustomerAccessoryModels: []
      },
      rules: {
        // customerNo: [{required: true, message: '客户编号不能为空', trigger: 'blur'}],
        customerName: [
          {
            required: true,
            message: '客户名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        customerAbbreviation: [
          {
            required: true,
            message: '客户简称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        // customerSource: [{required: true, message: '客户来源不能为空', trigger: 'blur'}],
        industryType: [
          {
            required: true,
            message: '所属行业不能为空',
            trigger: ['blur', 'change']
          }
        ],
        // customerPostcode: [{required: true, message: '邮编不能为空', trigger: 'blur'}],
        customerContacts: [
          {
            required: true,
            message: '联系人不能为空',
            trigger: ['blur', 'change']
          }
        ],
        // customerTel: [{required: true, message: '电话不能为空', trigger: 'blur'}],
        customerPhone: [
          {
            required: true,
            message: '联系电话不能为空',
            trigger: ['blur', 'change']
          }
        ],
        // customerEmail: [{required: true, message: '电子邮件不能为空', trigger: 'blur'}],
        customerAreaId: [
          {
            required: true,
            message: '客户地区不能为空',
            trigger: ['blur', 'change']
          }
        ],
        customerAddress: [
          {
            required: true,
            message: '详细地址不能为空',
            trigger: ['blur', 'change']
          }
        ],
        invoicingCompanyNumber: [
          {
            required: true,
            message: '纳税人识别号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        invoicingDepositBank: [
          {
            required: true,
            message: '开户银行不能为空',
            trigger: ['blur', 'change']
          }
        ],
        invoicingBankNumber: [
          {
            required: true,
            message: '银行账号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        invoicingRegisterAddress: [
          {
            required: true,
            message: '注册地址不能为空',
            trigger: ['blur', 'change']
          }
        ],
        invoicingCompanyAddress: [
          {
            required: true,
            message: '公司地址不能为空',
            trigger: ['blur', 'change']
          }
        ],
        invoicingCompanyPhone: [
          {
            required: true,
            message: '公司电话不能为空',
            trigger: ['blur', 'change']
          }
        ],
        invoiceTitle: [
          {
            required: true,
            message: '发票抬头不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  watch: {
    editId(editId) {},
    show(show) {
      this.form = this.$options.data.call(this).form
      if (!show) {
        return
      }
      if (this.editId == null) {
        return
      }
      this.getData()
    }
  },
  created() {
    this.type = this.$route.query.type
    if (this.editId == null && this.$route.query.id) {
      this.editId = this.$route.query.id
      this.getData()
    }
  },
  methods: {
    async onAudit(id) {
      try {
        await this.$confirm('确定审核通过吗', '提示', { type: 'warning' });
        this.loading = true;
        await audit({
          id
        })
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.handleClose();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.form.hcCustomerAccessoryModels = fileList
    },
    handleAvatarSuccess(response) {
      if (!response.status === 200) {
        this.$message.error(response.msg)
        return
      }
      const { fileName, url, md5 } = response.data
      this.form.hcCustomerAccessoryModels.push({
        // contractApplyId: 1 /** 合同主表ID */,
        accessoryName: fileName,
        accessoryUrl: url /** 附件URL */,
        url: url /** 附件URL */,
        name: fileName /** 附件URL */
      })
      console.log(fileName, url, md5)
    },
    handlePreview(file) {
      console.log(file)
      window.open(file.url, file.url, '_blank')
      // saveAs(file.url, file.accessoryName || file.url);
    },
    async submit() {
      // 先执行保存
      await this.submitForm('form', true, async() => {
        // await this.getData();
        // 在执行提交
        submit(this.form.id).then((response) => {
          const flowId = response.data.flowId
          this.$message.success('提交成功')
          this.form.flowId = flowId
          this.getData()
        })
      })
    },
    flowLoad(e) {
      console.log(e)
      this.flowOptions = e
    },
    // 流程提交
    async onComplete() {
      // 先执行保存
      await this.submitForm('form', false, async() => {
        // 在执行提交
        this.$refs.flow.onComplete(() => {
          this.handleClose()
        })
      })
    },

    async getData() {
      if (!this.editId) return
      this.loading = true
      try {
        const response = await get(this.editId)
        const { data } = response
        this.form = data
        this.loading = false
        if (Array.isArray(this.form.hcCustomerAccessoryModels)) {
          this.form.hcCustomerAccessoryModels.forEach((item) => {
            this.$set(item, 'url', item.accessoryUrl)
            this.$set(item, 'name', item.accessoryName)
          })
        } else {
          this.form.hcCustomerAccessoryModels = []
        }
        this.$nextTick(() => {
          this.$refs.elTable.doLayout()
        })
      } catch (error) {
        this.loading = false
      }
    },

    isActive(route) {
      return route.path === this.$route.path
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    handleClose() {
      Object.assign(this.$data, this.$options.data.call(this))
      // const view = this.$route;
      // this.$store
      //   .dispatch("tagsView/delView", view)
      //   .then(({ visitedViews }) => {
      //     if (this.isActive(view)) {
      //       this.toLastView(visitedViews, view);
      //     }
      //   });
      this.$emit('close')
    },

    async submitForm(formName, isClose = false, cb) {
      // ---- 需要手工填写此代码 -----
      this.$refs[formName].validate(async(valid, obj) => {
        if (valid) {
          // ---- 需要手工填写此代码 -----
          try {
            this.loading = true
            const { message } = await saveOrUpdate(this.form)
            this.$message({
              type: 'success',
              message: message
            })
            await this.getData()
            console.log('33333')
            this.loading = false
            if (cb) {
              await cb()
            }
            if (isClose) {
              this.handleClose()
            }
          } catch (error) {
            this.loading = false
          }
        } else {
          console.log(obj)
          for (const key in obj) {
            this.$message({
              type: 'warning',
              message: obj[key][0].message
            })
            return false
          }
          console.log('error submit!!')
          return false
        }
      })
    },
    popoverHide(value) {
      this.item.parent = value
    },
    handleAreaSelect(val, obj) {
      this.$refs.form.validateField('customerAreaId')
    }
  }
}
</script>

<style scoped>
</style>
