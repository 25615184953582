<!--词汇-选择器-->
<template>
  <el-select
    v-model="currentVal"
    :placeholder="placeholder"
    :disabled="disabled"
    :multiple="multiple"
    clearable
    filterable
    @change="changeNode"
    @blur="blur"
  >
    <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value" />
  </el-select>

</template>

<script>
import { getVocabulary } from '@/api/system/vocabulary'
import { isEmpty } from "@/utils/validate";
export default {
  name: 'VocabularySelect',
  props: {
    value: [ Number, String, Array ],
    code: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    disabled: Boolean,
    // 多选
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      currentVal: this.value
    }
  },
  watch: {
    value(val) {
      this.valueToString(val)
    }
  },
  created() {
    this.valueToString(this.value)
    this.getVocabulary()
  },
  methods: {
    getVocabulary() {
      getVocabulary(this.code).then((response) => {
        this.options = response.data
      })
    },
    changeNode(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    blur() {
      this.$emit('blur')
    },
    valueToString(val) {
      if (val instanceof Array) {
        this.currentVal = isEmpty(val) ? [] : val
      } else {
        // 因为词汇的value是string类型的，尔value可能会是number类型的，所以需要统一转完为string
        this.currentVal = val ? val + '' : val
      }
    }
  }
}
</script>

<style scoped>

</style>
