import request from '@/utils/request'
export function getPage(condition, pagination) {
  return request({
    url: '/hc/contract/change/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function saveOrUpdate(data) {
  return request({
    url: '/hc/contract/change/saveOrUpdate',
    method: 'post',
    data
  })
}

export function get(id) {
  return request({
    url: '/hc/contract/change/get',
    method: 'get',
    params: { id }
  })
}

export function deleteById(id) {
  return request({
    url: '/hc/contract/change/deleteById',
    method: 'post',
    data: { id: id }
  })
}

export function deleteBatchByIds(ids) {
  return request({
    url: '/hc/contract/deleteBatchById',
    method: 'post',
    data: { ids: ids }
  })
}

export function exportExcel(condition, pagination) {
  return request({
    url: '/hc/contract/exportExcel',
    method: 'post',
    data: { condition: condition, pagination: pagination },
    responseType: 'blob'
  })
}

export function submit(id) {
  return request({
    url: '/hc/contract/change/submit',
    method: 'post',
    data: { id: id }
  })
}
