/**
 * 客诉单据请求类
 */
import request from '@/utils/request'

export function getPage(condition, pagination) {
  return request({
    url: '/crm/complaint/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function deleteById(id) {
  return request({
    url: '/crm/complaint/deleteById',
    method: 'post',
    data: { id: id }
  })
}
export function saveOrUpdate(data) {
  return request({
    url: '/crm/complaint/saveOrUpdate',
    method: 'post',
    data
  })
}
/**
 * 确认当前数据
 * @param id
 * @returns {AxiosPromise}
 */
export function confirmed(id) {
  return request({
    url: '/crm/complaint/confirmed',
    method: 'post',
    data: { id: id }
  })
}

/**
 * 获取详情
 * @param id
 * @returns {AxiosPromise}
 */
export function get(id) {
  return request({
    url: '/crm/complaint/get',
    method: 'post',
    data: { id: id }
  })
}
