import request from '@/utils/request'


/**
 * 获取当前用户的自定列
 */
export function getAllMenuColumnData() {
  return request({
    url: '/system/adminColumn/getAllMenuColumnData',
    method: 'get',
  })
}


/**
 * 保存 自定列
 */
export function saveMenuColumnData(data) {
  return request({
    url: '/system/adminColumn/saveMenuColumnData',
    method: 'post',
    data:data
  })
}

/**
 * 删除 自定列
 */
export function delMenuColumnData(data) {
  return request({
    url: '/system/adminColumn/delMenuColumnData',
    method: 'post',
    data:data
  })
}
