var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.form.id ? "编辑缴费单申请" : "新增缴费单申请",
        visible: _vm.show,
        size: "80%",
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "destroy-on-close": "",
        center: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                disabled: _vm.type == "查看",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-card",
                { attrs: { header: "基础信息" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "缴费申请单号", prop: "sn" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.sn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sn", $$v)
                                  },
                                  expression: "form.sn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "流程单号",
                                prop: "flowpathBillsSn",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "",
                                    clearable: "",
                                    readonly: "",
                                    disabled:
                                      [2, 3].includes(_vm.form.status) &&
                                      !(
                                        _vm.flowOptions.isAssignee &&
                                        _vm.flowOptions.nodeName == "开始"
                                      ),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onPicker.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.flowpathBillsSn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "flowpathBillsSn", $$v)
                                    },
                                    expression: "form.flowpathBillsSn",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单据状态", prop: "status" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status",
                                  },
                                },
                                _vm._l(_vm.statusOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同名称",
                                prop: "contractApplyName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.contractApplyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractApplyName", $$v)
                                  },
                                  expression: "form.contractApplyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户名称",
                                prop: "customerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", disabled: true },
                                model: {
                                  value: _vm.form.customerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerName", $$v)
                                  },
                                  expression: "form.customerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "申请人", prop: "applyContact" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.applyContact,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "applyContact", $$v)
                                  },
                                  expression: "form.applyContact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "申请日期", prop: "applyTime" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  value:
                                    _vm.form.applyTime &&
                                    _vm
                                      .dayjs(_vm.form.applyTime)
                                      .format("YYYY-MM-DD"),
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "申请总金额", prop: "applyFee" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.applyFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "applyFee", $$v)
                                  },
                                  expression: "form.applyFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "会计名称",
                                prop: "accountantName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.accountantName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "accountantName", $$v)
                                  },
                                  expression: "form.accountantName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "会计审核时间",
                                prop: "accountantTime",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  value:
                                    _vm.form.accountantTime &&
                                    _vm
                                      .dayjs(_vm.form.accountantTime)
                                      .format("YYYY-MM-DD"),
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "总经理名称",
                                prop: "generalManager",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.form.generalManager,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "generalManager", $$v)
                                  },
                                  expression: "form.generalManager",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "总经理审批时间",
                                prop: "generalManagerTime",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  value:
                                    _vm.form.generalManagerTime &&
                                    _vm
                                      .dayjs(_vm.form.generalManagerTime)
                                      .format("YYYY-MM-DD"),
                                  placeholder: "",
                                  clearable: "",
                                  disabled: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态", prop: "jfType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled:
                                      [2, 3].includes(_vm.form.status) &&
                                      !(
                                        _vm.flowOptions.isAssignee &&
                                        _vm.flowOptions.nodeName == "开始"
                                      ),
                                  },
                                  model: {
                                    value: _vm.form.jfType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "jfType", $$v)
                                    },
                                    expression: "form.jfType",
                                  },
                                },
                                _vm._l(_vm.jfTypeOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否上传发票",
                                prop: "isInvoice",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: !(_vm.form.status == 3),
                                  },
                                  model: {
                                    value: _vm.form.isInvoice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isInvoice", $$v)
                                    },
                                    expression: "form.isInvoice",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发证机构",
                                prop: "certifyingAuthority",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.certifyingAuthority,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "certifyingAuthority",
                                      $$v
                                    )
                                  },
                                  expression: "form.certifyingAuthority",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发证机构费用",
                                prop: "attestationFee",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.attestationFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "attestationFee", $$v)
                                  },
                                  expression: "form.attestationFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "实验室", prop: "laboratory" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.laboratory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "laboratory", $$v)
                                  },
                                  expression: "form.laboratory",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "实验室费用",
                                prop: "laboratoryFee",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  type: "number",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.laboratoryFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "laboratoryFee", $$v)
                                  },
                                  expression: "form.laboratoryFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "note" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled:
                                    [2, 3].includes(_vm.form.status) &&
                                    !(
                                      _vm.flowOptions.isAssignee &&
                                      _vm.flowOptions.nodeName == "开始"
                                    ),
                                },
                                model: {
                                  value: _vm.form.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "note", $$v)
                                  },
                                  expression: "form.note",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "明细信息" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("明细信息")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: {
                            type: "text",
                            disabled:
                              [2, 3].includes(_vm.form.status) &&
                              !(
                                _vm.flowOptions.isAssignee &&
                                _vm.flowOptions.nodeName == "开始"
                              ),
                          },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("新增")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "elTable",
                          attrs: {
                            data: _vm.form.hcPayapplyFeeLineModels,
                            border: "",
                            stripe: "",
                            "summary-method": _vm.getSummaries,
                            "show-summary": "",
                            height: 280,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "事项",
                              align: "left",
                              prop: "matter",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".matter",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写事项",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.matter,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "matter", $$v)
                                            },
                                            expression: "scope.row.matter",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "应付金额",
                              align: "left",
                              prop: "amountPayable",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".amountPayable",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写应付金额",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.amountPayable,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "amountPayable",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.amountPayable",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "收款单位",
                              align: "left",
                              "show-overflow-tooltip": "",
                              prop: "receiptCompany",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".receiptCompany",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写收款单位",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.receiptCompany,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "receiptCompany",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.receiptCompany",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receiptBank",
                              label: "收款银行",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".receiptBank",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写收款银行",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.receiptBank,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "receiptBank",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.receiptBank",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "收款银行账号",
                              align: "left",
                              "show-overflow-tooltip": "",
                              prop: "receiptBankNumber",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".receiptBankNumber",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写收款银行账号",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.receiptBankNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "receiptBankNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.receiptBankNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receiptContact",
                              label: "收款人姓名",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".receiptContact",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写收款人姓名",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.receiptContact,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "receiptContact",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.receiptContact",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "receiptContactMobile",
                              label: "收款人电话",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".receiptContactMobile",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请填写收款人电话",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value:
                                              scope.row.receiptContactMobile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "receiptContactMobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.receiptContactMobile",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "note",
                              label: "说明",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "hcPayapplyFeeLineModels." +
                                            scope.$index +
                                            ".note",
                                          rules: [
                                            {
                                              required: false,
                                              message: "请填写说明",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            disabled:
                                              [2, 3].includes(
                                                _vm.form.status
                                              ) &&
                                              !(
                                                _vm.flowOptions.isAssignee &&
                                                _vm.flowOptions.nodeName ==
                                                  "开始"
                                              ),
                                          },
                                          model: {
                                            value: scope.row.note,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "note", $$v)
                                            },
                                            expression: "scope.row.note",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          disabled:
                                            [2, 3].includes(_vm.form.status) &&
                                            !(
                                              _vm.flowOptions.isAssignee &&
                                              _vm.flowOptions.nodeName == "开始"
                                            ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "收款方发票" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.handleAvatarSuccess,
                        multiple: "",
                        "on-preview": _vm.handlePreview,
                        "file-list": _vm.form.hcPayapplyFeeAccessoryModels,
                        disabled:
                          !_vm.flowOptions.isAssignee ||
                          ![2].includes(_vm.form.status) ||
                          _vm.flowOptions.nodeName != "出纳",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              !_vm.flowOptions.isAssignee ||
                              ![2].includes(_vm.form.status) ||
                              _vm.flowOptions.nodeName != "出纳",
                            size: "small",
                            type: "primary",
                          },
                        },
                        [_vm._v("点击上传")]
                      ),
                      _c("div", {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "发票附件" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        "on-remove": _vm.handleRemove2,
                        "on-success": _vm.handleAvatarSuccess2,
                        multiple: "",
                        "on-preview": _vm.handlePreview,
                        "file-list":
                          _vm.form.hcPayapplyFeeReceiptAccessoryModels,
                        disabled: !(_vm.form.status == 3 && _vm.form.isInvoice),
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !(
                              _vm.form.status == 3 && _vm.form.isInvoice
                            ),
                            size: "small",
                            type: "primary",
                          },
                        },
                        [_vm._v("点击上传")]
                      ),
                      _c("div", {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "流程审批" } },
                [
                  _c("flow", {
                    ref: "flow",
                    attrs: { "custom-event": true, "flow-id": _vm.form.flowId },
                    on: {
                      flowLoad: _vm.flowLoad,
                      complete: _vm.onComplete,
                      stop: _vm.getData,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm.form.id && (_vm.form.status == 1 || !_vm.form.status)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("flowDialog", { ref: "flowDialog", on: { select: _vm.onSelect } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }