<template>
  <div style="display: inline-block;margin-left: 10px;">
    <el-badge :value="total">
      <el-button type="primary" @click="dialogVisible = true">Excel导入</el-button>
    </el-badge>

    <el-dialog
      :title="title" :visible.sync="dialogVisible" width="70%" top="5vh">
      <el-upload drag action="/" :http-request="uploadFile"
        accept=".xlsx" class="excelImport">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">提示：将文件拖到此处，或<em>点击上传</em>，只能上传xlsx文件
          <br>导入结果将保留1天
        </div>

      </el-upload>
      <div class="el-upload__text">
        <el-button type="text" @click="downloadTemplate">下载模板</el-button>
      </div>


      <el-table :data="importTaskList" border v-loading="loading" max-height="350">
        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip/>
        <el-table-column prop="currentProgress" label="当前执行行数" width="100"/>
        <el-table-column prop="errorTotal" label="异常行数" width="70"/>
        <el-table-column prop="successTotal" label="成功行数" width="70"/>
        <el-table-column prop="complete" label="是否执行完成" width="100">
          <template slot-scope="scope">
            {{ format.formatBoolean(scope.row.complete) }}
          </template>
        </el-table-column>
        <el-table-column prop="data" label="导入时间" width="140"/>
        <el-table-column prop="createUser" label="导入用户"/>
        <el-table-column label="操作" width="100" fixed="right">
          <template slot="header">
            <el-button @click="getImportTaskList">刷新</el-button>
          </template>
          <template slot-scope="scope">
            <el-button v-if="scope.row.complete" type="text" @click="handleDownloadResult(scope.row)">下载结果</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>

</template>

<script>
import {downloadFile, uploadFile} from "@/utils/file";
import {downloadResult, downloadTemplate, getImportTaskList} from "@/api/system/excel";
import Format from "@/utils/formatter";

export default {
  name: "ExcelImport",
  props: {
    className: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Excel导入"
    },
    url: {
      type: String,
      required: true
    },
    templateName:{ // 模板路径模板名字
      type: String,
      required: true
    },
    fatherMethod: { // 调用父组件方法
      type: Function,
      default: null
    }
  },
  data() {
    return {
      format: Format,
      loading: false,
      total: null,

      dialogVisible: false,
      importTaskList: []

    };
  },
  created() {
    this.getImportTaskList();

  },

  methods: {
    uploadFile(param) {
      let fileObj = param.file;
      let form = new FormData();
      form.append("file", fileObj);
      uploadFile(this.url, form).then((response)=>{
        console.log(response)
        debugger
        this.$message.success("上传导入成功");
        this.getImportTaskList();
        if (this.fatherMethod) {
          this.fatherMethod()
        }
      })
    },
    getImportTaskList() {
      getImportTaskList(this.className).then((response => {
        this.importTaskList = response.data;

        // 计算执行中的任务数量
        let total = 0;
        this.importTaskList.forEach((item) => {``
          if (!item.complete) {
            total++;
          }
        });
        if (total === 0) {
          total = null;
        }
        this.total = total;

      }));
    },

    handleDownloadResult(row) {
      this.loading = true;
      downloadResult(row.key).then((response) => {
        downloadFile(response);
      }).finally(() => {
        this.loading = false;
      })
    },

    downloadTemplate() {
      downloadTemplate('/templates/' + this.templateName).then((response) => {
        response.headers['content-disposition'] = 'filename=' + this.templateName;
        downloadFile(response)
      });
    }

  }
};
</script>

<style>

/*1035px*/

.excelImport .el-upload-dragger {
  width: 1035px !important;
}

</style>
