<template>
  <div>
    <page-table ref="pageTable" url="/flow/myPending">
      <template v-slot:condition="{condition}">
        <el-col :span="3">
          <el-input v-model="condition.name" clearable placeholder="请输入流程名称" />
        </el-col>
<!--        <el-col :span="3">-->
<!--          <eb-vocabulary-select-->
<!--            v-model="condition.category"-->
<!--            code="FLOW_TEMPLATE_CATEGORY"-->
<!--            clearable-->
<!--            placeholder="请选择类别"-->
<!--          />-->
<!--        </el-col>-->
      </template>
      <template>
        <el-table-column label="单据号" prop="orderNo">
          <template slot-scope="{row}">
            <el-button @click="toOrder(row)" type="text">{{ row.orderNo }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="流程类型" prop="category" show-overflow-tooltip />
        <el-table-column label="启动人" prop="createUser" />
        <el-table-column label="流程名称" prop="name" />
        <el-table-column label="当前节点名称" prop="node" show-overflow-tooltip />
        <el-table-column label="当前流程处理人" prop="assignee" show-overflow-tooltip />
        <el-table-column label="创建时间" prop="createTime" width="140" />
      </template>
    </page-table>
    <component :is="currentView" ref="com" :show="showEdit" :edit-id="editId" @close="closeEdit" />
  </div>
</template>

<script>

import PageTable from "@/components/PageTable";
import injectComps from '@/views/components.js';
export default {
  name: 'MyPendingIndex',
  components: {PageTable},
  data() {
    return {
      currentView: '',
      showEdit: false,
      editId: null,
      injectCompNames: injectComps.map(item => item.name)
    }
  },
  methods: {
    closeEdit() {
      this.showEdit = false;
    },
    toOrder(row) {
      if (!row.componentName) {
        this.$message({
          type: 'warning',
          message: '没有配置单据类型'
        })
        return;
      }
      //判断时候配置了窗口打开
      if (this.injectCompNames.includes(row.componentName)) {
        console.log('配置了窗口打开')
        this.currentView = row.componentName;
        this.editId = row.businessId;
        this.$nextTick(() => {
          this.showEdit = true;
        })
      } else {
        console.log('原来打开', row)
        try {
          this.$router.push({ name: row.componentName, query: { id: row.businessId }})
        } catch (error) {
          console.log(error)
        }

      }
    }
  }
}
</script>

<style scoped>

</style>
