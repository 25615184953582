/**
 * 加载用户自定义列
 * this.sourceTableColumn 当前表格的所有列
 * this.columnUserData 当前表格自定义的列
 * @param refsTableName
 */
export function columnUserFunction(refsTableName,menuName){
  if(this.columnSourceTable.length==0){
    this.columnSourceTable = this.$refs[refsTableName].columns;
    this.columnChangeTable = [];
    for(var i=0;i<this.columnSourceTable.length;i++){
      if ((i==0&&this.columnSourceTable[i].type &&'selection'==this.columnSourceTable[i].type) || this.columnSourceTable[i].property == 'xuhao' || this.columnSourceTable[i].property == 'caozuo' || '操作'==this.columnSourceTable[i].label) {

      }else{
        this.columnChangeTable.push({label:this.columnSourceTable[i].label,property:this.columnSourceTable[i].property,isShow:"1",width:this.columnSourceTable[i].width,fixed:this.columnSourceTable[i].fixed});
      }
    }
  }
  const userAllMenuColumnData = window.localStorage.getItem('userAllMenuColumnData');
  if (userAllMenuColumnData == null || userAllMenuColumnData.length === 0) {
    return
  }
  var allData = JSON.parse(userAllMenuColumnData)
  this.columnUserData = allData[menuName];
  if(!this.columnUserData){
    //取租户默认的自定义列
    this.columnUserData = allData[menuName+"_org"];
    if(!this.columnUserData){
      return ;
    }
  }else{
    this.columnButtonType = "success"
  }
  /**
  this.columnUserData = [
    {property:'name',isShow:1,width:null},
    {property:'mobile',isShow:1,width:200},
    {property:'username',isShow:1,width:null},
    {property:'createTime',isShow:1,width:null},
    {property:'tenantCode',isShow:1,width:null},
    {property:'state',isShow:1,width:null},
    {property:'isEnabled',isShow:1,width:null},
  ];
   ***/
  if(this.columnUserData.length<=0){
    return ;
  }
  this.sourceTableColumn = this.$refs['listTable'].columns;
  var newColumns = [];
  this.columnChangeTable = [];
  var sourcenum = 0;
  if(this.columnSourceTable[0].property=='xuhao' || (this.columnSourceTable[0].type && 'selection'==this.columnSourceTable[0].type)){
    newColumns.push(this.sourceTableColumn[0]);
    sourcenum = 1;
  }

  for(var j=0;j<this.columnUserData.length;j++){
    for(var i=0;i<this.sourceTableColumn.length;i++){
      if(this.sourceTableColumn[i].property == this.columnUserData[j].property){
        if(this.columnUserData[j].isShow && this.columnUserData[j].isShow==0){
          break;
        }
        if(this.columnUserData[j].width!=null && this.columnUserData[j].width>0){
          this.sourceTableColumn[i].width = this.columnUserData[j].width;
        }
        this.sourceTableColumn[i].fixed = this.columnUserData[j].fixed;
        newColumns.push(this.sourceTableColumn[i]);
        if((i==0 && this.sourceTableColumn[i].type && 'selection'==this.sourceTableColumn[i].type) || this.sourceTableColumn[i].property == 'xuhao' || this.sourceTableColumn[i].property == 'caozuo' || '操作'==this.sourceTableColumn[i].label) {
        }else {
          this.columnChangeTable.push({label:this.sourceTableColumn[i].label,property:this.sourceTableColumn[i].property,isShow:"1",width:this.sourceTableColumn[i].width,fixed:this.sourceTableColumn[i].fixed});
        }
        break;
      }
    }
  }
  if(this.columnSourceTable[this.columnSourceTable.length-1].property=='caozuo' || '操作'==this.columnSourceTable[this.columnSourceTable.length-1].label){
    newColumns.push(this.sourceTableColumn[this.columnSourceTable.length-1]);
  }
  for(var i=sourcenum;i<this.columnSourceTable.length;i++){
    var  c = 0;
    for(var j=0;j<this.columnChangeTable.length;j++){
      if(this.columnSourceTable[i].property==this.columnChangeTable[j].property){
        c=2;
        break;
      }
    }
    if((i==0&& this.columnSourceTable[i].type && 'selection'==this.columnSourceTable[i].type) || this.columnSourceTable[i].property == 'xuhao' || this.columnSourceTable[i].property == 'caozuo' || '操作'==this.columnSourceTable[i].label) {
    }else {
      if (c == 0) {
        this.columnChangeTable.push({
          label: this.columnSourceTable[i].label,
          property: this.columnSourceTable[i].property,
          isShow: "0",
          width: this.columnSourceTable[i].width,
          fixed: this.columnSourceTable[i].fixed
        });
      }
    }
  }
  this.$refs['listTable'].store.states._columns = newColumns;
  this.$refs['listTable'].store.scheduleLayout(true);

}

