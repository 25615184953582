var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isButton
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleClick } },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _vm._e(),
      !_vm.isButton
        ? _c(
            "el-input",
            {
              attrs: {
                placeholder: "选择产品信息",
                value: _vm.content,
                readonly: "",
              },
              on: { focus: _vm.handleClick },
            },
            [
              _c("i", {
                staticClass: "el-icon-search",
                attrs: { slot: "suffix" },
                on: { click: _vm.handleClick },
                slot: "suffix",
              }),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择客户产品关系信息",
            visible: _vm.dialogVisible,
            width: "70%",
            "append-to-body": true,
            "destroy-on-close": "",
            center: "",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c(
            "el-row",
            { ref: "searchBox", staticClass: "search-box" },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "物料编码" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getPage.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.condition.materialCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.condition, "materialCode", $$v)
                      },
                      expression: "condition.materialCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "物料名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getPage.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.condition.materialName,
                      callback: function ($$v) {
                        _vm.$set(_vm.condition, "materialName", $$v)
                      },
                      expression: "condition.materialName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.getPage },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "selectListTable",
              attrs: {
                data: _vm.tableData,
                height: _vm.tableHeight,
                border: "",
              },
              on: { select: _vm.onSelect, "select-all": _vm.onSelectAll },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物料编码",
                  align: "left",
                  prop: "materialCode",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物料名称",
                  align: "left",
                  prop: "materialName",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "基准价",
                  align: "left",
                  prop: "minimumPrice",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "计量单位名称",
                  align: "left",
                  prop: "primaryUnit",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "主单位/辅单位",
                  align: "left",
                  prop: "measureUnitName",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户产品名称",
                  align: "left",
                  prop: "clientMaterialName",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户产品编码",
                  align: "left",
                  prop: "clientMaterialCode",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物料分类",
                  align: "left",
                  prop: "materialClass",
                  "show-overflow-tooltip": "",
                  width: "170",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "footer-box" },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c("pagination", {
                    attrs: {
                      "pager-count": 7,
                      total: _vm.pagination.total,
                      page: _vm.pagination.current,
                      limit: _vm.pagination.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.pagination, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.pagination, "size", $event)
                      },
                      pagination: _vm.getPage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }