<template>

  <div>

    <el-dialog
      title="重置密码"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false" center
      :close-on-press-escape="false"
      :close-on-click-modal="false">
      <span style="color: red">您的密码已过期，请重置密码</span>
      <reset-password :user="user" :callback="callback"/>
    </el-dialog>

  </div>


</template>

<script>
import ResetPassword from "@/views/fwpt/profile/components/ResetPassword";
import {mapGetters} from "vuex";

export default {
  name: 'VerifyResetPassword',
  components: {ResetPassword},
  data() {
    return {
      dialogVisible: false,

      user: {}
    };
  },
  methods: {
    callback() {
      this.dialogVisible = false;
      this.$store.commit("SET_IS_NEED_CHANGE_PASS", false);
    }
  },
  mounted() {
    this.dialogVisible = this.isNeedChangePass;
  },
  computed: {
    ...mapGetters([
      'isNeedChangePass',
    ]),
  },

}
</script>

<style scoped>

</style>
