import request from "@/utils/request";

export function getByParent(parentId) {
  return request({
    url: '/basic/area/getByParent',
    method: 'get',
    params: {parentId: parentId}
  });
}

export function getTreePath(areaId) {
  return request({
    url: '/basic/area/getTreePath',
    method: 'get',
    params: {id: areaId}
  });
}

export function getAllNode() {
  return request({
    url: '/basic/area/getAllNode',
    method: 'get',
  });
}

export function getByPath(areaId) {
  return request({
    url: '/basic/area/getByPath',
    method: 'get',
    params: {areaId: areaId}
  });
}
