<template>
  <div style="height: calc(100vh - 110px); overflow-y: auto;" v-loading="loading">
  <div style="height: 50px;">
    <div class="fixed">
      <div style="display: flex;align-items: center;width: 83%">
        <el-page-header  @back="handleClose" :content="form.id?'编辑客诉单据':'新增客诉单据'"/>
        <div style="margin-left: auto;">
          <el-button type="primary" @click="submitForm('form')">保存</el-button>
          <el-button @click="handleClose">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
    <div style="margin-top: 10px;">
      <el-divider content-position="left">基础信息</el-divider>
      <el-form ref="form" style="display: flex;flex-wrap: wrap;" :model="form" size="small" :rules="rules"  label-width="120px" >
        <el-row  class="search-boxx gz-form-row">
          <el-col :span="6">
            <el-form-item label="客诉编号" >
              <el-input v-model="form.customerComplaintNo" disabled placeholder="系统自动生成" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户名称"  prop="customerName">
              <el-input placeholder="请选择客户名称" @focus="clientClick" :value="form.customerName" readonly>
                <i slot="suffix" class="el-icon-search" @click="clientClick" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目名称"  prop="projectName">
              <select-project-record :confirm="projectSelect" placeholder="请输入项目名称" :is-button="false" :content="form.projectName"></select-project-record>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="投诉人/电话"  prop="complainantPhone">
              <el-input v-model="form.complainantPhone"  placeholder="客户自动带出" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目地址" prop="projectAddress">
              <el-cascader
                ref="cascaderArr"  placeholder="请选择所在地区"
                v-model="form.areaIdList"
                :options="areaOption"
                @change="handleChange($event)"
                clearable/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="详细地址"  prop="detailedAddress">
              <el-input v-model="form.detailedAddress"   placeholder="请输入详细地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售负责人"  prop="salesmanName">
              <select-salesman-dialog :confirm="reportPersonSelect" :placeholder="'请选择销售负责人'" :is-button="false" :content="form.salesDirectorName"></select-salesman-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属部门">
              <el-input v-model="form.groupName"  disabled placeholder="自动带出" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发货通知单号">
              <select-deliver-dialog :confirm="deliverSelect" :placeholder="'请选择发货通知单号'" :is-button="false" :content="form.deliverSn"></select-deliver-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="处理完成时间" >
              <el-date-picker  v-model="form.completionTime"  type="date" placeholder="请选择签署日期"
                               value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="处理内容">
              <el-input v-model="form.dealWithContent"   placeholder="请输入贷款金额" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否需要派遣技术服务人员现场勘查解决" label-width="150px" prop="isField">
              <el-radio v-model="form.isField" :label="true">需要</el-radio>
              <el-radio v-model="form.isField" :label="false">不需要</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="处理时间">
              <el-date-picker  v-model="form.processingTime"  type="date" placeholder="请选择签署日期"
                               value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="技术服务人员"  >
              <select-salesman-dialog :confirm="technicalPersonnelSelect" :placeholder="'请选择技术服务人员'" :is-button="false" :content="form.technicalPersonnelName"></select-salesman-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="定义结论" prop="defineConclusion">
              <el-radio v-model="form.defineConclusion" :label="true">产品质量问题</el-radio>
              <el-radio v-model="form.defineConclusion" :label="false">非产品质量问题</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="投诉问题描述" prop="salesProfession">
              <el-input v-model="form.complaints"  placeholder="请输入投诉问题描述" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="回访时间" >
              <el-date-picker  v-model="form.visitTime"  type="date" placeholder="请选择回访时间"
                               value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原因分析、处理措施">
              <el-input v-model="form.treatmentMeasures"  placeholder="请输入原因分析、处理措施" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="满意度">
              <el-radio v-model="form.satisfaction" :label="true">满意</el-radio>
              <el-radio v-model="form.satisfaction" :label="false">不满意</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="问题进度">
              <el-input v-model="form.problemProgress"  placeholder="请输入问题进度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人" >
              <el-input  v-model="form.createUser"  disabled placeholder="自动生成" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间" >
              <el-input v-model="form.createTime" disabled placeholder="自动生成" clearable />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item  label="是否日常现场"  prop="isVirtualContract">
              <el-select v-model="form.dailyLive"clearable placeholder="是否日常现场">
                <el-option clearable
                           v-for="item in isVirtualContractEnum"
                           :key="item.code"
                           :label="item.desc"
                           :value="parseInt(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="单据状态"  >
              <el-select v-model="form.status" disabled clearable placeholder="请选择单据状态">
                <el-option clearable
                           v-for="item in statusEnum"
                           :key="item.code"
                           :label="item.desc"
                           :value="parseInt(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="备注" >
              <el-input v-model="form.remark" type="textarea"  placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-card class="box-card" >
          <div slot="header" class="clearfix" style="display: flex;align-items: center">
            <span>产品信息</span>
            <div class="line">
              <select-product-dialog style="float: left;margin-left: 20px" :multiple="true" :confirm="productSelect" text="新增" :is-button="true" ></select-product-dialog>
              <el-button style="float: left;margin-left: 10px" type="danger" @click="deleteBatch">批量删除</el-button>
            </div>
          </div>
          <el-row>
            <el-col :span="24">
              <el-table
                :data="form.complaintLineModelList"
                height="250"
                border
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column  prop="name"  label="产品名称"  width="320">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.materialName"  placeholder="自动生成" clearable disabled />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="specification" label="规格" width="180"/>
                <el-table-column prop="model" label="型号" width="210"/>
                <el-table-column prop="color" label="颜色" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.color"  placeholder="请输入颜色" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="productionBatchNumber" label="生产批号" width="210">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.productionBatchNumber"  placeholder="请输入生产批号" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="deliveryNumber" label="发货数量" width="180"/>
                <el-table-column label="操作" fixed="right" width="60">
                  <template slot-scope="scope">
                    <el-button type="text" class="red" @click="delProduct(scope.$index,scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>

      </el-form>
    </div>
    <!--客户信息-->
    <customer-dialog ref="customer" @select="onClient"></customer-dialog>
  </div>

</template>

<script>
import { get, saveOrUpdate} from '@/api/crm/aftersale/crmComplaint.js'
import { deliverLineList } from '@/api/crm/deliver/notice'
import selectProductDialog from "@/views/crm/components/base/selectProductDialog";
import selectProjectRecord from "@/views/crm/components/commercial/selectProjectRecord";
import selectSalesmanDialog from "@/views/crm/components/client/selectSalesmanDialog";
import selectDeliverDialog from "@/views/crm/components/deliver/selectDeliverDialog";
import customerDialog from "@/views/crm/components/customerDialog/index";
  import format from '@/utils/formatter'
  import { pcData } from '@/utils/area'
import {v4} from "uuid";
  export default {
    components: {selectProjectRecord,customerDialog,selectSalesmanDialog,selectProductDialog,selectDeliverDialog},
    name: 'complaintEdit',
    props: {
      show: {
        type: Boolean,
        default() {
          return false
        }
      },
      editId: Number,
    },

    data() {
      return {
        loading: false,
        format:format,
        form: {
          id: null,
          customerComplaintNo: '',//客诉编号
          customerId:'',//客户Id
          projectName:'',//项目名称，
          projectId:'',//项目Id
          complainantPhone:'',//投诉人电话
          projectAddress:'',//项目地址
          detailedAddress: '',//详细地址
          salesDirectorId:'',//销售负责人Id
          salesDirectorName:'',//销售负责人名称
          department:'',//销售部门
          deliverId:'',//发货通知单Id
          deliverSn:'',//发货通知单单号
          customerName:'',//客户名称
          completionTime: '',//处理完成时间
          dealWithContent: '',//处理内容
          isField: '',//是否需要派遣技术服务人员现场勘查解决
          processingTime: '',//处理时间
          technicalPersonnelId: '',//技术服务人员Id
          technicalPersonnelName: '',//技术服务人员名称
          defineConclusion: '',//定义结论
          complaints: '',//投诉问题描述
          visitTime:'',//回访时间
          treatmentMeasures: '',//处理措施
          satisfaction: '',//满意度
          problemProgress:'',//问题进度
          dailyLive:'',//日常现场
          type:20,//合同管理类型
          remark: '',
          createTime: '',
          createUser:'',
          status:1,//单据状态
          areaIdList:[],
          complaintLineModelList:[],
        },
        // 地址下拉
        areaOption: null,
        multipleSelection:[],
        isVirtualContractEnum: [{
          code:1,
          desc:"是"
        },{
          code:0,
          desc:"否"
        }], //
        rules: {
          defineConclusion: [{ required: true, message: '定义结论不能为空', trigger: 'blur' }],
          complainantPhone: [{ required: true, message: '投诉人/电话不能为空', trigger: 'input' }],
          projectName: [{ required: true, message: '项目名称不能为空', trigger: 'input' }],
          projectAddress: [{ required: true, message: '项目地址不能为空', trigger: 'blur' }],
          detailedAddress: [{ required: true, message: '详细地址不能为空', trigger: 'input' }],
          isField: [{ required: true, message: '是否需要派遣技术服务人员现场勘查解决不能为空', trigger: 'blur' }],
          dailyLive: [{ required: true, message: '是否日常现场不能为空', trigger: 'blur' }]

        },
        contractTypeEnum:format.getEnumItems('ContractTypeEnum'),
        intermediaryCostTypeEnum:format.getEnumItems('IntermediaryCostTypeEnum'),
        statusEnum:format.getEnumItems('StatusEnum'),
      }
    },
    watch: {
      editId(editId) {

      },
      show(show) {
        console.log("this.offerId",this.offerId)
        if (!show) {
          return
        }

      },
    },
    created() {
      this.areaOption = pcData
      if (this.editId == null && this.$route.query.id) {
        this.editId = this.$route.query.id;
      }
      this.info();
    },
    methods: {
      info(){
        if (this.editId == null)return ;
        this.loading = true
        get(this.editId).then((response) => {
          const { data } = response
          this.form = data
          console.log(data.address)
          this.form.areaIdList = !data.projectAddress ? [] : data.projectAddress.split(",")
          console.log(this.form.areaIdList )
        }).finally(() => {
          this.loading = false
        })
      },
      handleClose() {
        this.form= {
          id: null,
          customerComplaintNo: '',//客诉编号
          customerId:'',//客户Id
          projectName:'',//项目名称，
          projectId:'',//项目Id
          complainantPhone:'',//投诉人电话
          projectAddress:'',//项目地址
          detailedAddress: '',//详细地址
          salesDirectorId:'',//销售负责人Id
          salesDirectorName:'',//销售负责人名称
          department:'',//销售部门
          deliverId:'',//发货通知单Id
          deliverSn:'',//发货通知单单号
          customerName:'',//客户名称
          completionTime: '',//处理完成时间
          dealWithContent: '',//处理内容
          isField: '',//是否需要派遣技术服务人员现场勘查解决
          processingTime: '',//处理时间
          technicalPersonnelId: '',//技术服务人员Id
          technicalPersonnelName: '',//技术服务人员名称
          defineConclusion: '',//定义结论
          complaints: '',//投诉问题描述
          visitTime:'',//回访时间
          treatmentMeasures: '',//处理措施
          satisfaction: '',//满意度
          problemProgress:'',//问题进度
          dailyLive:'',//日常现场
          type:20,//合同管理类型
          remark: '',
          createTime: '',
          createUser:'',
          status:1,//单据状态
          areaIdList:[],
          complaintLineModelList:[],
        };
        // this.$route.params.id = null
        const view = this.$route;
        this.$store
          .dispatch("tagsView/delView", view)
          .then(({ visitedViews }) => {
            if (this.isActive(view)) {
              this.toLastView(visitedViews, view);
            }
          });
        this.$emit('close')
      },
      isActive(route) {
        return route.path === this.$route.path;
      },

      deleteBatch() {
        if (this.multipleSelection.length == 0) {
          this.$message.warning("请至少选择一条数据进行删除！");
        } else {
          let arr1Ids = this.multipleSelection.map((item) => item.uuid);
          // 过滤arr2中不包含arr1相同的id数组。
          const result = this.form.complaintLineModelList.filter(
            (item) => !arr1Ids.includes(item.uuid)
          );
          this.form.complaintLineModelList = result;
        }
      },
      toLastView(visitedViews, view) {
        const latestView = visitedViews.slice(-1)[0];
        if (latestView) {
          this.$router.push(latestView.fullPath);
        } else {
          // now the default is to redirect to the home page if there is no tags-view,
          // you can adjust it according to your needs.
          if (view.name === "Dashboard") {
            // to reload home page
            this.$router.replace({ path: "/redirect" + view.fullPath });
          } else {
            this.$router.push("/");
          }
        }
      },
      //选择销售人员
      reportPersonSelect(data) {
        console.log(data);
        this.$set(this.form, "salesDirectorName", data.salesmanName);
        this.$set(this.form, "salesDirectorId", data.id);
        this.$set(this.form, "department", data.department);
      },
      //选择技术人员
      technicalPersonnelSelect(data){
        this.$set(this.form, "technicalPersonnelName", data.salesmanName);
        this.$set(this.form, "technicalPersonnelId", data.id);
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      submitForm(formName) {
        if (this.dailyLive != 1){
          this.$message.error('请选择产品信息')
          return
        }
        this.$refs[formName].validate((valid) => {
          if (!valid) {
            return false
          }

          saveOrUpdate(this.form).then(() => {
            this.handleClose()
            this.$message.success('操作成功')
          })
        })
      },
      //根据发货通知单Id获取明细
      deliverLineList(id){
        deliverLineList(id).then((response) => {
          const {data} = response;
          if (Array.isArray(data)){
            data.forEach(e=>{
              let item = {
                materialName:e.materialName,//产品名称
                specification:e.specification,//规格
                model:e.model,
                productId:e.materialId,
                color:'',
                productionBatchNumber:'',//生产批次
                deliveryNumber:null,//发货数量
                uuid: v4(),
              }
              this.form.complaintLineModelList.push(item);
            })
          }
          console.log(response)
        })
      },
      popoverHide(value) {
        this.item.parent = value
      },
      clientClick(){
        this.$refs.customer.open();
      },
      /**
       * 删除明细
       */
      //删除产品信息
      delProduct(index,row){
        this.$confirm('请确认是否删除此数据', '提示', { type: 'warning' }).then(() => {
          this.form.complaintLineModelList.splice(index,1)
        })
      },
      //选择业务员信息
      productSelect(data){
        console.log(data)
        if (data.length > 0){
          data.forEach(e=>{
            let item = {
              materialName:e.materialName,//产品名称
              specification:e.specification,//规格
              model:e.model,
              productId:e.productId,
              color:'',
              productionBatchNumber:'',//生产批次
              deliveryNumber:null,//发货数量
              uuid: v4(),
            }
            this.form.complaintLineModelList.push(item);
          })

        }

      },
      agreementSelect(data){
        this.$set(this.form, "agreementId", data.id);
        this.$set(this.form, "agreementName", data.agreementName);
      },
      //项目档案
      projectSelect(data){
        this.$set(this.form, "projectId", data.id);
        this.$set(this.form, "projectName", data.projectName);
      },
      deliverSelect(data){
        this.$set(this.form, "deliverId", data.id);
        this.$set(this.form, "deliverSn", data.sn);
        this.deliverLineList(data.id)
      },
      // 下拉地址绑定事件
      handleChange(array) {
        this.form.projectAddress = array.join(',');
      },
      //客户信息
      onClient(data){
        console.log(data)
        this.$set(this.form, "customerId", data.id);
        this.$set(this.form, "customerName", data.customerName);
      }
    }
  }
</script>

<style scoped>
.search-boxx .el-col {
  margin-bottom: 18px;
}
.gz-form-row{
  display: flex;
  flex-wrap: wrap;
}
.fixed{
  position: fixed;width: 100%;
  background-color: #FFF;
  z-index: 10;
  border-bottom: 1px solid  #c3c3c3;
  height: 40px;
}
</style>
