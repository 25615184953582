import request from '@/utils/request'
export function getPage(condition, pagination) {
  return request({
    url: '/hc/flowpath/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}
export function getPage2(condition, pagination) {
  return request({
    url: '/hc/flowpath/listFlowpath',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}
//回款单查询流程单号
export function getPage3(condition, pagination) {
  return request({
    url: '/hc/flowpath/listReturnedFlowpath',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}


export function saveOrUpdate(data) {
  return request({
    url: '/hc/flowpath/saveOrUpdate',
    method: 'post',
    data
  })
}

export function get(id) {
  return request({
    url: '/hc/flowpath/get',
    method: 'get',
    params: { id }
  })
}


export function submit(id) {
  return request({
    url: '/hc/flowpath/submit',
    method: 'post',
    data: { id: id }
  })
}

export function deleteById(id) {
  return request({
    url: '/hc/flowpath/deleteById',
    method: 'post',
    data: { id: id }
  })
}

// export function deleteBatchByIds(ids) {
//   return request({
//     url: '/hc/contract/deleteBatchById',
//     method: 'post',
//     data: { ids: ids }
//   })
// }

// export function exportExcel(condition, pagination) {
//   return request({
//     url: '/hc/contract/exportExcel',
//     method: 'post',
//     data: { condition: condition, pagination: pagination },
//     responseType: 'blob'
//   })
// }

