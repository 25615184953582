import request from '@/utils/request'

export function getListPage(condition) {
  return request({
    url: '/system/vocabulary/listPage',
    method: 'post',
    data: { condition: condition }
  })
}

export function deleteById(id) {
  return request({
    url: '/system/vocabulary/deleteById',
    method: 'post',
    data: { id: id }
  })
}

export function saveOrUpdate(data) {
  return request({
    url: '/system/vocabulary/saveOrUpdate',
    method: 'post',
    data: data
  })
}

export function getVocabulary(code) {
  return request({
    url: '/system/vocabulary/getVocabulary',
    method: 'post',
    data: {code: code}
  })
}

