var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
    },
    [
      _c("div", { staticStyle: { height: "50px" } }, [
        _c("div", { staticClass: "fixed" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                width: "83%",
              },
            },
            [
              _c("el-page-header", {
                attrs: {
                  content: _vm.form.id ? "编辑客诉单据" : "新增客诉单据",
                },
                on: { back: _vm.handleClose },
              }),
              _c(
                "div",
                { staticStyle: { "margin-left": "auto" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("基础信息"),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { display: "flex", "flex-wrap": "wrap" },
              attrs: {
                model: _vm.form,
                size: "small",
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "search-boxx gz-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客诉编号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "系统自动生成",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.customerComplaintNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "customerComplaintNo", $$v)
                              },
                              expression: "form.customerComplaintNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称", prop: "customerName" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请选择客户名称",
                                value: _vm.form.customerName,
                                readonly: "",
                              },
                              on: { focus: _vm.clientClick },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-search",
                                attrs: { slot: "suffix" },
                                on: { click: _vm.clientClick },
                                slot: "suffix",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目名称", prop: "projectName" } },
                        [
                          _c("select-project-record", {
                            attrs: {
                              confirm: _vm.projectSelect,
                              placeholder: "请输入项目名称",
                              "is-button": false,
                              content: _vm.form.projectName,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "投诉人/电话",
                            prop: "complainantPhone",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "客户自动带出",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.complainantPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "complainantPhone", $$v)
                              },
                              expression: "form.complainantPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "项目地址", prop: "projectAddress" },
                        },
                        [
                          _c("el-cascader", {
                            ref: "cascaderArr",
                            attrs: {
                              placeholder: "请选择所在地区",
                              options: _vm.areaOption,
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChange($event)
                              },
                            },
                            model: {
                              value: _vm.form.areaIdList,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "areaIdList", $$v)
                              },
                              expression: "form.areaIdList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "详细地址", prop: "detailedAddress" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入详细地址",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.detailedAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "detailedAddress", $$v)
                              },
                              expression: "form.detailedAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "销售负责人", prop: "salesmanName" },
                        },
                        [
                          _c("select-salesman-dialog", {
                            attrs: {
                              confirm: _vm.reportPersonSelect,
                              placeholder: "请选择销售负责人",
                              "is-button": false,
                              content: _vm.form.salesDirectorName,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属部门" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "自动带出",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.groupName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupName", $$v)
                              },
                              expression: "form.groupName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货通知单号" } },
                        [
                          _c("select-deliver-dialog", {
                            attrs: {
                              confirm: _vm.deliverSelect,
                              placeholder: "请选择发货通知单号",
                              "is-button": false,
                              content: _vm.form.deliverSn,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "处理完成时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "请选择签署日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.completionTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "completionTime", $$v)
                              },
                              expression: "form.completionTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "处理内容" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入贷款金额",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.dealWithContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dealWithContent", $$v)
                              },
                              expression: "form.dealWithContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否需要派遣技术服务人员现场勘查解决",
                            "label-width": "150px",
                            prop: "isField",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value: _vm.form.isField,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isField", $$v)
                                },
                                expression: "form.isField",
                              },
                            },
                            [_vm._v("需要")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value: _vm.form.isField,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isField", $$v)
                                },
                                expression: "form.isField",
                              },
                            },
                            [_vm._v("不需要")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "处理时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "请选择签署日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.processingTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "processingTime", $$v)
                              },
                              expression: "form.processingTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "技术服务人员" } },
                        [
                          _c("select-salesman-dialog", {
                            attrs: {
                              confirm: _vm.technicalPersonnelSelect,
                              placeholder: "请选择技术服务人员",
                              "is-button": false,
                              content: _vm.form.technicalPersonnelName,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "定义结论",
                            prop: "defineConclusion",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value: _vm.form.defineConclusion,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "defineConclusion", $$v)
                                },
                                expression: "form.defineConclusion",
                              },
                            },
                            [_vm._v("产品质量问题")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value: _vm.form.defineConclusion,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "defineConclusion", $$v)
                                },
                                expression: "form.defineConclusion",
                              },
                            },
                            [_vm._v("非产品质量问题")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "投诉问题描述",
                            prop: "salesProfession",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入投诉问题描述",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.complaints,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "complaints", $$v)
                              },
                              expression: "form.complaints",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "回访时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "请选择回访时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.visitTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "visitTime", $$v)
                              },
                              expression: "form.visitTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "原因分析、处理措施" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入原因分析、处理措施",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.treatmentMeasures,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "treatmentMeasures", $$v)
                              },
                              expression: "form.treatmentMeasures",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "满意度" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value: _vm.form.satisfaction,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "satisfaction", $$v)
                                },
                                expression: "form.satisfaction",
                              },
                            },
                            [_vm._v("满意")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value: _vm.form.satisfaction,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "satisfaction", $$v)
                                },
                                expression: "form.satisfaction",
                              },
                            },
                            [_vm._v("不满意")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "问题进度" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入问题进度",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.problemProgress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "problemProgress", $$v)
                              },
                              expression: "form.problemProgress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建人" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "自动生成",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.createUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "createUser", $$v)
                              },
                              expression: "form.createUser",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "自动生成",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.createTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "createTime", $$v)
                              },
                              expression: "form.createTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否日常现场",
                            prop: "isVirtualContract",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "是否日常现场",
                              },
                              model: {
                                value: _vm.form.dailyLive,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dailyLive", $$v)
                                },
                                expression: "form.dailyLive",
                              },
                            },
                            _vm._l(_vm.isVirtualContractEnum, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  clearable: "",
                                  label: item.desc,
                                  value: parseInt(item.code),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: "",
                                clearable: "",
                                placeholder: "请选择单据状态",
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            _vm._l(_vm.statusEnum, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  clearable: "",
                                  label: item.desc,
                                  value: parseInt(item.code),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入备注",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      staticStyle: { display: "flex", "align-items": "center" },
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("产品信息")]),
                      _c(
                        "div",
                        { staticClass: "line" },
                        [
                          _c("select-product-dialog", {
                            staticStyle: {
                              float: "left",
                              "margin-left": "20px",
                            },
                            attrs: {
                              multiple: true,
                              confirm: _vm.productSelect,
                              text: "新增",
                              "is-button": true,
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "left",
                                "margin-left": "10px",
                              },
                              attrs: { type: "danger" },
                              on: { click: _vm.deleteBatch },
                            },
                            [_vm._v("批量删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.form.complaintLineModelList,
                                height: "250",
                                border: "",
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "产品名称",
                                  width: "320",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "自动生成",
                                                clearable: "",
                                                disabled: "",
                                              },
                                              model: {
                                                value: scope.row.materialName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "materialName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.materialName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "specification",
                                  label: "规格",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "model",
                                  label: "型号",
                                  width: "210",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "color",
                                  label: "颜色",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入颜色",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.color,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "color",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.color",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productionBatchNumber",
                                  label: "生产批号",
                                  width: "210",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入生产批号",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  scope.row
                                                    .productionBatchNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "productionBatchNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.productionBatchNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "deliveryNumber",
                                  label: "发货数量",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  fixed: "right",
                                  width: "60",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "red",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delProduct(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("customer-dialog", { ref: "customer", on: { select: _vm.onClient } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }