<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key"/>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  //min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px #f2f2f2;
  min-width: 1000px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 10px;

  flex: 1 auto;

}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}

.search-box .el-col {
  margin-right: 8px;
  margin-bottom: 10px;
}

.footer-box .el-col {
  margin-top: 10px;
}

.el-icon-search:hover {
  cursor: pointer
}

.el-select {
  width: 100%;
}

.el-cascader-menu {
  max-height: 300px;
  min-height: 205px;
}

.el-cascader {
  width: 100%;
}

.el-autocomplete {
  width: 100%;
}

.el-date-editor {
  width: 100% !important;
}

.el-divider__text{
  font-weight: bold;
}

/* 表格星号样式 */
table th .must:before {
  content: "*";
  color: #ff4949;
  margin-right: 4px;
}

</style>
