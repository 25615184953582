import request from '@/utils/request'

/**
 * 获取流程图片
 * @param flowId
 * @returns {AxiosPromise}
 */
export function getFlowImage(flowId) {
  return request({
    url: '/flow/getFlowImage',
    method: 'get',
    params: { flowId: flowId },
    responseType: 'blob'
  })
}

/**
 * 获取几点信息
 * @param flowId
 * @returns {AxiosPromise}
 */
export function getHistoryActivityList(flowId) {
  return request({
    url: '/flow/getHistoryActivityList',
    method: 'post',
    data: { flowId: flowId }
  })
}

/**
 * 提交审批
 * @param flowId
 * @param opinion
 * @param comment
 * @returns {AxiosPromise}
 */
export function complete(flowId, opinion, comment) {
  return request({
    url: '/flow/complete',
    method: 'post',
    data: { flowId: flowId, opinion: opinion, comment: comment }
  })
}

export function getCurrentNodeInfo(flowId) {
  return request({
    url: '/flow/getCurrentNodeInfo',
    method: 'post',
    data: { flowId: flowId }
  })
}

/**
 * 转办
 * @param flowId
 * @param username
 */
export function transition(flowId, username) {
  return request({
    url: '/flow/transition',
    method: 'post',
    data: {flowId: flowId, username: username}
  })
}

export function stop(flowId,reason) {
  return request({
    url: '/flow/stop',
    method: 'post',
    data: {flowId: flowId, reason: reason}
  })
}

export function rejectTo(taskId, flowId,comment) {
  return request({
    url: '/flow/rejectTo',
    method: 'post',
    data: {taskId: taskId, flowId: flowId, comment: comment}
  })
}
