// 不取后台常量
const timeRange = [{
  value: '00:00-08:00',
  propEnd: 10,
  label: '00:00-08:00'
}, {
  value: '08:00-10:00',
  propEnd: 10,
  label: '08:00-10:00'
}, {
  value: '10:00-12:00',
  propEnd: 12,
  label: '10:00-12:00'
}, {
  value: '12:00-14:00',
  propEnd: 14,
  label: '12:00-14:00'
}, {
  value: '14:00-16:00',
  propEnd: 16,
  label: '14:00-16:00'
}, {
  value: '16:00-18:00',
  propEnd: 18,
  label: '16:00-18:00'
}, {
  value: '18:00-20:00',
  propEnd: 20,
  label: '18:00-20:00'
}, {
  value: '20:00-22:00',
  propEnd: 22,
  label: '20:00-22:00'
}, {
  value: '22:00-23:59',
  propEnd: 24,
  label: '22:00-23:59'
}]

const shortcuts = [{
  text: '最近一周',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    picker.$emit('pick', [start, end])
  }
}, {
  text: '最近一个月',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    picker.$emit('pick', [start, end])
  }
}, {
  text: '最近三个月',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
    picker.$emit('pick', [start, end])
  }
}]

// 本地缓存key
const localStorageKey = [
  "ALL_ENUM",// 枚举类
];

export default {
  timeRange,
  shortcuts,
  localStorageKey
}
