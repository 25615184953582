import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
var sessionStorageKey = "Ebo-"+TokenKey;

export function getToken() {

  var token = Cookies.get(TokenKey)
  if(token!=null){
    return token;
  }
  token = window.localStorage.getItem(TokenKey);
  return token;
}

export function setToken(token) {
  window.localStorage.setItem(TokenKey, token);
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  window.localStorage.removeItem(TokenKey);
  return Cookies.remove(TokenKey)
}
