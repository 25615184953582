<template>
  <div>
    <!--流程审批-->
    <el-row>
      <el-col>
        <img :src="imgUrl" style="width:100%">
      </el-col>
    </el-row>
    <el-row v-if="isAssignee">
      <el-col>
        <el-input
          v-model="comment"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8}"
          placeholder="请输入审批意见"
        />
      </el-col>
      <el-col style="margin-bottom: 10px; margin-top: 5px;">
        <el-button @click="refresh">刷新</el-button>
        <el-button type="success" :loading="isLoading" @click="handleComplete">同意</el-button>
        <el-button type="success" :loading="isLoading" @click="handleTransition">转办</el-button>
        <el-button v-if="hasReject" :loading="isLoading" type="danger" @click="handleReject">驳回</el-button>
        <el-button v-if="hasReject" :loading="isLoading" type="danger" @click="handleRejectTo">驳回至</el-button>
        <el-button type="danger" :loading="isLoading" @click="stop">中断</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="historyActivityList" border>
        <el-table-column prop="name" label="节点名称" />
        <el-table-column prop="startTime" label="开始时间" />
        <el-table-column prop="endTime" label="结束时间" />
        <el-table-column prop="assignee" label="审批人" />
        <el-table-column prop="type" label="处理类型" />
        <el-table-column prop="comment" label="审批意见" show-overflow-tooltip />
      </el-table>
    </el-row>

    <table-dialog
      ref="tableDialog"
      title="转办至"
      url="/system/admin/page"
      :confirm="confirmAdmin"
      style="display: none"
    >
      <template slot="condition" slot-scope="{condition}">
        <el-col :span="3">
          <el-input v-model="condition.name" clearable placeholder="请输入姓名" />
        </el-col>
        <el-col :span="3">
          <el-input v-model="condition.username" clearable placeholder="请输入用户名" />
        </el-col>
        <el-col :span="3">
          <el-input v-model="condition.mobile" clearable placeholder="联系电话" />
        </el-col>
      </template>
      <el-table-column type="selection" width="40" />
      <el-table-column prop="username" label="用户名" show-overflow-tooltip />
      <el-table-column prop="name" label="姓名" show-overflow-tooltip />
      <el-table-column prop="email" label="邮箱" show-overflow-tooltip />
      <el-table-column prop="mobile" label="联系电话" show-overflow-tooltip />
      <el-table-column label="创建时间" align="center" prop="createTime" />
    </table-dialog>
<!--    驳回到某个节点的弹窗-->
    <table-dialog
      ref="rejectToDialog"
      title="驳回至"
      url="/flow/getHiTaskList"
      :confirm="confirmRejectTo"
      style="display: none"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column prop="name" label="节点名称" show-overflow-tooltip />
      <el-table-column prop="assignee" label="处理人" show-overflow-tooltip />
      <el-table-column label="处理时间" align="center" prop="createTime" />
    </table-dialog>

  </div>
</template>

<script>
import {
  complete,
  getCurrentNodeInfo,
  getFlowImage,
  getHistoryActivityList,
  transition,
  stop,
  rejectTo
} from '@/api/flow/flow'
import TableDialog from '@/components/TableDialog/MultipleChoiceTableDialog.vue'

export default {
  name: 'Flow',
  components: { TableDialog },
  props: {
    flowId: String,
    customEvent: Boolean // 自定义提交事件
  },
  data() {
    return {
      isLoading: false,
      imgUrl: null,
      comment: '', // 审批意见
      historyActivityList: [], // 历史审批列表
      validatorForm: [],
      isAssignee: false, // 是否当前节点受理人
      hasReject: false, // 是否有驳回操作
      nodeName: '' // 当前审批节点
    }
  },
  watch: {
    flowId(val) {
      this.refresh()
    }
  },
  methods: {
    getFlowImage() {
      getFlowImage(this.flowId).then((response) => {
        const resData = response.data
        const fileReader = new FileReader()
        fileReader.readAsText(resData)
        fileReader.onloadend = () => {
          if (resData.type === 'application/json') {
            const data = JSON.parse(fileReader.result)
            this.$message({
              message: '流程图不存在',
              type: 'error',
              duration: 5 * 1000
            })
          } else {
            this.imgUrl = window.URL.createObjectURL(response.data) // 这里调用window的URL方法
          }
        }
      })
    },
    async onComplete(cb) {
      try {
        this.isLoading = true
        await complete(this.flowId, true, this.comment)
        this.$message.success('操作成功')
        cb && cb()
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
      this.refresh()
    },
    async handleComplete() {
      if (this.customEvent) {
        this.$emit('complete')
      } else {
        this.isLoading = true
        complete(this.flowId, true, this.comment).then((response) => {
          this.$message.success('操作成功')
          this.refresh()
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    handleTransition() {
      this.$refs.tableDialog.handleClick()
    },
    handleReject() {
      this.isLoading = true
      complete(this.flowId, 'false', this.comment).then((response) => {
        this.$message.success('操作成功')
        this.refresh()
      }).finally(() => {
        this.isLoading = false
      })
    },
    handleRejectTo() {
      this.$refs.rejectToDialog.condition.flowId = this.flowId
      this.$refs.rejectToDialog.handleClick()
    },
    // 驳回到某一个节点
    confirmRejectTo(row) {
      this.isLoading = true
      rejectTo(row.id, this.flowId, this.comment).then(() => {
        this.$message.success('操作成功')
        this.refresh()
      }).finally(() => {
        this.isLoading = false
      })
    },
    getHistoryActivityList() {
      getHistoryActivityList(this.flowId).then((response) => {
        this.historyActivityList = response.data
      })
    },
    getCurrentNodeInfo() {
      getCurrentNodeInfo(this.flowId).then(response => {
        // 是否当前节点受让人
        this.isAssignee = response.data.isAssignee
        this.hasReject = response.data.hasReject
        this.nodeName = response.data.nodeName
        this.$emit('flowLoad', this.$data)
      })
    },
    refresh() {
      if (!this.flowId || this.flowId == '0') {
        this.imgUrl = ''
        this.historyActivityList = []
        this.isAssignee = false
        return
      }
      this.getFlowImage()
      this.getHistoryActivityList()
      this.getCurrentNodeInfo()
    },
    confirmAdmin(row) {
      transition(this.flowId, row.username).then(() => {
        this.$message.success('操作成功')
        this.refresh()
      })
    },
    /**
     * 中断
     */
    stop() {
      this.isLoading = true
      stop(this.flowId, this.comment).then(() => {
        this.$message.success('操作成功')
        this.refresh()
      }).finally(() => {
        this.isLoading = false
      })
    }

  }
}
</script>

<style scoped>

</style>
