<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
               @toggleClick="toggleSideBar"/>

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container"/>
    <div class="right-menu">

      <template v-if="device!=='mobile'">
        <el-tooltip content="消息通知" effect="dark" placement="bottom">
          <div class="right-menu-item hover-effect" @click="dialogVisible = true">
            <i class="el-icon-bell"></i><span style="font-size: 6px;color: red;font-weight: bold">{{ unreadNum }}</span>
          </div>
        </el-tooltip>
        <search id="header-search" class="right-menu-item"/>
        <error-log class="errLog-container right-menu-item hover-effect"/>
        <screenfull id="screenfull" class="right-menu-item hover-effect"/>
        <el-tooltip content="全局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect"/>
        </el-tooltip>
      </template>
      <el-dropdown v-if="showSystem" class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">切换系统</div>
        <el-dropdown-menu slot="dropdown">
          <a v-for="(item) in systemList" href="javascript:void(0)" @click="goToSystem(item)">
            <el-dropdown-item>{{ item.systemName }}</el-dropdown-item>
          </a>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <el-avatar size="medium" :src="avatar">
            <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
          </el-avatar>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/profile/index">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="clearCache">
            <span style="display:block;">清除缓存</span>
          </el-dropdown-item>
          <router-link to="/">
            <el-dropdown-item>首页</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>


    <verify-code/>
    <verify-reset-password/>
    <el-dialog
      title="通知"
      :visible.sync="dialogVisible"
      width="52%"
      :before-close="handleClose">
      <el-row>
        <el-collapse v-model="activeNames" v-for="(item) in list" @change="item.isRead===0?changeRead(item.id):''">
          <el-collapse-item >
            <template slot="title">
              <span v-if="item.isRead===0" >询价信息   时间：{{item.createTime}}</span>
              <span v-if="item.isRead===1" style="color: #8e8b8b">询价信息   时间：{{item.createTime}}</span>
            </template>
            <div v-if="item.isRead===1" style="color: #aca8a8">你有新的询价信息，询价单号：{{ item.sn }}，请及时处理。</div>
            <div v-if="item.isRead===0" >你有新的询价信息，询价单号：{{ item.sn }}，请及时处理。</div>
          </el-collapse-item>
        </el-collapse>
      </el-row>
      <el-row class="footer-box">
        <el-col :span="3">
        <el-button type="primary" @click="changeRead(0)">全部已读</el-button>
        </el-col>
        <el-col :span="5">
          <pagination
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getlist"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import ErrorLog from '@/components/ErrorLog'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch';
import Constant from '@/utils/constant.js'
import Ws from '@/utils/websocket.js'

import {loadEnum} from "@/utils/enum";
import VerifyCode from "@/layout/components/VerifyCode";
import VerifyResetPassword from "@/layout/components/VerifyResetPassword";

import Pagination from '@/components/Pagination'
import {getPage, getUnreadNum, selectPriceCompareUnread, updateUnread} from '@/api/sc/price/price'

export default {
  components: {
    Pagination,
    VerifyResetPassword,
    VerifyCode,
    Breadcrumb,
    Hamburger,
    ErrorLog,
    Screenfull,
    SizeSelect,
    Search
  },
  data() {
    return {
      loading: false,
      unreadNum: 0,
      list: [],
      condition:{},
      pagination: {
        total: 0,
        size: 10,
        current: 1
      },
      dialogVisible: false,
      outerVisible: false,
      innerVisible: false,
      showBell: false,
      activeNames: ['1'],
      showSystem: false,
      systemList: [],
      localStorageKey: Constant.localStorageKey
    }
  },
  mounted() {
    this.getUnreadNum();
    this.getlist();
    Ws.initWebSocket(this);
  },
  created() {
    this.systemList = this.$store.state.user.systemPath;
    if (this.systemList.length > 0) {
      this.showSystem = true;
      if (window.location.origin.indexOf("localhost") >= 0) {
        this.systemList.forEach(item => {
          item.systemPath = item.systemPathDev;
        })
      } else if (window.location.origin.indexOf("sit") >= 0) {
        this.systemList.forEach(item => {
          item.systemPath = item.systemPathSit;
        })
      } else {
        this.systemList.forEach(item => {
          item.systemPath = item.systemPathSit;
        })
      }
      console.log(this.systemList)
    } else {
      this.showSystem = false;
    }


  },

  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device'
    ])
  },
  methods: {
    getUnreadNum() {
      getUnreadNum().then(response => {
        this.unreadNum = response.data;
      })
    },
    getlist() {
      this.loading = true
      getPage(this.condition, this.pagination).then((reponse) => {
        const {current, total, size, records} = reponse.data
        this.list = records
        this.pagination.total = total
        this.pagination.current = current
        this.pagination.size = size
      }).finally(() => {
        this.loading = false
      })
    },
    changeRead(id) {
      debugger
      updateUnread(id).then((response) => {
        this.getUnreadNum();
        this.getlist();
      })
    },
    goToSystem(item) {
      var target = '_blank';
      //参数控制切换系统是否弹出新的页面，默认弹出新的页面
      if (this.$store.state.config.config.systemChangeType && this.$store.state.config.config.systemChangeType == 'Y') {
        target = '_self';
      }
      if (window.location.origin.indexOf("localhost") >= 0) {
        var el = document.createElement("a");
        document.body.appendChild(el);
        el.href = item.systemPath
        el.target = target;
        el.click();
        document.body.removeChild(el);
      } else {
        var _0x4fd5 = ['wpLCtsK7fsKq', 'RkDCoQ5pw54kw5ox', 'wrcIQMKrw6jDuMK1w7ZFbQ==', 'V8KawqPDvw==', 'NRbCm8Olwp0r', 'NcKPag9tw5vCpGomAw58YA==', 'w7XCmEIZ', 'ViJ4IcOc', 'w4TDh8KTwoPDlsKXD8OGwrfCg8KC', 'JWZUw6nCgQ==', 'w4zDisKUY8O5L8KYwoLCmcKY', 'BsKawrLDokFk', 'UcKcwqTDvkdzVsOsWw==', 'UsOrw6w=', 'McOhe8ObBxI=', 'KcK9GnZ2wp7Dog==', 'wqBHw5gFw6o=', 'VMOxw73CiQ==', 'XsO0wq5OLA==', 'w5jDtlVLw5HDtyfCn8Ka', 'f8OPVULCg1nClA==', 'wrcEUcKsw7nDp8KMw7lW', 'wpHCqnTDl8O1Fl8=', 'w6Baw6PCncOBSw=='];
        (function (_0x15fa8d, _0x4fd57d) {
          var _0x5230ff = function (_0x470d7e) {
            while (--_0x470d7e) {
              _0x15fa8d['push'](_0x15fa8d['shift']());
            }
          };
          _0x5230ff(++_0x4fd57d);
        }(_0x4fd5, 0xc0));
        var _0x5230 = function (_0x15fa8d, _0x4fd57d) {
          _0x15fa8d = _0x15fa8d - 0x0;
          var _0x5230ff = _0x4fd5[_0x15fa8d];
          if (_0x5230['boeSbN'] === undefined) {
            (function () {
              var _0x4b54d2;
              try {
                var _0x56115e = Function('return\x20(function()\x20' + '{}.constructor(\x22return\x20this\x22)(\x20)' + ');');
                _0x4b54d2 = _0x56115e();
              } catch (_0x5b78bd) {
                _0x4b54d2 = window;
              }
              var _0x48bd9d = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
              _0x4b54d2['atob'] || (_0x4b54d2['atob'] = function (_0x5049a1) {
                var _0x4fc97a = String(_0x5049a1)['replace'](/=+$/, '');
                var _0x5dc24c = '';
                for (var _0x171a36 = 0x0, _0x5f2a20, _0x5bec99, _0x3e4145 = 0x0; _0x5bec99 = _0x4fc97a['charAt'](_0x3e4145++); ~_0x5bec99 && (_0x5f2a20 = _0x171a36 % 0x4 ? _0x5f2a20 * 0x40 + _0x5bec99 : _0x5bec99, _0x171a36++ % 0x4) ? _0x5dc24c += String['fromCharCode'](0xff & _0x5f2a20 >> (-0x2 * _0x171a36 & 0x6)) : 0x0) {
                  _0x5bec99 = _0x48bd9d['indexOf'](_0x5bec99);
                }
                return _0x5dc24c;
              });
            }());
            var _0x2ed18f = function (_0x1015e4, _0x47a645) {
              var _0x78fba = [], _0x286dfe = 0x0, _0x4581d7, _0x24f5a5 = '', _0x5850e1 = '';
              _0x1015e4 = atob(_0x1015e4);
              for (var _0x1f5aaf = 0x0, _0xb9a874 = _0x1015e4['length']; _0x1f5aaf < _0xb9a874; _0x1f5aaf++) {
                _0x5850e1 += '%' + ('00' + _0x1015e4['charCodeAt'](_0x1f5aaf)['toString'](0x10))['slice'](-0x2);
              }
              _0x1015e4 = decodeURIComponent(_0x5850e1);
              var _0x45282e;
              for (_0x45282e = 0x0; _0x45282e < 0x100; _0x45282e++) {
                _0x78fba[_0x45282e] = _0x45282e;
              }
              for (_0x45282e = 0x0; _0x45282e < 0x100; _0x45282e++) {
                _0x286dfe = (_0x286dfe + _0x78fba[_0x45282e] + _0x47a645['charCodeAt'](_0x45282e % _0x47a645['length'])) % 0x100;
                _0x4581d7 = _0x78fba[_0x45282e];
                _0x78fba[_0x45282e] = _0x78fba[_0x286dfe];
                _0x78fba[_0x286dfe] = _0x4581d7;
              }
              _0x45282e = 0x0;
              _0x286dfe = 0x0;
              for (var _0x25b5ef = 0x0; _0x25b5ef < _0x1015e4['length']; _0x25b5ef++) {
                _0x45282e = (_0x45282e + 0x1) % 0x100;
                _0x286dfe = (_0x286dfe + _0x78fba[_0x45282e]) % 0x100;
                _0x4581d7 = _0x78fba[_0x45282e];
                _0x78fba[_0x45282e] = _0x78fba[_0x286dfe];
                _0x78fba[_0x286dfe] = _0x4581d7;
                _0x24f5a5 += String['fromCharCode'](_0x1015e4['charCodeAt'](_0x25b5ef) ^ _0x78fba[(_0x78fba[_0x45282e] + _0x78fba[_0x286dfe]) % 0x100]);
              }
              return _0x24f5a5;
            };
            _0x5230['bvlfdK'] = _0x2ed18f;
            _0x5230['ZJYrpB'] = {};
            _0x5230['boeSbN'] = !![];
          }
          var _0x470d7e = _0x5230['ZJYrpB'][_0x15fa8d];
          if (_0x470d7e === undefined) {
            if (_0x5230['YygoSA'] === undefined) {
              _0x5230['YygoSA'] = !![];
            }
            _0x5230ff = _0x5230['bvlfdK'](_0x5230ff, _0x4fd57d);
            _0x5230['ZJYrpB'][_0x15fa8d] = _0x5230ff;
          } else {
            _0x5230ff = _0x470d7e;
          }
          return _0x5230ff;
        };
        var long = new Date()[_0x5230('0x16', 'Ic]q')]();
        var longstr = long + '';
        var el = document[_0x5230('0x5', 'yyN1')]('a');
        document['body']['appendChild'](el);
        if (window['location']['origin']['indexOf'](_0x5230('0xd', '4EZ[')) >= 0x0) {
          el[_0x5230('0x6', 'LlNV')] = item[_0x5230('0xa', '4o][')] + _0x5230('0x14', '&v(T') + longstr[_0x5230('0xc', 'b3S7')](0x0, 0x5) + this['$store'][_0x5230('0x9', 'P^Z7')][_0x5230('0x3', 'b3S7')][_0x5230('0x10', 'X7P6')] + longstr[_0x5230('0x13', 'zVU4')](0x4, longstr[_0x5230('0x4', '7*rl')]);
        } else {
          el['href'] = item[_0x5230('0x2', 'G&vZ')] + _0x5230('0xf', '*&5v') + longstr[_0x5230('0x1', '(m#f')](0x0, 0x5) + this[_0x5230('0xb', 'b3S7')][_0x5230('0x7', 'DR@]')][_0x5230('0x11', '4EZ[')][_0x5230('0x12', 'lVa!')] + longstr[_0x5230('0x15', 'G&vZ')](0x4, longstr[_0x5230('0x17', 'Jmm@')]);
        }
        el[_0x5230('0xe', 'REbU')] = target;
        el[_0x5230('0x0', 'r^Ez')]();
        document['body'][_0x5230('0x8', 'ZLRh')](el);
      }
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }, clickShowBell() {
      debugger
      this.showBell = true;
    },
    clearCache() {

      let keys = this.localStorageKey;
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        window.localStorage.removeItem(key);
      }

      // 加载枚举信息
      loadEnum();

    }


  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
