<!-- 选择品牌 -->
<template>
  <div>

    <span v-if="isButton">
      <el-button type="primary" @click="handleClick">项目档案</el-button>
    </span>
    <span v-else>
      <el-input :placeholder="placeholder" :disabled="disabled" @focus="handleClick" :value="content" readonly>
        <i slot="suffix" class="el-icon-search" @click="handleClick" />
      </el-input>
    </span>

    <el-dialog
      title="选择项目档案"
      :visible="dialogVisible"
      width="70%"
      :append-to-body="true"
      destroy-on-close
      center
      :before-close="handleClose"
    >
      <el-row ref="searchBox" class="search-box">
        <el-col :span="4">
          <el-input v-model="condition.reportCode" clearable placeholder="请输入报备单号" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="condition.projectName" clearable placeholder="请输入项目名称" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="condition.contractor" clearable placeholder="请输入工程承包商" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="condition.designer" clearable placeholder="请输入设计方" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="condition.business" clearable placeholder="请输入业务方" />
        </el-col>
        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" @click="getPage">查询</el-button>
        </el-col>
      </el-row>
      <el-table
        ref="selectListTable"
        v-loading="loading"
        :data="tableData"
        :height="tableHeight"
        border
        @select="onSelect"
        @select-all="onSelectAll">
        <el-table-column type="selection" width="40"/>
        <el-table-column label="报备单号" align="left" prop="reportCode" show-overflow-tooltip width="160"/>
        <el-table-column label="项目名称" align="left" prop="projectName" show-overflow-tooltip width="160"/>
        <el-table-column label="业务方" align="left" prop="business" show-overflow-tooltip width="160"/>
        <el-table-column label="设计方" align="left" prop="designer" show-overflow-tooltip width="160"/>
        <el-table-column label="工程承包商" align="left" prop="contractor" show-overflow-tooltip width="160"/>
        <el-table-column label="联系人" align="left" prop="linkman" show-overflow-tooltip width="160"/>
        <el-table-column label="所属部门" align="left" prop="department" show-overflow-tooltip width="160">
          <template slot-scope="scope">
            <span>{{format.formatEnumDesc('DepartmentEnum',scope.row.department)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="footer-box">
        <el-col :span="5">
          <el-button type="primary" @click="handleConfirm">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-col>
        <el-col :span="14">
          <pagination
            :pager-count="7"
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getPage"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>

</template>

<script>
import Pagination from '@/components/Pagination'
import { getPage } from '@/api/crm/commercial/crmProjcetRecord'
import { isEmpty } from '@/utils/validate'
import format from '@/utils/formatter'

export default {
  name: 'selectSalesmanDialog',
  components: { Pagination },
  props: {
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择项目编码'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Function,
      required: true
    },
    currentIndex: {
      type: Number
    },
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    validation: { // 校验
      type: Function,
      default: function() {
        return true
      }
    },
    content: { // 输入框内容
      type: String,
      default: null
    },
    isButton: {
      type: Boolean,
      default: false
    } // 是否为按钮，
  },

  data() {
    return {
      format: format,
      dialogVisible: false,
      loading: false,
      condition: {
        mainProjectName: '',
        threadCode:'',
        projectName:'',
        projectCode:''
      },
      // 分页
      pagination: {
        total: 0,
        size: 20,
        current: 1
      },
      tableHeight: window.innerHeight - 400,
      tableData: []

    }
  },
  methods: {
    onSelect(selection, row) {
      if (!this.multiple) {
        this.$refs.selectListTable.clearSelection()
        this.$refs.selectListTable.toggleRowSelection(row)
      }
    },
    onSelectAll() {
      if (!this.multiple) {
        this.$refs.selectListTable.clearSelection()
      }
    },

    getPage() {
      this.loading = true
      getPage(this.condition, this.pagination).then((response) => {
        const { current, total, size, records } = response.data
        this.tableData = records
        this.pagination.total = total
        this.pagination.current = current
        this.pagination.size = size
      }).finally(() => {
        this.loading = false
      })
    },
    handleConfirm() {
      const selectData = this.$refs.selectListTable.selection
      if (isEmpty(selectData)) {
        return this.$message.warning(this.placeholder)
      }
      if (this.multiple) {
        this.confirm(selectData, this.currentIndex)
      } else {
        this.confirm(selectData[0], this.currentIndex)
      }
      this.$emit('change')
      this.dialogVisible = false
    },

    handleClose() {
      this.dialogVisible = false
    },
    handleClick() {
      if (!this.validation()) {
        return
      }
      this.dialogVisible = true
      this.getPage()
    }
  }

}
</script>

<style scoped>
i:hover {
  cursor: pointer
}
el-button {

}
</style>
