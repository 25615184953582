import { getAllEnums } from '@/api/system/enum'

import { getAllMenuColumnData } from '@/api/systempt/adminColumn'

const key = 'ALL_ENUM'
let allEnum = null

/**
 * 获取枚举详情列表
 */
export function getEnumItems(className) {
  const enumItem = loadLocalData()
  if (enumItem != null) {
    return enumItem[className]
  }
}

/**
 * 加载本地数据
 */
export function loadLocalData() {
  if (allEnum != null) {
    return allEnum
  }

  const item = window.localStorage.getItem(key)
  if (item == null || item.length === 0) {
    return null
  }
  allEnum = JSON.parse(item)
  return allEnum
}

/**
 * 获取枚举详情
 * @param className
 * @param name 枚举名称
 * @returns {null|*}
 */
export function getEnumItem(className, name) {

  const enumItem = getEnumItems(className)
  if (enumItem == null || enumItem.length === 0) {
    return null
  }
  for (let i = 0; i < enumItem.length; i++) {
    const item = enumItem[i]
    if (item.code == name) {
      return item
    }
  }
  return null
}

/**
 * 获取枚举描述
 * @param className
 * @param name
 */
export function getEnumDesc(className, name) {
  const item = getEnumItem(className, name)
  if (item == null) {
    return null
  }
  return item.desc
}

/**
 * 加载枚举类
 */
export function loadEnum() {
  loadColumnUserData()
  getAllEnums().then((response) => {
    const { data } = response
    window.localStorage.setItem(key, JSON.stringify(data))
  })
}


/**
 * 加载自定义列
 */
export function loadColumnUserData() {
  getAllMenuColumnData().then((response) => {
    if(response.data){
      for(let key in response.data){
        response.data[key] = JSON.parse(response.data[key]);
      }
      window.localStorage.setItem('userAllMenuColumnData', JSON.stringify(response.data))
    }
  })
}
