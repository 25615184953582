import {config} from "@/api/system/config";

const state = {
  config: {},// 系统配置
}

const mutations = {
  SET_CONFIG: (state, token) => {
    state.config = token
  },
}

const actions = {

  config({commit, state}) {
    return new Promise((resolve, reject) => {
      config().then(response => {
        const {data} = response;

        commit('SET_CONFIG', data);

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
