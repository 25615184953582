import {getEnumDesc, getEnumItems} from '@/utils/enum'

/**
 * 格式化工具类
 */

// 字典列表
export default {

  /**
   * 获取枚举详情列表
   */
  getEnumItems(className){
    return getEnumItems(className);
  },

  /**
   * 格式枚举描述
   * @param className 类
   * @param name 枚举名
   */
  formatEnumDesc(className, name) {
    return getEnumDesc(className, name)
  },

  /**
   * 格式boolean值
   * @param booleanVal
   * @returns {string|string}
   */
  formatBoolean(booleanVal) {
    if (booleanVal == null) {
      return ''
    }
    return booleanVal ? '是' : '否'
  },

  /**
   * 将 Date 转化为指定格式的string(本地时间)
   * @function formatDate
   * @param {Date} date
   * @param {string} [fmt=yyyy-MM-dd HH:mm:ss]
   * @returns {String}
   */
  formatDate(date, fmt) {
    if (!date || isNaN(date)) return ''
    var d = date
    if (typeof date === 'string') {
      if (date.indexOf("/Date(") > -1)
        d = new Date(parseInt(date.replace("/Date(", "").replace(")/", ""), 10));
      else
        d = new Date(Date.parse(date.replace(/-/g, "/").replace("T", " ").split(".")[0]));
    }else if (typeof date === 'number'){
      d = new Date(date)
    }
    if (!fmt) fmt = 'yyyy-MM-dd HH:mm:ss'
    const o = {
      'M+': d.getMonth() + 1, // 月份
      'd+': d.getDate(), // 日
      'h+': d.getHours() % 12 === 0 ? 12 : d.getHours() % 12, // 小时
      'H+': d.getHours(), // 小时
      'm+': d.getMinutes(), // 分
      's+': d.getSeconds() // 秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  },

  /**
   * 添加货币符号
   * @param amount 金额
   * @returns {string}
   */
  currency(amount) {
/*    if (amount == null) {
      return ''
    }
    if (isNaN(amount)) {
      return '￥0'
    }
    return '￥' + amount*/
    return amount;
  }

}

