<template>
  <div class="table-dialog" @mouseenter="hovering = true" @mouseleave="hovering = false">
    <slot v-if="!hidden && $slots.button" name="button" />
    <el-input v-else-if="!hidden" :placeholder="placeholder" :disabled="disabled" :value="content" readonly>
      <i v-if="showClear" slot="suffix" class="el-icon-circle-close suffix" @click="clear" />
      <template slot="append">
        <el-button slot="append" :disabled="disabled" icon="el-icon-search" @click="handleClick" />
      </template>
    </el-input>
    <el-dialog
      :title="title"
      :visible="dialogVisible"
      width="70%"
      :append-to-body="true"
      destroy-on-close
      :before-close="handleClose"
      :close-on-click-modal="closeModal"
    >
      <el-row ref="searchBox" class="search-box">
        <template v-if="searchCondition && searchCondition.length>0">
          <el-col :span="4">
            <el-select v-model="conditionName">
              <el-option
                v-for="item in searchCondition"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input v-model="conditionValue" placeholder="请输入" clearable @keyup.enter.native="getPaginationPage" />
          </el-col>
        </template>
        <template v-else>
          <slot name="condition" :condition="condition" />
        </template>
        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" @click="getPaginationPage">查询</el-button>
          <el-button icon="el-icon-refresh" @click="handleRefresh">重置</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-table
            ref="selectListTable"
            v-loading="loading"
            :data="tableData"
            :height="tableHeight"
            border
            :row-key="showRowKey?getRowKeys:''"
            @select="onSelect"
            @select-all="onSelectAll"
            @row-click="onRowClick"
            @row-dblclick="onDblclick"
          >
            <slot />
          </el-table>
        </el-col>
      </el-row>
      <el-row class="footer-box">
        <el-col :span="18">
          <Pagination
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getPaginationPage"
          />
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-button type="primary" @click="handleConfirm">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/utils/request'
import { isEmpty } from '@/utils/validate'
import Pagination from '@/components/Pagination'

export default {
  name: 'TableDialog',
  components: { Pagination },
  props: {
    showRowKey: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否点击空白处，关闭。false为否
    closeModal: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    searchCondition: {
      type: Array,
      default: function() {
        return []
      }
    },
    confirm: {
      type: Function,
      required: true
    },
    validate: {
      type: Function,
      default: () => {
        return true
      }
    },
    currentIndex: {
      type: Number | Object,
      default: null
    },
    content: String,
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    disabled: Boolean,
    hidden: { // 隐藏操作按钮
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      conditionName: '',
      conditionValue: '',
      condition: {
        isEnabled: true
      },
      // 分页
      pagination: {
        total: 0,
        size: 20,
        current: 1
      },
      tableHeight: window.innerHeight - 400,
      tableData: [],

      hovering: false,
      focused: false
    }
  },
  computed: {
    showClear() {
      return !this.disabled && this.clearable && this.content && (this.focused || this.hovering)
    }
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    onSelect(select, row) {
      this.$nextTick(() => {
        if (!this.multiple) {
          this.$refs.selectListTable.clearSelection()
          this.$refs.selectListTable.toggleRowSelection(row, true)
        }
      })
    },
    onSelectAll() {
      if (this.multiple) {
        return
      }
      this.$refs.selectListTable.clearSelection()
    },
    /**
     * 判断当前行是否可选择
     */
    isCanSelect(row) {
      // 有可能会设置selectable属性，需要判断当前数据是否可选
      let columns = this.$refs.selectListTable.columns
      let selectionColumn = columns.find(item => item.type === 'selection')
      if (selectionColumn === undefined) {
        return true
      }
      let selectable = selectionColumn.selectable
      if (selectable === undefined) {
        return true
      }
      return selectable(row)
    },

    onRowClick(row, column, event) {
      if (!this.isCanSelect(row)) {
        return
      }
      this.onSelect({}, row)
    },
    onDblclick(row, column, event) {
      if (!this.isCanSelect(row)) {
        return
      }
      if (this.multiple) {
        this.confirm([row], this.currentIndex)
      } else {
        this.confirm(row, this.currentIndex)
      }

      this.$emit('change')
      this.handleClose()
    },
    handleClick() {
      if (!this.validate()) {
        return
      }
      if (!isEmpty(this.searchCondition)) {
        this.conditionName = this.searchCondition[0].value // 默认选择第一个条件
      }
      this.dialogVisible = true
      this.getPage()
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    },
    getPaginationPage() {
      if (!isEmpty(this.$parent.getPage)) {
        this.$parent.getPage()
      } else {
        this.getPage()
      }
    },
    getPage() {
      const condition = { ...this.condition }
      if (this.conditionName && this.conditionName !== '') {
        condition[this.conditionName] = this.conditionValue
      }
      // 拼接参数
      if (this.params) {
        for (const key in this.params) {
          if (this.params.hasOwnProperty(key)) {
            condition[key] = this.params[key]
          }
        }
      }
      this.loading = true
      request({
        url: this.url,
        method: 'post',
        data: { condition: condition, pagination: this.pagination }
      }).then((response) => {
        const { current, total, size, records } = response.data
        this.tableData = records
        this.pagination.total = total
        this.pagination.current = current
        this.pagination.size = size
      }).finally(() => {
        this.loading = false
      })
    },
    handleConfirm() {
      const selectData = this.$refs.selectListTable.selection
      if (isEmpty(selectData)) {
        return this.$message.warning(this.placeholder)
      }
      if (this.multiple) {
        this.confirm(selectData, this.currentIndex)
      } else {
        this.confirm(selectData[0], this.currentIndex)
      }
      this.$emit('change')

      // 解决工单发布，产品资料页面显示数据问题
      this.pagination.current = 1
      if (!isEmpty(this.$parent.getPage)) {
        this.$parent.getPage(true)
      }

      this.dialogVisible = false
    },
    clear() {
      this.content = null
      this.$emit('input', null)
      this.$emit('change', null)
      this.$emit('clear')
    },
    handleRefresh() {
      this.conditionValue = null
      for (const key in this.condition) {
        this.condition[key] = null
      }
    }
  }
}
</script>

<style>
.table-dialog .el-input-group__append {
  padding: 0 14px;
}

.table-dialog .el-input__suffix-inner {
  line-height: 28px;
}

.el-icon-circle-close {
  cursor: pointer;
}
</style>
