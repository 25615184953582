var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
    },
    [
      _c("div", { staticStyle: { height: "50px" } }, [
        _c("div", { staticClass: "fixed" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                width: "83%",
              },
            },
            [
              _c("el-page-header", {
                attrs: {
                  content: _vm.form.id ? "编辑合同申请" : "新增合同申请",
                },
                on: { back: _vm.handleClose },
              }),
              _c(
                "div",
                { staticStyle: { "margin-left": "auto" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("基础信息"),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { display: "flex", "flex-wrap": "wrap" },
              attrs: {
                model: _vm.form,
                size: "small",
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "search-boxx gz-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同编号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "系统自动生成",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.contractCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractCode", $$v)
                              },
                              expression: "form.contractCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同类型", prop: "contractType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择合同类型",
                              },
                              model: {
                                value: _vm.form.contractType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "contractType", $$v)
                                },
                                expression: "form.contractType",
                              },
                            },
                            _vm._l(_vm.contractTypeEnum, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  clearable: "",
                                  label: item.desc,
                                  value: parseInt(item.code),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户合同编码",
                            prop: "clientContractCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入客户合同编码",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.clientContractCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientContractCode", $$v)
                              },
                              expression: "form.clientContractCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同名称", prop: "contractName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入合同名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.contractName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractName", $$v)
                              },
                              expression: "form.contractName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目编码", prop: "projectCode" } },
                        [
                          _c("select-project-record", {
                            attrs: {
                              confirm: _vm.projectSelect,
                              "is-button": false,
                              content: _vm.form.projectCode,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目名称", prop: "projectName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "项目自动带出",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectName", $$v)
                              },
                              expression: "form.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称", prop: "clientName" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请选择客户名称",
                                value: _vm.form.clientName,
                                readonly: "",
                              },
                              on: { focus: _vm.clientClick },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-search",
                                attrs: { slot: "suffix" },
                                on: { click: _vm.clientClick },
                                slot: "suffix",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户编码", prop: "clientCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "客户自动带出",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.clientCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "clientCode", $$v)
                              },
                              expression: "form.clientCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所在地区", prop: "address" } },
                        [
                          _c("el-cascader", {
                            ref: "cascaderArr",
                            attrs: {
                              placeholder: "请选择所在地区",
                              options: _vm.areaOption,
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChange($event)
                              },
                            },
                            model: {
                              value: _vm.form.areaIdList,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "areaIdList", $$v)
                              },
                              expression: "form.areaIdList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "详细地址", prop: "detailedAddress" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入详细地址",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.detailedAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "detailedAddress", $$v)
                              },
                              expression: "form.detailedAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售目标" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入详细地址",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.salesTarget,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salesTarget", $$v)
                              },
                              expression: "form.salesTarget",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "总金额" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "自动算出-明细合计",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.totalMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "totalMoney", $$v)
                              },
                              expression: "form.totalMoney",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "贷款金额" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入贷款金额",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.loanAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "loanAmount", $$v)
                              },
                              expression: "form.loanAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账期(天)" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入账期", clearable: "" },
                            model: {
                              value: _vm.form.paymentDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "paymentDays", $$v)
                              },
                              expression: "form.paymentDays",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "签署日期", prop: "signDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "请选择签署日期",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.signDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "signDate", $$v)
                              },
                              expression: "form.signDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同签订地点", prop: "signedAt" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入合同签订地点",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.signedAt,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "signedAt", $$v)
                              },
                              expression: "form.signedAt",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "贷款支付办法及时间",
                            prop: "agreementName",
                          },
                        },
                        [
                          _c("select-proceeds-agreement-dialog", {
                            attrs: {
                              confirm: _vm.agreementSelect,
                              "is-button": false,
                              content: _vm.form.agreementName,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否虚拟合同",
                            prop: "isVirtualContract",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择是否虚拟合同",
                              },
                              model: {
                                value: _vm.form.isVirtualContract,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isVirtualContract", $$v)
                                },
                                expression: "form.isVirtualContract",
                              },
                            },
                            _vm._l(_vm.isVirtualContractEnum, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  clearable: "",
                                  label: item.desc,
                                  value: parseInt(item.code),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同开始日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择合同开始日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.contractStartDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractStartDate", $$v)
                              },
                              expression: "form.contractStartDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同开始日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择合同开始日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.contractEndDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractEndDate", $$v)
                              },
                              expression: "form.contractEndDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "销售行业", prop: "salesProfession" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "客户自动带出",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.salesProfession,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salesProfession", $$v)
                              },
                              expression: "form.salesProfession",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "币种", prop: "currency" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "请输入币种",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.currency,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "currency", $$v)
                              },
                              expression: "form.currency",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "居间费" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择居间费",
                              },
                              model: {
                                value: _vm.form.intermediaryCost,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "intermediaryCost", $$v)
                                },
                                expression: "form.intermediaryCost",
                              },
                            },
                            _vm._l(
                              _vm.intermediaryCostTypeEnum,
                              function (item) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: {
                                    clearable: "",
                                    label: item.desc,
                                    value: parseInt(item.code),
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员", prop: "salesmanName" } },
                        [
                          _c("select-salesman-dialog", {
                            attrs: {
                              confirm: _vm.reportPersonSelect,
                              placeholder: "请选择业务员",
                              "is-button": false,
                              content: _vm.form.salesmanName,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: "",
                                clearable: "",
                                placeholder: "请选择单据状态",
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            _vm._l(_vm.statusEnum, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  clearable: "",
                                  label: item.desc,
                                  value: parseInt(item.code),
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建人" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "自动生成",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.createUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "createUser", $$v)
                              },
                              expression: "form.createUser",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "自动生成",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.createTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "createTime", $$v)
                              },
                              expression: "form.createTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入备注",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      staticStyle: { display: "flex", "align-items": "center" },
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", [_vm._v("产品信息")]),
                      _c(
                        "div",
                        { staticClass: "line" },
                        [
                          _c("select-client-product-dialog", {
                            staticStyle: {
                              float: "left",
                              "margin-left": "20px",
                            },
                            attrs: {
                              multiple: true,
                              confirm: _vm.productSelect,
                              text: "新增",
                              "is-button": true,
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "left",
                                "margin-left": "10px",
                              },
                              attrs: { type: "danger" },
                              on: { click: _vm.delProductLin },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.form.crmContractProductLineParams,
                                height: "250",
                                border: "",
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { type: "selection", width: "55" },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "产品名称",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "自动生成",
                                                clearable: "",
                                                disabled: "",
                                              },
                                              model: {
                                                value: scope.row.productName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "productName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.productName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productCode",
                                  label: "产品编码",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "clientProductName",
                                  label: "客户产品名称",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "请输入客户产品名称",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  scope.row.clientProductName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "clientProductName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.clientProductName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "clientProductCode",
                                  label: "客户产品编码",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: "",
                                                placeholder:
                                                  "请输入客户产品编码",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  scope.row.clientProductCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "clientProductCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.clientProductCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "specification",
                                  label: "规格",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "primaryUnit",
                                  label: "单位",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "minimumPrice",
                                  label: "基准价",
                                  width: "180",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "amount",
                                  label: "数量/组",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入数量",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "amount",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "unitPrice",
                                  label: "重量",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: "",
                                                placeholder: "自动生成",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.weight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "weight",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.weight",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "unitPrice",
                                  label: "单价",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入单价",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.unitPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "unitPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.unitPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "money",
                                  label: "金额",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: "",
                                                placeholder: "自动生成",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.money,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "money",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.money",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "money",
                                  label: "说明",
                                  width: "180",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入说明",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.illustrate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "illustrate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.illustrate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("customer-dialog", { ref: "customer", on: { select: _vm.onClient } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }