<!-- 合同信息 -->
<template>
  <el-dialog
    title="列表"
    :visible.sync="drawer"
    width="800px"
    @close="onClose"
    append-to-body
  >
    <div class="content">
      <el-row ref="searchBox" class="search-box">
        <el-col :span="3">
          <el-input v-model="condition.sn" clearable placeholder="合同编码" />
        </el-col>

        <el-col :span="3">
          <el-input
            v-model="condition.contractSn"
            clearable
            placeholder="合同号"
          />
        </el-col>

        <el-col :span="3">
          <el-input
            v-model="condition.contractName"
            clearable
            placeholder="合同名称"
          />
        </el-col>

        <el-col :span="3">
          <el-input
            v-model="condition.salesman"
            clearable
            placeholder="业务员"
          />
        </el-col>

        <el-col :span="3">
          <el-button type="primary" icon="el-icon-search" @click="getPage"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-table
        ref="listTable"
        v-loading="loading"
        :data="tableData"
        :height="tableHeight"
        border
      >
        <el-table-column type="selection" width="40" />

        <el-table-column
          label="合同编码"
          align="left"
          prop="sn"
          show-overflow-tooltip
        />

        <el-table-column
          label="合同号"
          align="left"
          prop="contractSn"
          show-overflow-tooltip
        />

        <el-table-column
          label="合同名称"
          align="left"
          prop="contractName"
          show-overflow-tooltip
        />

        <el-table-column
          label="业务员"
          align="left"
          prop="salesman"
          show-overflow-tooltip
        />

        <el-table-column
          label="客户编码"
          align="left"
          prop="customerNo"
          show-overflow-tooltip
        />

        <el-table-column
          label="客户名称"
          align="left"
          prop="customerName"
          show-overflow-tooltip
        />

        <el-table-column
          label="详细地址"
          align="left"
          prop="customerAddress"
          show-overflow-tooltip
        />

        <el-table-column
          label="联系人"
          align="left"
          prop="contact"
          show-overflow-tooltip
        />

        <el-table-column
          label="联系人电话"
          align="left"
          prop="contactMobile"
          show-overflow-tooltip
        />

        <el-table-column
          label="总金额"
          align="left"
          prop="totalMoney"
          show-overflow-tooltip
        />

        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="onSubmit(scope.row)"
              >确定
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="footer-box">
        <el-col :span="24">
          <pagination
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getPage"
          />
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { getPage, deleteById, exportExcel } from "@/api/hc/contract/contract";
import { booleanToStr } from "@/filters";
import Pagination from "@/components/Pagination";
import edit from "@/views/hc/contract/edit";
import ExcelImport from "@/components/ExcelImport";
import { downloadFile } from "@/utils/file";
import format from "@/utils/formatter";

export default {
  name: "Index",
  components: { Pagination, ExcelImport, edit },
  data() {
    return {
      drawer: false,
      tableHeight: "300px",
      format: format,
      showEdit: false,
      loading: false,
      editId: null,
      pagination: {
        total: 0,
        size: 20,
        current: 1,
      },
      condition: {
        sn: null,
        contractSn: null,
        contractName: null,
        salesman: null,
        status: 3,
        isChange:1,
      },
      tableData: [],
    };
  },
  created() {
    this.getPage();
  },
  methods: {
    onClose() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    open() {
      this.drawer = true;
      this.getPage();
    },
    onSubmit(row) {
      this.$emit("select", row);
      this.drawer = false;
    },
    getPage() {
      this.loading = true;
      getPage(this.condition, this.pagination)
        .then((reponse) => {
          const { current, total, size, records } = reponse.data;
          this.tableData = records;
          this.pagination.total = total;
          this.pagination.current = current;
          this.pagination.size = size;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDelete(row) {
      this.$confirm("请确认是否删除此数据", "提示", { type: "warning" }).then(
        () => {
          deleteById(row.id).then((response) => {
            this.$message.success("删除成功");
            this.getPage();
          });
        }
      );
    },
    handleEdit(id) {
      this.showEdit = true;
      this.editId = id;
    },
    handleBatchDel() {},
    handleConditionExport() {
      exportExcel(this.condition, this.pagination).then((response) => {
        downloadFile(response);
      });
    },
    closeEdit() {
      this.showEdit = false;
      this.getPage();
    },
    formatBoolean(cellValue) {
      return booleanToStr(cellValue.isEnabled);
    },
  },
};
</script>

<style scoped>
</style>
