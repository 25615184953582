/**
 * 报价申请档案
 */
import request from '@/utils/request'

export function getPage(condition, pagination) {
  return request({
    url: '/crm/contract/offer/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function deleteById(id) {
  return request({
    url: '/crm/contract/offer/deleteById',
    method: 'post',
    data: { id: id }
  })
}
export function saveInfo(data) {
  return request({
    url: '/crm/contract/offer/saveInfo',
    method: 'post',
    data
  })
}

export function updateInfo(data) {
  return request({
    url: '/crm/contract/offer/updateInfo',
    method: 'post',
    data
  })
}
/**
 * 获取详情
 * @param id
 * @returns {AxiosPromise}
 */
export function getOffer(id) {
  return request({
    url: '/crm/contract/offer/get',
    method: 'post',
    data: { id: id }
  })
}

/**
 * 使用项目档案客户校验客户档案是否存在
 * @param id
 * @returns {AxiosPromise}
 */
export function checkCustomerFileExists(projectId) {
  return request({
    url: '/crm/contract/offer/checkCustomerFileExists',
    method: 'post',
    data: { projectId: projectId }
  })
}
