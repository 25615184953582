var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.form.id ? "编辑流程单" : "新增流程单",
        visible: _vm.show,
        size: "80%",
        "before-close": _vm.handleClose,
        "append-to-body": "",
        "destroy-on-close": "",
        center: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.isCheck ? _vm.rules : {},
                "label-width": "140px",
                disabled:
                  ([3].includes(_vm.form.status) &&
                    !(
                      _vm.flowOptions.isAssignee &&
                      _vm.flowOptions.nodeName == "开始"
                    )) ||
                  _vm.type == "查看",
              },
            },
            [
              _c(
                "el-card",
                { attrs: { header: "业务流程信息" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { display: "flex", "flex-flow": "row wrap" },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "流程单号", prop: "sn" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.sn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sn", $$v)
                                  },
                                  expression: "form.sn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品名称/类别",
                                prop: "productCategory",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.productCategory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "productCategory", $$v)
                                  },
                                  expression: "form.productCategory",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "项目类型", prop: "projectType" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.projectType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "projectType", $$v)
                                    },
                                    expression: "form.projectType",
                                  },
                                },
                                _vm._l(_vm.projectTypeOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户名称",
                                prop: "customerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.customerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerName", $$v)
                                  },
                                  expression: "form.customerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户地区",
                                prop: "customerAreaId",
                              },
                            },
                            [
                              _c("area-select", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.customerAreaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerAreaId", $$v)
                                  },
                                  expression: "form.customerAreaId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户地址",
                                prop: "customerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.customerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "customerAddress", $$v)
                                  },
                                  expression: "form.customerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系人", prop: "contact" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.contact,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contact", $$v)
                                  },
                                  expression: "form.contact",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系人电话",
                                prop: "contactMobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.contactMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactMobile", $$v)
                                  },
                                  expression: "form.contactMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否需要审厂",
                                prop: "isFactory",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.isFactory,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isFactory", $$v)
                                    },
                                    expression: "form.isFactory",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "要求完成时间",
                                prop: "askAccomplishTime",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.askAccomplishTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "askAccomplishTime", $$v)
                                  },
                                  expression: "form.askAccomplishTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "实验室", prop: "laboratory" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.laboratory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "laboratory", $$v)
                                  },
                                  expression: "form.laboratory",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "跟单员", prop: "merchandiser" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.merchandiser,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "merchandiser", $$v)
                                  },
                                  expression: "form.merchandiser",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "培训工程师",
                                prop: "cultivateEngineer",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", clearable: "" },
                                model: {
                                  value: _vm.form.cultivateEngineer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cultivateEngineer", $$v)
                                  },
                                  expression: "form.cultivateEngineer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.roleName
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "合同号",
                                    prop: "contractSn",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: "",
                                      placeholder: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.contractSn,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "contractSn", $$v)
                                      },
                                      expression: "form.contractSn",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "note" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "note", $$v)
                                  },
                                  expression: "form.note",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { header: "测试流程信息" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { display: "flex", "flex-flow": "row wrap" },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "申请资料确认时间",
                                prop: "applyDatumTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.applyDatumTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "applyDatumTime", $$v)
                                  },
                                  expression: "form.applyDatumTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开案时间",
                                prop: "implementTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.implementTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "implementTime", $$v)
                                  },
                                  expression: "form.implementTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "样品寄出时间",
                                prop: "specimenSendTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.specimenSendTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "specimenSendTime", $$v)
                                  },
                                  expression: "form.specimenSendTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "覆盖图片集齐时间",
                                prop: "coverCompleteTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.coverCompleteTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "coverCompleteTime", $$v)
                                  },
                                  expression: "form.coverCompleteTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "草稿报告出具时间",
                                prop: "draftProvideTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.draftProvideTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "draftProvideTime", $$v)
                                  },
                                  expression: "form.draftProvideTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "正式报告出具时间",
                                prop: "officialProvideTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                on: { change: _vm.onOfficialProvideTime },
                                model: {
                                  value: _vm.form.officialProvideTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "officialProvideTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.officialProvideTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "跟单员是否已确认",
                                prop: "isMerchandiser",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "", filterable: "" },
                                  model: {
                                    value: _vm.form.isMerchandiser,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isMerchandiser", $$v)
                                    },
                                    expression: "form.isMerchandiser",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "跟单员确认时间",
                                prop: "merchandiserTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.merchandiserTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "merchandiserTime", $$v)
                                  },
                                  expression: "form.merchandiserTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "电子版证书完成时间",
                                prop: "electronicCertificateTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.electronicCertificateTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "electronicCertificateTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.electronicCertificateTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否有纸质证书",
                                prop: "isPaperCertificate",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.form.isPaperCertificate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "isPaperCertificate",
                                        $$v
                                      )
                                    },
                                    expression: "form.isPaperCertificate",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纸质版证书完成时间",
                                prop: "paperCertificateTime",
                                rules: [
                                  {
                                    required:
                                      _vm.form.isPaperCertificate == 1
                                        ? true
                                        : false,
                                    message: "请填写纸质版证书完成时间",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.paperCertificateTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "paperCertificateTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.paperCertificateTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证书/报告号",
                                prop: "certificateOrReportCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.certificateOrReportCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "certificateOrReportCode",
                                      $$v
                                    )
                                  },
                                  expression: "form.certificateOrReportCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "财务是否已确认",
                                prop: "isFinance",
                                rules: [
                                  {
                                    required:
                                      _vm.flowOptions.isAssignee &&
                                      [2].includes(_vm.form.status) &&
                                      _vm.flowOptions.nodeName == "财务",
                                    message: "请选择财务是否已确认",
                                    trigger: ["blur", "change"],
                                    type: "number",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    disabled:
                                      !_vm.flowOptions.isAssignee ||
                                      ![2].includes(_vm.form.status) ||
                                      _vm.flowOptions.nodeName != "财务",
                                  },
                                  model: {
                                    value: _vm.form.isFinance,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isFinance", $$v)
                                    },
                                    expression: "form.isFinance",
                                  },
                                },
                                _vm._l(_vm.booleanOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "财务确认时间",
                                prop: "financeTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.financeTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "financeTime", $$v)
                                  },
                                  expression: "form.financeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检测费(元)", prop: "testFee" } },
                            [
                              _c("el-input", {
                                attrs: { type: "number", clearable: "" },
                                model: {
                                  value: _vm.form.testFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "testFee", $$v)
                                  },
                                  expression: "form.testFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "认证费(元)",
                                prop: "attestationFee",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number", clearable: "" },
                                model: {
                                  value: _vm.form.attestationFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "attestationFee", $$v)
                                  },
                                  expression: "form.attestationFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "差旅费(元)", prop: "travelFee" },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number", clearable: "" },
                                model: {
                                  value: _vm.form.travelFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "travelFee", $$v)
                                  },
                                  expression: "form.travelFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.roleName
                        ? [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "项目单价",
                                      prop: "projectPrice",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { clearable: "", disabled: "" },
                                      model: {
                                        value: _vm.form.projectPrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "projectPrice",
                                            $$v
                                          )
                                        },
                                        expression: "form.projectPrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "合同金额",
                                      prop: "totalMoney",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { clearable: "", disabled: "" },
                                      model: {
                                        value: _vm.form.totalMoney,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "totalMoney", $$v)
                                        },
                                        expression: "form.totalMoney",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "审厂周期",
                                prop: "factoryAuditCycleTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                  clearable: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.form.factoryAuditCycleTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "factoryAuditCycleTime",
                                      $$v
                                    )
                                  },
                                  expression: "form.factoryAuditCycleTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "异常说明1",
                                prop: "abnormalNote",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.abnormalNote,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "abnormalNote", $$v)
                                  },
                                  expression: "form.abnormalNote",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "异常说明2",
                                prop: "abnormalNoteTwo",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.abnormalNoteTwo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "abnormalNoteTwo", $$v)
                                  },
                                  expression: "form.abnormalNoteTwo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "异常说明3",
                                prop: "abnormalNoteThree",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "", clearable: "" },
                                model: {
                                  value: _vm.form.abnormalNoteThree,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "abnormalNoteThree", $$v)
                                  },
                                  expression: "form.abnormalNoteThree",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "项目状态", prop: "projectStat" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "", filterable: "" },
                                  on: { change: _vm.onProjectStat },
                                  model: {
                                    value: _vm.form.projectStat,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "projectStat", $$v)
                                    },
                                    expression: "form.projectStat",
                                  },
                                },
                                _vm._l(_vm.projectStatOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "bzNote" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.bzNote,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bzNote", $$v)
                                  },
                                  expression: "form.bzNote",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.form.roleName
                ? _c(
                    "el-card",
                    { attrs: { header: "附件" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.uploadUrl,
                            "on-remove": _vm.handleRemove,
                            "on-preview": _vm.handlePreview,
                            "on-success": _vm.handleAvatarSuccess,
                            multiple: "",
                            "file-list": _vm.form.hcFlowpathAccessoryModels,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c("div", {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-card",
                { attrs: { header: "流程审批" } },
                [
                  _c(
                    "el-form",
                    [
                      _c("flow", {
                        ref: "flow",
                        attrs: {
                          "custom-event": true,
                          "flow-id": _vm.form.flowId,
                        },
                        on: {
                          flowLoad: _vm.flowLoad,
                          complete: _vm.onComplete,
                          stop: _vm.getData,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm({
                            name: "form",
                            isCheck: false,
                          })
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm.form.id && (_vm.form.status == 1 || !_vm.form.status)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("customerDialog", {
            ref: "customerDialog",
            on: { select: _vm.onSelect },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }