/**
 * 站点资料请求类
 */
import request from '@/utils/request'

export function getPage(condition, pagination) {
  return request({
    url: '/crm/customer/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function deleteById(id) {
  return request({
    url: '/crm/customer/deleteById',
    method: 'post',
    data: { id: id }
  })
}
export function saveOrUpdate(data) {
  return request({
    url: '/crm/customer/saveOrUpdate',
    method: 'post',
    data
  })
}
/**
 * 获取详情
 * @param id
 * @returns {AxiosPromise}
 */
export function get(id) {
  return request({
    url: '/crm/customer/get',
    method: 'get',
    params: {id: id }
  })
}

export function submit(id) {
  return request({
    url: '/crm/customer/submit',
    method: 'post',
    data: { id: id }
  })
}


export function getPageList(data) {
  return request({
    url: '/crm/provincial/code/listPage',
    method: 'post',
    data
  })
}