<template>
  <div style="height: calc(100vh - 110px); overflow-y: auto;" v-loading="loading">
  <div style="height: 50px;">
    <div class="fixed">
      <div style="display: flex;align-items: center;width: 83%">
        <el-page-header  @back="handleClose" :content="form.id?'编辑合同申请':'新增合同申请'"/>
        <div style="margin-left: auto;">
          <el-button type="primary" @click="submitForm('form')">保存</el-button>
          <el-button @click="handleClose">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
    <div style="margin-top: 10px;">
      <el-divider content-position="left">基础信息</el-divider>
      <el-form ref="form" style="display: flex;flex-wrap: wrap;" :model="form" size="small" :rules="rules"  label-width="120px" >
        <el-row  class="search-boxx gz-form-row">
          <el-col :span="6">
            <el-form-item label="合同编号" >
              <el-input v-model="form.contractCode" disabled placeholder="系统自动生成" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="合同类型"  prop="contractType">
              <el-select v-model="form.contractType"  clearable placeholder="请选择合同类型">
                <el-option clearable
                           v-for="item in contractTypeEnum"
                           :key="item.code"
                           :label="item.desc"
                           :value="parseInt(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户合同编码" prop="clientContractCode">
              <el-input v-model="form.clientContractCode"  placeholder="请输入客户合同编码" clearable  />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同名称"  prop="contractName">
              <el-input v-model="form.contractName"  placeholder="请输入合同名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目编码"  prop="projectCode">
              <select-project-record :confirm="projectSelect"  :is-button="false" :content="form.projectCode"></select-project-record>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目名称"  prop="projectName">
              <el-input v-model="form.projectName" disabled placeholder="项目自动带出" clearable />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="客户名称"  prop="clientName">
              <el-input placeholder="请选择客户名称" @focus="clientClick" :value="form.clientName" readonly>
                <i slot="suffix" class="el-icon-search" @click="clientClick" />
              </el-input>
              <!--                <el-input v-model="form.clientName"  placeholder="请输入客户名称" clearable />-->
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户编码"  prop="clientCode">
              <el-input v-model="form.clientCode" disabled placeholder="客户自动带出" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所在地区" prop="address">
              <el-cascader
                ref="cascaderArr"  placeholder="请选择所在地区"
                v-model="form.areaIdList"
                :options="areaOption"
                @change="handleChange($event)"
                clearable/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="详细地址"  prop="detailedAddress">
              <el-input v-model="form.detailedAddress"   placeholder="请输入详细地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售目标">
              <el-input v-model="form.salesTarget"   placeholder="请输入详细地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="总金额">
              <el-input v-model="form.totalMoney" disabled  placeholder="自动算出-明细合计" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="贷款金额">
              <el-input v-model="form.loanAmount"   placeholder="请输入贷款金额" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账期(天)">
              <el-input v-model="form.paymentDays"   placeholder="请输入账期" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签署日期" prop="signDate">
              <el-date-picker  v-model="form.signDate"  type="date" placeholder="请选择签署日期"
                               value-format="yyyy-MM-dd" format="yyyy-MM-dd" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同签订地点" prop="signedAt" >
              <el-input v-model="form.signedAt"  placeholder="请输入合同签订地点" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="贷款支付办法及时间" prop="agreementName" >
              <select-proceeds-agreement-dialog :confirm="agreementSelect" :is-button="false" :content="form.agreementName"></select-proceeds-agreement-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="是否虚拟合同"  prop="isVirtualContract">
              <el-select v-model="form.isVirtualContract"clearable placeholder="请选择是否虚拟合同">
                <el-option clearable
                           v-for="item in isVirtualContractEnum"
                           :key="item.code"
                           :label="item.desc"
                           :value="parseInt(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同开始日期">
              <el-date-picker  v-model="form.contractStartDate"  type="datetime" placeholder="请选择合同开始日期"
                               value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同开始日期" >
              <el-date-picker  v-model="form.contractEndDate"  type="datetime" placeholder="请选择合同开始日期"
                               value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售行业" prop="salesProfession">
              <el-input v-model="form.salesProfession" disabled placeholder="客户自动带出" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="币种" prop="currency">
              <el-input v-model="form.currency" disabled  placeholder="请输入币种" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="居间费"  >
              <el-select v-model="form.intermediaryCost"  clearable placeholder="请选择居间费">
                <el-option clearable
                           v-for="item in intermediaryCostTypeEnum"
                           :key="item.code"
                           :label="item.desc"
                           :value="parseInt(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="业务员"  prop="salesmanName">
              <select-salesman-dialog :confirm="reportPersonSelect" :placeholder="'请选择业务员'" :is-button="false" :content="form.salesmanName"></select-salesman-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  label="单据状态"  >
              <el-select v-model="form.status" disabled clearable placeholder="请选择单据状态">
                <el-option clearable
                           v-for="item in statusEnum"
                           :key="item.code"
                           :label="item.desc"
                           :value="parseInt(item.code)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人" >
              <el-input  v-model="form.createUser"  disabled placeholder="自动生成" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间" >
              <el-input v-model="form.createTime" disabled placeholder="自动生成" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="备注" >
              <el-input v-model="form.remark" type="textarea"  placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-card class="box-card" >
          <div slot="header" class="clearfix" style="display: flex;align-items: center">
            <span>产品信息</span>
            <div class="line">
              <select-client-product-dialog style="float: left;margin-left: 20px" :multiple="true" :confirm="productSelect" text="新增" :is-button="true" ></select-client-product-dialog>
              <el-button style="float: left;margin-left: 10px" type="danger" @click="delProductLin">删除</el-button>
            </div>
          </div>
          <el-row>
            <el-col :span="24">
              <el-table
                :data="form.crmContractProductLineParams"
                height="250"
                border
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column  prop="name"  label="产品名称"  width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.productName"  placeholder="自动生成" clearable disabled />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="productCode" label="产品编码" width="180"/>
                <el-table-column prop="clientProductName" label="客户产品名称" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.clientProductName"  placeholder="请输入客户产品名称" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="clientProductCode" label="客户产品编码" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.clientProductCode" disabled placeholder="请输入客户产品编码" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="specification" label="规格" width="180"/>
                <el-table-column prop="primaryUnit" label="单位" width="180"/>
                <el-table-column prop="minimumPrice" label="基准价" width="180"/>
                <el-table-column prop="amount" label="数量/组" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.amount"   placeholder="请输入数量" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="unitPrice" label="重量" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.weight"  disabled placeholder="自动生成" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="unitPrice" label="单价" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.unitPrice"   placeholder="请输入单价" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="money" label="金额" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.money" disabled placeholder="自动生成" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="money" label="说明" width="180">
                  <template slot-scope="scope">
                    <el-form-item >
                      <el-input v-model="scope.row.illustrate"  placeholder="请输入说明" clearable />
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-card>

      </el-form>
    </div>
    <!--客户信息-->
    <customer-dialog ref="customer" @select="onClient"></customer-dialog>
  </div>

</template>

<script>
import { get, saveApplyInfo,updateApplyInfo} from '@/api/crm/contract/crmContractApply.js'
import { getOffer } from '@/api/crm/contract/crmOfferApply'
import selectClientProductDialog from "@/views/crm/components/base/selectClientProductDialog";
import selectProceedsAgreementDialog from "@/views/crm/components/base/selectProceedsAgreementDialog";
import selectProjectRecord from "@/views/crm/components/commercial/selectProjectRecord";
import selectSalesmanDialog from "@/views/crm/components/client/selectSalesmanDialog";
import customerDialog from "@/views/crm/components/customerDialog/index";
  import format from '@/utils/formatter'
  import { pcData } from '@/utils/area'
  import {isEmpty} from "@/utils/validate";
import { getEnumItems } from "@/utils/enum";
  export default {
    components: {selectClientProductDialog,selectProceedsAgreementDialog,selectProjectRecord,customerDialog,selectSalesmanDialog},
    name: 'contractApplyEdit',
    props: {
      show: {
        type: Boolean,
        default() {
          return false
        }
      },
      editId: Number,
      offerId:Number//报价Id，用于报价转合同申请
    },

    data() {
      return {
        loading: false,
        format:format,
        form: {
          id: null,
          contractCode: '',//合同编码
          contractType:'',//合同类型
          clientContractCode:'',//客户合同编码
          contractName:'',//合同名称
          projectCode: '',//项目编码
          projectName:'',//项目名称
          projectId:'',//项目Id
          clientId:'',//客户Id
          clientCode:'',//客户编码
          clientName:'',//客户名称
          address: '',//所在地址
          detailedAddress: '',//详细地址
          salesTarget: '',//销售目标
          totalMoney: '',//总金额
          loanAmount: '',//贷款金额
          paymentDays: '',//账期
          signDate: '',//签署日期
          signedAt: '',//合同签订地点
          agreementId:'',//协议ID
          agreementName: '',//贷款支付办法及时间
          isVirtualContract: '',//是否虚拟合同
          salesmanId:'',//业务员Id
          salesmanName:'',//业务员名称
          contractStartDate: '',//合同开始日期
          contractEndDate: '',//合同结束日期
          salesProfession: '',//销售行业
          currency: '人民币',//币种
          intermediaryCost:null,//居间费
          type:20,//合同管理类型
          remark: '',
          createTime: '',
          createUser:'',
          status:1,//单据状态
          areaIdList:[],
          crmContractProductLineParams:[],
        },
        // 地址下拉
        areaOption: null,
        multipleSelection:[],
        isVirtualContractEnum: [{
          code:1,
          desc:"是"
        },{
          code:0,
          desc:"否"
        }], //
        rules: {
          contractCode: [{ required: true, message: '合同编码不能为空', trigger: 'blur' }],
          contractType: [{ required: true, message: '合同类型不能为空', trigger: 'blur' }],
          clientContractCode: [{ required: true, message: '客户合同编码不能为空', trigger: 'blur' }],
          contractName: [{ required: true, message: '合同名称不能为空', trigger: 'blur' }],
          clientName: [{ required: true, message: '客户名称不能为空', trigger: 'input' }],
          clientCode: [{ required: true, message: '客户编码不能为空', trigger: 'blur' }],
          address: [{ required: true, message: '所在地区不能为空', trigger: 'blur' }],
          detailedAddress: [{ required: true, message: '详细地址不能为空', trigger: 'blur' }],
          signDate: [{ required: true, message: '签署日期不能为空', trigger: 'blur' }],
          signedAt: [{ required: true, message: '合同签订地点不能为空', trigger: 'blur' }],
          agreementName: [{ required: true, message: '贷款支付办法及时间不能为空', trigger: 'input' }],
          isVirtualContract: [{ required: true, message: '请选择是否虚拟合同', trigger: 'blur' }],
          salesmanName: [{ required: true, message: '请选择业务员', trigger: 'input' }]
        },
        contractTypeEnum:getEnumItems('contractType'),
        intermediaryCostTypeEnum:format.getEnumItems('IntermediaryCostTypeEnum'),
        statusEnum:format.getEnumItems('StatusEnum'),
      }
    },
    watch: {
      editId(editId) {

      },
      show(show) {
        console.log("this.offerId",this.offerId)
        if (!show) {
          return
        }

      },
      'form.crmContractProductLineParams': {
        handler(newVal, oldVal) {
          let totalAmount = 0;
          console.log("newVal",newVal)
          newVal.forEach((item,index)=>{
           this.calculateWeight(index,item);
          })
        },
        deep: true
      }
    },
    created() {
      this.areaOption = pcData
      if (this.editId == null && this.$route.query.id) {
        this.editId = this.$route.query.id;
      }
      console.log(this.$route.query)
      if (this.$route.query.offerId){
        this.offerId = this.$route.query.offerId;
        this.getOffer();
      }
      this.info();
    },
    methods: {
      info(){
        if (this.editId == null)return ;
        this.loading = true
        get(this.editId).then((response) => {
          const { data } = response
          this.form = data
          this.form.crmContractProductLineParams = data.crmContractProductLinVOS;
          console.log(data.address)
          this.form.areaIdList = !data.address ? [] : data.address.split(",")
          console.log(this.form.areaIdList )
        }).finally(() => {
          this.loading = false
        })
      },
      //获取报价申请信息
      getOffer(){
        this.loading = true
        getOffer(this.offerId).then((response) => {
          const { data } = response
          this.$set(this.form, "projectId", data.id);
          this.$set(this.form, "projectName", data.projectName);
          this.$set(this.form, "projectCode", data.projectCode);
          data.crmOfferProductLinVOList.forEach(item=>{
            item.id = null;
          })
          console.log(data.crmOfferProductLinVOList)
          this.$set(this.form, "crmContractProductLineParams", data.crmOfferProductLinVOList);
          console.log("data",data);
        }).finally(() => {
          this.loading = false
        })
      },
      handleClose() {
        this.form= {
          id: null,
            contractCode: '',//合同编码
            contractType:'',//合同类型
            clientContractCode:'',//客户合同编码
            contractName:'',//合同名称
            projectCode: '',//项目编码
            projectName:'',//项目名称
            projectId:'',//项目Id
            clientId:'',//客户Id
            clientCode:'',//客户编码
            clientName:'',//客户名称
            address: '',//所在地址
            detailedAddress: '',//详细地址
            salesTarget: '',//销售目标
            totalMoney: '',//总金额
            loanAmount: '',//贷款金额
            paymentDays: '',//账期
            signDate: '',//签署日期
            signedAt: '',//合同签订地点
            agreementId:'',//贷款Id
            agreementName:'',//贷款支付办法及时间
            isVirtualContract: '',//是否虚拟合同
            salesmanId:'',//业务员Id
            salesmanName:'',//业务员名称
            contractStartDate: '',//合同开始日期
            contractEndDate: '',//合同结束日期
            salesProfession: '',//销售行业
            currency: '',//币种
            intermediaryCost:'',//居间费
            type:20,//合同管理类型
            remark: '',
            createTime: '',
            createUser:'',
            status:1,
            areaIdList:[],
            crmContractProductLineParams:[]
        };
        // this.$route.params.id = null
        const view = this.$route;
        this.$store
          .dispatch("tagsView/delView", view)
          .then(({ visitedViews }) => {
            if (this.isActive(view)) {
              this.toLastView(visitedViews, view);
            }
          });
        this.$emit('close')
      },
      isActive(route) {
        return route.path === this.$route.path;
      },
      toLastView(visitedViews, view) {
        const latestView = visitedViews.slice(-1)[0];
        if (latestView) {
          this.$router.push(latestView.fullPath);
        } else {
          // now the default is to redirect to the home page if there is no tags-view,
          // you can adjust it according to your needs.
          if (view.name === "Dashboard") {
            // to reload home page
            this.$router.replace({ path: "/redirect" + view.fullPath });
          } else {
            this.$router.push("/");
          }
        }
      },
      reportPersonSelect(data) {
        console.log(data);
        this.$set(this.form, "salesmanName", data.salesmanName);
        this.$set(this.form, "salesmanId", data.id);
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) {
            return false
          }
          let formDate = this.form.id?updateApplyInfo:saveApplyInfo
          formDate(this.form).then(() => {
            this.handleClose()
            this.$message.success('操作成功')
          })
        })
      },
      popoverHide(value) {
        this.item.parent = value
      },
      clientClick(){
        this.$refs.customer.open();
      },
      //计算重量
      calculateWeight(index,data){
        let list = data.measureUnitName.split("/");
        if (Array.isArray(list)){
          let mainUnit = list[0];
          let weight = parseFloat(mainUnit) * parseFloat(data.amount)
          this.form.crmContractProductLineParams[index].weight = weight.toFixed(2);

          let money = parseFloat(data.weight) * parseFloat(data.unitPrice);
          this.form.crmContractProductLineParams[index].money = money.toFixed(2);
        }
        console.log(list);
      },
      //计算金额
      calculationAmount(index,data){
        let money = parseFloat(data.weight) * parseFloat(data.money);
        this.form.crmContractProductLineParams[index].money = money.toFixed(2);
      },
      /**
       * 删除明细
       */
      delProductLin(){
        if (isEmpty(this.multipleSelection)){
          this.$message({
            message: '请至少选择一条数据',
            type: 'warning'
          });
          return
        }
        this.$confirm('请确认是否删除此数据', '提示', { type: 'warning' }).then(() => {
          this.multipleSelection.forEach(item=>{
            this.form.crmContractProductLineParams.forEach((item2,index)=>{
              if (item.id == item2.id){
                this.form.crmContractProductLineParams.splice(index,1)
              }
            })
          })
          this.$message.success('删除成功')
        })
      },
      //选择业务员信息
      productSelect(data){
        console.log(data)
        if (data.length > 0){
          data.forEach(e=>{
            let item = {
              productName:e.materialName,//产品名称
              productCode:e.materialCode,//产品编码
              clientProductName:e.clientMaterialName,//客户产品编码
              clientProductCode:e.clientMaterialCode,//客户产品名称
              minimumPrice:e.minimumPrice,//基准价
              measureUnitName:e.measureUnitName,
              primaryUnit:e.primaryUnit,//单位
              specification:e.specification,//规格
              productId:e.productId,
              amount:0,
              weight:0,
              unitPrice:0,
              money:0,
              illustrate:'',
            }
            this.form.crmContractProductLineParams.push(item);
          })

        }

      },
      agreementSelect(data){
        this.$set(this.form, "agreementId", data.id);
        this.$set(this.form, "agreementName", data.agreementName);
      },
      //项目档案
      projectSelect(data){
        this.$set(this.form, "projectId", data.id);
        this.$set(this.form, "projectName", data.projectName);
        this.$set(this.form, "projectCode", data.reportCode);
      },
      // 下拉地址绑定事件
      handleChange(array) {
        this.form.address = array.join(',');
      },
      //客户信息
      onClient(data){
        console.log(data)
        this.$set(this.form, "clientId", data.id);
        this.$set(this.form, "clientName", data.customerName);
        this.$set(this.form, "clientCode", data.customerNo);
        this.$set(this.form, "salesProfession", data.sellName);
      }
    }
  }
</script>

<style scoped>
.search-boxx .el-col {
  margin-bottom: 18px;
}
.gz-form-row{
  display: flex;
  flex-wrap: wrap;
}
.fixed{
  position: fixed;width: 100%;
  background-color: #FFF;
  z-index: 10;
  border-bottom: 1px solid  #c3c3c3;
  height: 40px;
}
</style>
