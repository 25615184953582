<template>
  <el-drawer
    v-loading="loading"
    :title="form.id ? '编辑流程单' : '新增流程单'"
    :visible.sync="show"
    size="80%"
    :before-close="handleClose"
    append-to-body
    destroy-on-close
    center
    :close-on-click-modal="false"
  >
    <div style="height: calc(100vh - 110px); overflow-y: auto">
      <el-form
        ref="form"
        :model="form"
        :rules="isCheck ? rules : {}"
        label-width="140px"
        :disabled="([3].includes(form.status) && !(flowOptions.isAssignee && flowOptions.nodeName == '开始')) || type == '查看'"
      >
        <el-card header="业务流程信息">
          <el-row style="display:flex;flex-flow: row wrap;">
            <el-col :span="6">
              <el-form-item label="流程单号" prop="sn">
                <el-input v-model="form.sn" disabled placeholder="" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="产品名称/类别" prop="productCategory">
                <el-input v-model="form.productCategory" disabled placeholder="" clearable />
                <!-- <el-select v-model="form.productCategory" clearable filterable disabled>
                  <el-option
                    v-for="item in productCategoryOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select> -->
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="项目类型" prop="projectType">
                <el-select v-model="form.projectType" clearable filterable disabled>
                  <el-option
                    v-for="item in projectTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户名称" prop="customerName">
                <el-input v-model="form.customerName" placeholder="" clearable disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户地区" prop="customerAreaId">
                <area-select v-model="form.customerAreaId" disabled />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户地址" prop="customerAddress">
                <el-input v-model="form.customerAddress" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="联系人" prop="contact">
                <el-input v-model="form.contact" disabled clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="联系人电话" prop="contactMobile">
                <el-input v-model="form.contactMobile" disabled clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否需要审厂" prop="isFactory">
                <el-select v-model="form.isFactory" clearable filterable disabled>
                  <el-option v-for="item in booleanOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="要求完成时间" prop="askAccomplishTime">
                <el-input v-model="form.askAccomplishTime" placeholder="" clearable disabled />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="实验室" prop="laboratory">
                <el-input v-model="form.laboratory" disabled clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="跟单员" prop="merchandiser">
                <el-input v-model="form.merchandiser" disabled clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="培训工程师" prop="cultivateEngineer">
                <el-input v-model="form.cultivateEngineer" disabled clearable />
              </el-form-item>
            </el-col>
            <el-col v-if="form.roleName" :span="6">
              <el-form-item label="合同号" prop="contractSn">
                <el-input v-model="form.contractSn" disabled placeholder="" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="note">
                <el-input v-model="form.note" placeholder="" clearable disabled />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card header="测试流程信息">
          <el-row style="display: flex;flex-flow: row wrap;">
            <el-col :span="6">
              <el-form-item label="申请资料确认时间" prop="applyDatumTime">
                <el-date-picker
                  v-model="form.applyDatumTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="开案时间" prop="implementTime">
                <el-date-picker
                  v-model="form.implementTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="样品寄出时间" prop="specimenSendTime">
                <el-date-picker
                  v-model="form.specimenSendTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="覆盖图片集齐时间" prop="coverCompleteTime">
                <el-date-picker
                  v-model="form.coverCompleteTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="草稿报告出具时间" prop="draftProvideTime">
                <el-date-picker
                  v-model="form.draftProvideTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="正式报告出具时间" prop="officialProvideTime">
                <el-date-picker
                  v-model="form.officialProvideTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                  @change="onOfficialProvideTime"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="跟单员是否已确认" prop="isMerchandiser">
                <el-select v-model="form.isMerchandiser" clearable filterable>
                  <el-option v-for="item in booleanOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="跟单员确认时间" prop="merchandiserTime">
                <el-date-picker
                  v-model="form.merchandiserTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="电子版证书完成时间" prop="electronicCertificateTime">
                <el-date-picker
                  v-model="form.electronicCertificateTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="是否有纸质证书" prop="isPaperCertificate">
                <el-select v-model="form.isPaperCertificate">
                  <el-option v-for="item in booleanOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item
                label="纸质版证书完成时间"
                prop="paperCertificateTime"
                :rules="[
                  {
                    required: form.isPaperCertificate == 1 ? true : false,
                    message: '请填写纸质版证书完成时间',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-date-picker
                  v-model="form.paperCertificateTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="证书/报告号" prop="certificateOrReportCode">
                <el-input v-model="form.certificateOrReportCode" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="财务是否已确认"
                prop="isFinance"
                :rules="[
                  {
                    required:
                      flowOptions.isAssignee &&
                      [2].includes(form.status) &&
                      flowOptions.nodeName == '财务',
                    message: '请选择财务是否已确认',
                    trigger: ['blur','change'],
                    type: 'number',
                  },
                ]"
              >
                <el-select
                  v-model="form.isFinance"
                  clearable
                  filterable
                  :disabled="
                    !flowOptions.isAssignee ||
                      ![2].includes(form.status) ||
                      flowOptions.nodeName != '财务'
                  "
                >
                  <el-option v-for="item in booleanOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="财务确认时间" prop="financeTime">
                <el-date-picker
                  v-model="form.financeTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="检测费(元)" prop="testFee">
                <el-input v-model="form.testFee" type="number" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="认证费(元)" prop="attestationFee">
                <el-input v-model="form.attestationFee" type="number" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="差旅费(元)" prop="travelFee">
                <el-input v-model="form.travelFee" type="number" clearable />
              </el-form-item>
            </el-col>

            <template v-if="form.roleName">
              <el-col :span="6">
                <el-form-item label="项目单价" prop="projectPrice">
                  <el-input v-model="form.projectPrice" clearable disabled />
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="合同金额" prop="totalMoney">
                  <el-input v-model="form.totalMoney" clearable disabled />
                </el-form-item>
              </el-col>
            </template>

            <el-col :span="6">
              <el-form-item label="审厂周期" prop="factoryAuditCycleTime">
                <el-date-picker
                  v-model="form.factoryAuditCycleTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  clearable
                  disabled
                />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="异常说明1" prop="abnormalNote">
                <el-input v-model="form.abnormalNote" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="异常说明2" prop="abnormalNoteTwo">
                <el-input v-model="form.abnormalNoteTwo" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="异常说明3" prop="abnormalNoteThree">
                <el-input v-model="form.abnormalNoteThree" placeholder="" clearable />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="项目状态" prop="projectStat">
                <el-select v-model="form.projectStat" clearable filterable @change="onProjectStat">
                  <el-option
                    v-for="item in projectStatOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="备注" prop="bzNote">
                <el-input v-model="form.bzNote" clearable />
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card v-if="form.roleName" header="附件">
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :on-remove="handleRemove"
            :on-preview="handlePreview"
            :on-success="handleAvatarSuccess"
            multiple
            :file-list="form.hcFlowpathAccessoryModels"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              <!-- 只能上传jpg/png文件，且不超过500kb -->
            </div>
          </el-upload>
        </el-card>

        <el-card header="流程审批">
          <el-form>
            <flow
              ref="flow"
              :custom-event="true"
              :flow-id="form.flowId"
              @flowLoad="flowLoad"
              @complete="onComplete"
              @stop="getData"
            />
          </el-form>
        </el-card>
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            @click="submitForm({ name: 'form', isCheck: false })"
          >确认</el-button>
          <el-button v-if="form.id && (form.status == 1 || !form.status)" type="primary" @click="submit()">提交</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
      <customerDialog ref="customerDialog" @select="onSelect" />
    </div>
  </el-drawer>
</template>

<script>
import dayjs from 'dayjs'
import { get, saveOrUpdate, submit } from '@/api/hc/flow/flow'
import Flow from '@/components/Flow'
import customerDialog from '@/views/hc/components/customerDialog'
import AreaSelect from '@/views/fwpt/basic/area/AreaSelect'
export default {
  name: 'hcFlowEdit',
  components: { Flow, customerDialog, AreaSelect },
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    editId: Number
  },

  data() {
    return {
      isCheck: false,
      type: '',
      flowOptions: {},
      productCategoryOptions: [
        {
          label: 'CCC新申请',
          value: 1
        },
        {
          label: 'CCCS同类新申请',
          value: 2
        },
        {
          label: '能效检测',
          value: 3
        },
        {
          label: '委托测试项目',
          value: 4
        },
        {
          label: '化学项目',
          value: 5
        },
        {
          label: '体系项目',
          value: 6
        },
        {
          label: '节能认证新申请',
          value: 7
        },
        {
          label: '节能认证同类新申请',
          value: 8
        },
        {
          label: '环保认证',
          value: 9
        },
        {
          label: '环保认证同类新申请',
          value: 10
        },
        {
          label: 'CB认证',
          value: 11
        },
        {
          label: 'CE认证',
          value: 12
        }
      ],
      projectTypeOptions: [
        {
          label: 'CCCS同类新申请',
          value: 1
        },
        {
          label: '能效检测',
          value: 2
        },
        {
          label: '委托测试项目',
          value: 3
        },
        {
          label: '化学项目',
          value: 4
        },
        {
          label: '节能认证同类新申请',
          value: 5
        },
        {
          label: '环保认证同类新申请',
          value: 6
        },
        {
          label: 'CB认证',
          value: 7
        },
        {
          label: 'CE认证',
          value: 8
        }
      ],
      booleanOptions: [
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ],
      projectStatOptions: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '异常',
          value: 2
        }
      ],
      loading: false,
      form: {
        isPaperCertificate: 1,
        hcFlowpathAccessoryModels: []
      },
      rules: {
        abnormalNote: [
          { required: false, message: '异常说明1不能为空', trigger: 'blur' }
        ],
        applyDatumTime: [
          {
            required: true,
            message: '申请资料确认时间不能为空',
            trigger: 'blur'
          }
        ],
        implementTime: [
          { required: true, message: '开案时间不能为空', trigger: 'blur' }
        ],
        specimenSendTime: [
          { required: true, message: '样品寄出时间不能为空', trigger: 'blur' }
        ],
        isPaperCertificate: [
          { required: true, message: '是否有纸质证书不能为空', trigger: 'blur' }
        ],
        coverCompleteTime: [
          {
            required: true,
            message: '覆盖图片集齐时间不能为空',
            trigger: 'blur'
          }
        ],
        draftProvideTime: [
          {
            required: true,
            message: '草稿报告出具时间不能为空',
            trigger: 'blur'
          }
        ],
        officialProvideTime: [
          {
            required: true,
            message: '正式报告出具时间不能为空',
            trigger: 'blur'
          }
        ],
        isMerchandiser: [
          {
            required: true,
            message: '跟单员是否已确认不能为空',
            trigger: 'blur'
          }
        ],
        electronicCertificateTime: [
          { required: true, message: '电子版证书完成时间不能为空', trigger: 'blur' }
        ],
        // paperCertificateTime: [
        //   { required: true, message: "纸质版证书完成时间不能为空", trigger: "blur" },
        // ],
        certificateOrReportCode: [
          { required: true, message: '证书/报告号不能为空', trigger: 'blur' }
        ],

        testFee: [
          {
            validator: (rule, value, callback) => {
              if (value < 0) {
                callback(new Error('检测费不能小于零'))
              } else {
                callback()
              }
            }, message: '检测费不能小于零', trigger: 'blur'
          }
        ],
        attestationFee: [
          {
            validator: (rule, value, callback) => {
              if (value < 0) {
                callback(new Error('认证费不能小于零'))
              } else {
                callback()
              }
            }, message: '认证费不能小于零', trigger: 'blur'
          }
        ],
        travelFee: [
          {
            validator: (rule, value, callback) => {
              if (value < 0) {
                callback(new Error('差旅费不能小于零'))
              } else {
                callback()
              }
            }, message: '差旅费不能小于零', trigger: 'blur'
          }
        ]

      },
      uploadUrl: process.env.VUE_APP_FILE_UPLOAD_URL
    }
  },
  watch: {
    editId(editId) { },
    show(show) {
      if (!show) {
        return
      }
      if (this.editId == null) {
        this.form = this.$options.data.call(this).form
        return
      }
      this.getData()
    }
  },
  created() {
    this.type = this.$route.query.type
    if (this.editId == null && this.$route.query.id) {
      this.editId = this.$route.query.id
      this.getData()
    }
  },
  methods: {
    onProjectStat(e) {
      if (e == 2) {
        this.rules.abnormalNote[0].required = true
      } else {
        this.rules.abnormalNote[0].required = false
      }
    },
    onOfficialProvideTime(e) {
      this.form.factoryAuditCycleTime = dayjs(e).add(10, 'day').format('YYYY-MM-DD')
    },
    // 流程提交
    async onComplete() {
      // 先执行保存
      await this.submitForm({
        name: 'form',
        isClose: false,
        isCheck: true
      }, async() => {
        // 在执行提交
        this.$refs.flow.onComplete(() => {
          this.handleClose()
        })
      })
    },
    flowLoad(e) {
      console.log(e)
      this.flowOptions = e
      this.$refs.flow.validatorForm.push(this.$refs.isProceeds)
    },
    async getData() {
      if (!this.editId) return
      this.loading = true
      try {
        const response = await get(this.editId)
        const { data } = response
        this.form = data
        this.loading = false
        if (Array.isArray(this.form.hcFlowpathAccessoryModels)) {
          this.form.hcFlowpathAccessoryModels.forEach((item) => {
            this.$set(item, 'url', item.accessoryUrl)
            this.$set(item, 'name', item.accessoryName)
          })
        } else {
          this.form.hcFlowpathAccessoryModels = []
        }
      } catch (error) {
        this.loading = false
      }
    },
    handlePreview(file) {
      console.log(file)
      // window.open(file.url, file.url, "_blank");
      window.open(file.url, file.url, '_blank')
      // saveAs(file.url, file.accessoryName || file.url);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.form.hcFlowpathAccessoryModels = fileList
    },
    handleAvatarSuccess(response) {
      if (!response.status === 200) {
        this.$message.error(response.msg)
        return
      }
      const { fileName, url, md5 } = response.data
      this.form.hcFlowpathAccessoryModels.push({
        // contractApplyId: 1 /** 合同主表ID */,
        accessoryName: fileName,
        accessoryUrl: url /** 附件URL */,
        url: url /** 附件URL */,
        name: fileName /** 附件URL */
      })
      console.log(fileName, url, md5)
    },
    onSelect(row) {
      Object.assign(this.form, {
        customerId: row.id,
        customerNo: row.customerNo,
        customerName: row.customerName,
        customerAreaId: row.customerAreaId,
        customerAddress: row.customerAddress
      })
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    handleClose() {
      Object.assign(this.$data, this.$options.data.call(this))
      // const view = this.$route;
      // this.$store
      //   .dispatch("tagsView/delView", view)
      //   .then(({ visitedViews }) => {
      //     if (this.isActive(view)) {
      //       this.toLastView(visitedViews, view);
      //     }
      //   });
      this.$emit('close')
    },
    // 定义一个 promise 封装 validate 方法
    validateForm(form) {
      return new Promise((resolve, reject) => {
        form?.validate((valid, obj) => {
          if (valid) {
            resolve()
          } else {
            reject(obj)
          }
        })
      })
    },
    async submitForm(options = {}, cb) {
      // name:'表单名' isClose:'执行保存后是否关闭弹窗' isCheck: '是否需要校验表单'
      const { name, isClose, isCheck } = options
      if (isCheck !== undefined) this.isCheck = isCheck
      this.$nextTick(async() => {
        // 校验必填项
        try {
          await this.validateForm(this.$refs[name])
        } catch (error) {
          console.log(error)
          for (const key in error) {
            this.$message({
              type: 'warning',
              message: error[key][0].message
            })
            // 清除校验结果
            this.$refs[name].clearValidate()
            this.isCheck = false
            return false
          }
        }
        // console.log('你们好')
        // 保存单据
        try {
          this.loading = true
          const { message } = await saveOrUpdate(this.form)
          this.$message({
            type: 'success',
            message: message
          })
          // 更新当前单据数据
          await this.getData()
          this.loading = false
          cb && await cb()
          isClose && this.handleClose()
        } catch (error) {
          this.loading = false
        }
      })
    },
    async submit() {
      // 先执行保存
      await this.submitForm({
        name: 'form',
        isClose: true,
        isCheck: true
      }, async() => {
        // 在执行提交
        console.log('执行提交')
        submit(this.form.id).then((response) => {
          const flowId = response.data.flowId
          this.$message.success('提交成功')
          this.form.flowId = flowId
          this.getData()
        })
      })
    }
  }
}
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
