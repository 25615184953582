<!-- 合同信息 -->
<template>
  <el-dialog
    title="列表"
    :visible.sync="drawer"
    width="800px"
    append-to-body
    @close="onClose"
  >
    <div class="content">
      <el-row ref="searchBox" class="search-box">
        <el-col :span="6">
          <el-input v-model="condition.sn" clearable placeholder="流程号" />
        </el-col>

        <el-col :span="3">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getPage"
          >查询</el-button>
        </el-col>
      </el-row>
      <el-table
        ref="listTable"
        v-loading="loading"
        :data="tableData"
        :height="tableHeight"
        border
      >
        <el-table-column type="selection" width="40" />

        <el-table-column
          label="流程号"
          align="left"
          prop="sn"
          show-overflow-tooltip
        />

        <el-table-column
          label="客户编码"
          align="left"
          prop="customer_no"
          show-overflow-tooltip
        />

        <el-table-column
          label="客户名称"
          align="left"
          prop="customer_name"
          show-overflow-tooltip
        />

        <el-table-column
          label="合同名称"
          align="left"
          prop="contract_name"
          show-overflow-tooltip
        />

        <el-table-column
          label="合同编码"
          align="left"
          prop="contract_apply_sn"
          show-overflow-tooltip
        />

        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="onSubmit(scope.row)"
            >确定
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="footer-box">
        <el-col :span="24">
          <pagination
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getPage"
          />
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import {
  getPage2,
  getPage3,
  deleteById,
  exportExcel
} from '@/api/hc/flow/flow'
import { booleanToStr } from '@/filters'
import Pagination from '@/components/Pagination'
import edit from '@/views/hc/contract/edit'
import ExcelImport from '@/components/ExcelImport'
import { downloadFile } from '@/utils/file'
import format from '@/utils/formatter'

export default {
  name: 'Index',
  components: { Pagination, ExcelImport, edit },
  data() {
    return {
      productCategoryOptions: [
        {
          label: 'CCC新申请',
          value: 1
        },
        {
          label: 'CCCS同类新申请',
          value: 2
        },
        {
          label: '能效检测',
          value: 3
        },
        {
          label: '委托测试项目',
          value: 4
        },
        {
          label: '化学项目',
          value: 5
        },
        {
          label: '体系项目',
          value: 6
        },
        {
          label: '节能认证新申请',
          value: 7
        },
        {
          label: '节能认证同类新申请',
          value: 8
        },
        {
          label: '环保认证',
          value: 9
        },
        {
          label: '环保认证同类新申请',
          value: 10
        },
        {
          label: 'CB认证',
          value: 11
        },
        {
          label: 'CE认证',
          value: 12
        }
      ],
      projectTypeOptions: [
        {
          label: 'CCCS同类新申请',
          value: 1
        },
        {
          label: '能效检测',
          value: 2
        },
        {
          label: '委托测试项目',
          value: 3
        },
        {
          label: '化学项目',
          value: 4
        },
        {
          label: '节能认证同类新申请',
          value: 5
        },
        {
          label: '环保认证同类新申请',
          value: 6
        },
        {
          label: 'CB认证',
          value: 7
        },
        {
          label: 'CE认证',
          value: 8
        }
      ],
      statusOptions: [
        {
          label: '制单',
          value: 1
        },
        {
          label: '审核中',
          value: 2
        },
        {
          label: '审核完成',
          value: 3
        },
        {
          label: '驳回',
          value: 4
        }
      ],
      drawer: false,
      tableHeight: '300px',
      format: format,
      showEdit: false,
      loading: false,
      editId: null,
      pagination: {
        total: 0,
        size: 20,
        current: 1
      },
      condition: {
        sn: null,
        isPay: 1
      },
      tableData: [],
      fromType: '缴费单申请'
    }
  },
  created() {
    this.getPage()
  },
  methods: {
    getLabel(value, options = []) {
      const row = options.find((item) => item.value == value)
      if (row) {
        return row.label
      } else {
        return ''
      }
    },
    onClose() {
      Object.assign(this.$data, this.$options.data.call(this))
    },
    open(options = {}) {
      this.drawer = true
      this.fromType = options.fromType
      this.getPage()
    },
    onSubmit(row) {
      this.$emit('select', row)
      this.drawer = false
    },
    getPage() {
      this.loading = true
      const getPage = this.fromType === '缴费单申请' ? getPage2 : getPage3
      getPage(this.condition, this.pagination)
        .then((reponse) => {
          const { current, total, size, records } = reponse.data
          this.tableData = records
          this.$refs.listTable.doLayout();
          this.pagination.total = total
          this.pagination.current = current
          this.pagination.size = size
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDelete(row) {
      this.$confirm('请确认是否删除此数据', '提示', { type: 'warning' }).then(
        () => {
          deleteById(row.id).then((response) => {
            this.$message.success('删除成功')
            this.getPage()
          })
        }
      )
    },
    handleEdit(id) {
      this.showEdit = true
      this.editId = id
    },
    handleBatchDel() {},
    handleConditionExport() {
      exportExcel(this.condition, this.pagination).then((response) => {
        downloadFile(response)
      })
    },
    closeEdit() {
      this.showEdit = false
      this.getPage()
    },
    formatBoolean(cellValue) {
      return booleanToStr(cellValue.isEnabled)
    }
  }
}
</script>

<style scoped>
</style>
