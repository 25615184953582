/**
 * 站点资料请求类
 */
import request from '@/utils/request'

export function getPage(condition, pagination) {
  return request({
    url: '/crm/proceeds/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function deleteById(id) {
  return request({
    url: '/crm/proceeds/deleteById',
    method: 'post',
    data: { id: id }
  })
}
export function saveOrUpdate(data) {
  return request({
    url: '/crm/proceeds/saveOrUpdate',
    method: 'post',
    data
  })
}
/**
 * 获取详情
 * @param id
 * @returns {AxiosPromise}
 */
export function get(id) {
  return request({
    url: '/crm/proceeds/get',
    method: 'post',
    data: { id: id }
  })
}

export function exportExcel(condition, pagination) {
  return request({
    url: '/crm/proceeds/exportExcel',
    method: 'post',
    data: { condition: condition, pagination: pagination },
    responseType: 'blob'
  })
}
